import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { append, without } from 'ramda';
import classNames from 'classnames';
import 'es6-string-polyfills';

class SearchFilterCheckboxes extends Component {
  constructor(props) {
    super(props);

    const { selectedGroup, groupSelected, searchFilter } = props;

    this.state = { selectedGroup, groupSelected, searchFilter };

    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
  }

  checkboxOnChange(selected) {
    let searchFilter = this.state.searchFilter;
    let selectedString = String(selected);
    let newGroup;
    if (this.isGroupChecked(selectedString)) {
      newGroup = without(
        selectedString,
        searchFilter[this.state.groupSelected],
      );
    } else {
      newGroup = append(selectedString, searchFilter[this.state.groupSelected]);
    }
    searchFilter[this.state.groupSelected] = newGroup.sort();
    this.setState({ searchFilter });
  }

  handleModalClose() {
    const { searchFilter, groupSelected, selectedGroup } = this.state;
    if (
      searchFilter[groupSelected].length === selectedGroup.length &&
      groupSelected != 'game'
    ) {
      this.handleSelectAllClick();
    }
    this.props.closeModal(this.state.searchFilter);
  }

  handleSelectAllClick() {
    let searchFilter = this.state.searchFilter;
    searchFilter[this.state.groupSelected] = [];
    this.setState({ searchFilter });
  }

  isGroupChecked(key) {
    return this.state.searchFilter[this.state.groupSelected].includes(
      String(key),
    );
  }

  printGroup(key) {
    if (this.state.groupSelected === 'day') {
      return this.props.dayMapping[key];
    } else if (this.state.groupSelected === 'hour') {
      return moment(key, 'HH:mm:ss').format('h:mm A');
    } else {
      return key;
    }
  }

  render() {
    const customStyles = {
      color: '#000',
      fontSize: '22px',
      textAlign: 'center',
      marginTop: '15px',
    };
    let modalTitle =
      this.state.groupSelected[0].toUpperCase() +
      this.state.groupSelected.slice(1);
    if (modalTitle == 'Hour') {
      modalTitle = 'Time';
    }
    let allCount = this.state.selectedGroup.reduce(
      (a, b) => +a + b.doc_count,
      0,
    );

    return (
      <div>
        <div className="event-finder-modal-title">
          <h2 style={customStyles}>{modalTitle}</h2>
        </div>

        <div className="modal-filters">
          <div
            className={classNames({
              'modal-filter-checkbox select-all': true,
              checked:
                this.state.searchFilter[this.state.groupSelected].length == 0,
            })}
            onClick={this.handleSelectAllClick}
          >
            Select All
            <div className="cat-quantity">{allCount}</div>
          </div>

          {this.state.selectedGroup.map((group, i) => (
            <div
              key={i}
              className={classNames({
                'modal-filter-checkbox': true,
                checked: this.isGroupChecked(group.key),
              })}
              onClick={this.checkboxOnChange.bind(this, group.key)}
            >
              {this.printGroup(group.key)}
              <div className="cat-quantity">{group.doc_count}</div>
            </div>
          ))}
        </div>

        <div className="event-filter-close">
          <button onClick={this.handleModalClose} className=" button">
            OK
          </button>
        </div>
      </div>
    );
  }
}

export default SearchFilterCheckboxes;
