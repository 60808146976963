import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import SatelliteConventionAttendeeManager from './SatelliteConventionAttendeeManager';
import NotFound from './NotFound';

const SatelliteConvention = ({
  satelliteConvention,
  satelliteConventions,
  onSatelliteConventionChange,
  onDelete,
  history,
  admin,
}) => {
  if (!satelliteConvention) return <NotFound />;

  return (
    <div>
      <div className="page-title">Satellite Convention</div>
      <div className="panel">
        <div className="ribbon-top">
          {/* <div className="right" style={{ margin: '10px 10px 0 0' }}>
            <select
              value={satelliteConvention.id}
              onChange={onSatelliteConventionChange}
            >
              {satelliteConventions.map(satConv => {
                if (
                  satConv.contact_group_id ===
                  satelliteConvention.contact_group_id
                ) {
                  return (
                    <option key={satConv.id} value={satConv.id}>
                      {`${satConv.convention.name} - ${satConv.name}`}
                    </option>
                  );
                }
              })}
            </select>
          </div> */}

          {admin && (
            <ul className="left">
              <li className="btn">
                <Link to="/satellite_conventions">
                  <i className="fa fa-arrow-left fa-2x" />
                  <label>Satellite Conventions</label>
                </Link>
              </li>
              <li className="btn">
                <Link
                  to={`/satellite_conventions/${satelliteConvention.id}/edit`}
                >
                  <i className="fa fa-edit fa-2x" />
                  <label>Edit</label>
                </Link>
              </li>
              <li className="btn">
                <Link to="#" onClick={() => onDelete(satelliteConvention.id)}>
                  <i className="fa fa-trash fa-2x" />
                  <label>Delete</label>
                </Link>
              </li>
            </ul>
          )}
        </div>

        <div className="panel_titlebar">Organization Info</div>
        <div className="attributes">
          <div className="attr">
            <div className="name">Contact Group/Organization:</div>
            <div className="value">
              <a href={`/profile/${satelliteConvention.contact_group_id}`}>
                {satelliteConvention.contact_group.name}
              </a>
            </div>
          </div>
          <div className="attr">
            <div className="name">Name:</div>
            <div className="value">{satelliteConvention.name}</div>
          </div>
          <div className="attr">
            <div className="name">Convention:</div>
            <div className="value">{satelliteConvention.convention.name}</div>
          </div>
          <div className="attr">
            <div className="name">Active:</div>
            <div className="value">
              {satelliteConvention.active ? 'Yes' : 'No'}
            </div>
          </div>
          <div className="attr">
            <div className="name">Badge Limit:</div>
            <div className="value">{satelliteConvention.badge_limit}</div>
          </div>
        </div>

        <hr />

        <div className="panel_titlebar">Location Info</div>
        <div className="attributes">
          <div className="attr">
            <div className="name">Address:</div>
            <div className="value">
              <div style={{ float: 'left' }}>
                {satelliteConvention.address1}
                {satelliteConvention.address2}
                <br />
                {`${satelliteConvention.city}, ${satelliteConvention.state} ${
                  satelliteConvention.zip
                }`}
                <br />
                {satelliteConvention.country}
              </div>
            </div>
          </div>
        </div>

        <div className="attributes">
          <hr />
          <div className="panel_titlebar">Description</div>
          <div className="row">
            <div className="small-12 small-centered columns">
              <p>
                <span
                  dangerouslySetInnerHTML={{
                    __html: satelliteConvention.description,
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>

      <SatelliteConventionAttendeeManager
        satelliteConvention={satelliteConvention}
        history={history}
        admin={admin}
      />
    </div>
  );
};

SatelliteConvention.propTypes = {
  satelliteConvention: PropTypes.shape(),
  satelliteConventions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSatelliteConventionChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  admin: PropTypes.bool.isRequired,
};

SatelliteConvention.defaultProps = {
  satelliteConvention: undefined,
};

export default withRouter(SatelliteConvention);
