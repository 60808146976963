document.addEventListener('DOMContentLoaded', () => {
  const textAreas = document.querySelectorAll(
    '.iigoh_description textarea',
  );

  if (textAreas.length > 0) {
    textAreas.forEach(element => {
      element.addEventListener('keyup', event => {
        const max = 200;
        const len = element.value.length;
        if (len <= max) {
          const charRemaining = max - len;
          const characterCountElement = element.closest('tr').nextElementSibling.querySelector('.count');

          if(characterCountElement){
            characterCountElement.textContent = `${charRemaining} characters left`;
          }
        }
      });
    });
  }
});