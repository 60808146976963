/* global document  */
import React from 'react';
import { Switch } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Alert, noticeMsg, errorMsg } from '../../helpers/notifications';
import FlashMsgCustomSAlertContentTemplate from '../../helpers/FlashMsgCustomSAlertContentTemplate';
import PropsRoute from '../../helpers/PropsRoute';
import SatelliteConventionList from './SatelliteConventionList';
import SatelliteConvention from './SatelliteConvention';
import SatelliteConventionForm from './SatelliteConventionForm';

class Manager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      conventions: props.conventions,
      convention: props.convention,
      satelliteConventionMetrics: null,
      satelliteConventions: null,
      admin: props.admin,
    };

    this.createSatelliteConvention = this.createSatelliteConvention.bind(this);
    this.updateSatelliteConvention = this.updateSatelliteConvention.bind(this);
    this.deleteSatelliteConvention = this.deleteSatelliteConvention.bind(this);

    this.getSatelliteConventionMetrics = this.getSatelliteConventionMetrics.bind(
      this,
    );
    this.getSatelliteConventions = this.getSatelliteConventions.bind(this);
    this.handleConventionChange = this.handleConventionChange.bind(this);
    this.handleSatelliteConventionChange = this.handleSatelliteConventionChange.bind(
      this,
    );
  }

  componentDidMount() {
    const { convention } = this.state;

    this.getSatelliteConventionMetrics(convention);
    this.getSatelliteConventions(convention);
  }

  createSatelliteConvention(newSatelliteConvention) {
    axios
      .post('/api/v1/satellite_conventions', newSatelliteConvention)
      .then(response => {
        const targetNode = document.querySelector('[data-flash]');
        const flashData = { 
          user: -1,
          nId: Math.random().toString(36).substring(7),
          text: 'Satellite Convention Added!',
          icon: 'fa-check-square-o',
          level: 'low',
          timeout: 0
         }
        targetNode.dataset.flash = JSON.stringify(flashData);
        const savedSatelliteConvention =
          response.data.data.satellite_convention;
        this.setState(prevState => ({
          satelliteConventions: [
            ...prevState.satelliteConventions,
            savedSatelliteConvention,
          ],
        }));
        const { history } = this.props;
        history.push(`/satellite_conventions/${savedSatelliteConvention.id}`);
      })
      .catch(error => {
        console.log(error);
        let errorMessages = '';
        error.response.data.messages.errors.forEach(element => {
          // errorMessages += element.message;
          if (element.fields !== undefined) {
            element.fields.forEach(field => {
              errorMessages += field.messages.join();
            });
          }
        });
        errorMsg(errorMessages);
        window.scrollTo(0, 0);
      });
  }

  updateSatelliteConvention(updatedSatelliteConvention) {
    axios
      .put(
        `/api/v1/satellite_conventions/${updatedSatelliteConvention.id}`,
        updatedSatelliteConvention,
      )
      .then(() => {
        noticeMsg('Satellite Convention updated');
        const { satelliteConventions } = this.state;
        const idx = satelliteConventions.findIndex(
          satelliteConvention =>
            satelliteConvention.id === updatedSatelliteConvention.id,
        );
        satelliteConventions[idx] = updatedSatelliteConvention;
        const { history } = this.props;
        history.push(`/satellite_conventions/${updatedSatelliteConvention.id}`);
        this.setState({ satelliteConventions });
      })
      .catch(error => {
        console.log(error);
        let errorMessages = '';
        error.response.data.messages.errors.forEach(element => {
          // errorMessages += element.message;
          if (element.fields !== undefined) {
            element.fields.forEach(field => {
              errorMessages += field.messages.join();
            });
          }
        });
        errorMsg(errorMessages);
        window.scrollTo(0, 0);
      });
  }

  deleteSatelliteConvention(satelliteConventionId) {
    const sure = window.confirm('Are you sure?');
    if (sure) {
      axios
        .delete(`/api/v1/satellite_conventions/${satelliteConventionId}`)
        .then(response => {
          if (response.status === 200) {
            noticeMsg('Satellite Convention deleted');
            const { history } = this.props;
            history.push('/satellite_conventions');

            const { satelliteConventions } = this.state;
            this.setState({
              satelliteConventions: satelliteConventions.filter(
                satelliteConvention =>
                  satelliteConvention.id !== satelliteConventionId,
              ),
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  getSatelliteConventionMetrics(convention) {
    axios
      .get(
        `/api/v1/satellite_convention_metrics?convention_id=${convention.id}`,
      )
      .then(response =>
        this.setState({
          satelliteConventionMetrics:
            response.data.data.satellite_convention_metrics,
        }),
      )
      .catch(error => {
        console.log(error);
      });
  }

  getSatelliteConventions(convention) {
    axios
      .get(`/api/v1/satellite_conventions?convention_id=${convention.id}`)
      .then(response =>
        this.setState({
          satelliteConventions: response.data.data.satellite_conventions,
        }),
      )
      .catch(error => {
        console.log(error);
      });
  }

  handleConventionChange(e) {
    const conventionId = e.target.value;

    const { conventions } = this.state;
    const convention = conventions.find(c => c.id === Number(conventionId));

    this.setState({
      convention,
    });

    this.getSatelliteConventionMetrics(convention);
    this.getSatelliteConventions(convention);
  }

  handleSatelliteConventionChange(e) {
    const conventionId = e.target.value;
    const { history } = this.props;

    history.push(`/satellite_conventions/${conventionId}`);
  }

  render() {
    const { satelliteConventions } = this.state;
    if (satelliteConventions === null) return null;

    const { conventions } = this.state;
    const { convention } = this.state;
    const { satelliteConventionMetrics } = this.state;

    const { match } = this.props;
    const satelliteConventionId = match.params.id;
    const satelliteConvention = satelliteConventions.find(
      sc => sc.id === Number(satelliteConventionId),
    );

    const { admin } = this.state;

    return (
      <div>
        <Alert
          contentTemplate={FlashMsgCustomSAlertContentTemplate}
          stack={{ limit: 1 }}
        />

        <Switch>
          <PropsRoute
            path="/satellite_conventions/new"
            component={SatelliteConventionForm}
            convention={convention}
            onSubmit={this.createSatelliteConvention}
          />
          <PropsRoute
            exact
            path="/satellite_conventions/:id/edit"
            component={SatelliteConventionForm}
            satelliteConvention={satelliteConvention}
            onSubmit={this.updateSatelliteConvention}
          />
          <PropsRoute
            path="/satellite_conventions/:id"
            component={SatelliteConvention}
            satelliteConvention={satelliteConvention}
            satelliteConventions={satelliteConventions}
            onSatelliteConventionChange={this.handleSatelliteConventionChange}
            onDelete={this.deleteSatelliteConvention}
            history={this.props}
            admin={admin}
          />
          <PropsRoute
            path="/satellite_conventions"
            component={SatelliteConventionList}
            conventions={conventions}
            convention={convention}
            satelliteConventionMetrics={satelliteConventionMetrics}
            satelliteConventions={satelliteConventions}
            onConventionChange={this.handleConventionChange}
            admin={admin}
          />
        </Switch>
      </div>
    );
  }
}

Manager.propTypes = {
  conventions: PropTypes.arrayOf(PropTypes.object).isRequired,
  convention: PropTypes.shape().isRequired,
  admin: PropTypes.bool.isRequired,
  match: PropTypes.shape(),
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
};

Manager.defaultProps = {
  match: undefined,
};

export default Manager;
