import React from 'react';
import JSONDateParser from '../../helpers/jsonDateParser'

const EventRow = ({ eventOption }) => {
  const formatDay = (dateString) => {
    let date = new JSONDateParser(dateString);
    return date.weekDay();
  }

  const formatDate = (dateString) => {
    let date = new JSONDateParser(dateString);
    return (
      date.hours() +
      ':' +
      date.minutes() +
      ' ' +
      date.meridian()
    );
  }

  const formatDuration = (event) => {
    let start = Date.parse(event.start_date);
    let end = Date.parse(event.end_date);
    let duration = end - start;
    let hours = Math.floor(duration / 1000 / 60 / 60);
    let minutes = Math.floor((duration - hours * 1000 * 60 * 60) / 1000 / 60);
    return `${hours > 0 ? hours + ' hr' : ''} ${minutes > 0 ? minutes + ' min' : ''}`.trim();
  }

  const formatGameSystem = (event) => {
    if (event.game_system.length > 0) {
      return event.game_system + ', ' + event.rules_edition + ' Ed';
    }
  }

  const iconUrl = `/event_types/Web${eventOption.event_type.replace(/\s.*/,'',)}100px_1.gif`;

  return (
    <a
      key={eventOption.id}
      id={`event-${eventOption.id}`}
    >
      <div className='row result-data'>
        <div className="row">
          <div id="event-title" className="small-12 name-small">
            {eventOption.title}
          </div>
        </div>
        <div className="large-1 small-1 columns text-center icon">
          <img border={0} src={iconUrl} style={{height: '40px', width: '40px'}}/>
        </div>
        <div className="large-5 small-5 columns name" style={{fontSize: '12px', fontWeight: 'normal'}}>
          {eventOption.game_system && <span className="subtitle">{formatGameSystem(eventOption)}</span>}
          {eventOption.game_system && <br/>}
          <span className="subtitle">{formatDay(eventOption.start_date)} at {formatDate(eventOption.start_date)} for {formatDuration(eventOption)}</span>
        </div>
      </div>
    </a>
  );
};

export default EventRow;