import React from 'react';
import PropTypes from 'prop-types';
import Control from '@skyeer/react-leaflet-custom-control';
import { project } from './helpers/coordinates';

const roundDownTo128 = xy => Math.round(xy / 256);

const Coordinates = ({ map, currentClick }) => {
  const clickedProjected = project(currentClick, map);

  return (
    <Control position="topright">
      <div className="coordinates-control" style={{ color: 'white' }}>
        <strong>Grid</strong>
        <div>x: {roundDownTo128(clickedProjected.x)}</div>
        <div style={{ paddingBottom: '5px' }}>
          y: {roundDownTo128(clickedProjected.y)}
        </div>

        <strong>Clicked (x,y)</strong>
        <div>x: {clickedProjected.x}</div>
        <div style={{ paddingBottom: '5px' }}>y: {clickedProjected.y}</div>
      </div>
    </Control>
  );
};

export default Coordinates;

Coordinates.propTypes = {
  map: PropTypes.shape().isRequired,
  currentClick: PropTypes.shape().isRequired,
};
