import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TicketStatsIcon from './TicketStatsIcon';

class TicketStats extends Component {
  static displayName = 'TicketStats'

  redeemedCount(contacts) {
    return contacts.reduce(function (accumulator, currentContact) {
      return accumulator + currentContact.tickets.filter(x => x.redeemed).length;
    }, 0)
  }

  guestsCount(contacts) {
    return contacts.filter(x => x.status === 'guests').reduce(function (accumulator, currentContact) {
      return accumulator + currentContact.tickets.filter(x => !x.redeemed).length;
    }, 0)
  }

  render() {
    const Button = props => {
      let buttonClass = classNames({
        'btn disabled-filter': (props.label.toLowerCase() !== props.currentFilter),
        'btn active-filter': (props.label.toLowerCase() === props.currentFilter),
      });


      return (
        <li className={buttonClass} onClick={props.onClick}>
          <a href="#">
            <TicketStatsIcon
              status={props.label.toLowerCase()}
              count={props.count}
              title={`Electronic Ticket ${props.label} Count`}
            />
            <label>{props.label}</label>
          </a>
        </li>
      )
    }

    return (
      <div className="ribbon-row">
        <ul className="left">
          <Button
            onClick={this.props.handleButtonClick.bind(null, "redeemed")}
            count={this.redeemedCount(this.props.tickets)}
            label="Redeemed"
            currentFilter={this.props.currentFilter}
          />
          <Button
            onClick={this.props.handleButtonClick.bind(null, "guests")}
            count={this.guestsCount(this.props.tickets)}
            label="Guests"
            currentFilter={this.props.currentFilter}
          />
        </ul>
      </div>
    );
  }
}

export default TicketStats;
