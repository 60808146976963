import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import PropTypes from 'prop-types';
import axios from 'axios';
import FinderResult from './FinderResult';
import Loading from './Loading';
import Error from './Error';
import { equals } from 'ramda';

class FinderResults extends Component {
  static displayName = 'FinderResults';

  constructor(props) {
    super(props);

    this.state = {
      events: [],
      hasMoreEvents: true,
      loading: false,
      error: null,
      nextHref: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (!equals(this.props, prevProps)) {
      this.setState({
        error: null,
        events: [],
        hasMoreEvents: true,
        nextHref: null,
        loading: false,
      });
    }
  }

  loadItems(page) {
    const baseUri = '/api/event_search?moderation=true&';
    let url = baseUri + this.props.searchQuery;

    if (this.state.nextHref) {
      url = this.state.nextHref;
    }

    if (this.state.hasMoreEvents && !this.state.loading) {
      this.setState({ loading: true, scrollPosition: window.pageYOffset });
      axios
        .get(url)
        .then(res => {
          if (res.data.error) {
            this.setState({
              error: res.data.error,
              hasMoreEvents: false,
              loading: false,
            });
            return;
          } else if (res.data?.records?.data?.events?.length > 0) {
            this.setState({
              events: [...this.state.events, ...res.data.records.data.events],
              nextHref: res.data.next_path,
              hasMoreEvents: res.data.has_more,
              loading: false,
            });
          } else {
            this.setState({
              hasMoreEvents: false,
            });
          }
        })
        .catch(error => {
          this.setState({
            error: 'Failed to load events!',
            loading: false,
            hasMoreEvents: false,
          });
        });
      window.scroll(0, this.state.scrollPosition);
    }
  }

  render() {
    var items = [];
    this.state.events.map((event, i) => {
      items.push(
        <FinderResult
          key={i}
          event={event}
          currQuery={this.props.searchQuery}
          selectedEventId={this.props.selectedEventId}
        />,
      );
    });

    return (
      <div className="results_container">
        <div className="results">
          <div className="event-info">
            <div className="event-info-data">
              <div className="result-set-pages">
                <InfiniteScroll
                  pageStart={0}
                  loadMore={this.loadItems.bind(this)}
                  loader={<Loading key={0} />}
                  threshold={150}
                  hasMore={this.state.hasMoreEvents}
                >
                  {this.state.error ? (
                    <Error message={this.state.error} />
                  ) : (
                    items
                  )}
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FinderResults;
