import React, { useState, useEffect } from 'react';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroller';
import { useActionCable } from 'use-action-cable';
import Loading from '../eventFinder/EventFinder/Loading';
import Message from './Message';

import { MESSAGES_ENDPOINT } from '../../../constants/apiEndpoints';
import { filterDupeElements } from './helpers/utils';

const CHECK_FOR_UPDATES_TIMER = 1000 * 15;
let currentTimer;

const Conversation = ({ userId, conversationId, isAdmin, actionCableOn }) => {
  const [messages, setMessages] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  // Check every [n] seconds for more messages
  // Check if new/updated message records
  // Return if user has been inactive on the page for [m] minutes
  const checkForUpdatedMessages = async () => {

    const res = await axios.get(MESSAGES_ENDPOINT(conversationId, page));
    const { messages: newMessages } = res.data.data;

    setMessages(currentMessages => {
      return filterDupeElements([...currentMessages, ...newMessages]);
    });

    setHasMore(
      res.data.meta.pagination.current_page <
        res.data.meta.pagination.total_pages,
    );

    clearTimeout(currentTimer);
    currentTimer = setTimeout(
      () => checkForUpdatedMessages(),
      CHECK_FOR_UPDATES_TIMER,
    );
  };

  const startTimer = async () => {
    clearTimeout(currentTimer);

    currentTimer = setTimeout(
      () => checkForUpdatedMessages(),
      CHECK_FOR_UPDATES_TIMER,
    );
  };

  const channelHandlers = channelInfo => ({
    connected() {
      console.log('Websocket connected to', channelInfo);
    },
    disconnected() {
      console.log('Websocket disconnected from', channelInfo);
    },
    received: msg => {
      console.log('Websocket received message', { ...channelInfo, ...msg });
      setMessages(currentMessages => {
        return [msg, ...currentMessages];
      });
    },
  });

  if (actionCableOn) {
    useActionCable(
      { channel: 'ConversationChannel', id: conversationId },
      channelHandlers({ channel: 'ConversationChannel', id: conversationId }),
    );
  }

  const loadMoreMessages = async page => {
    await axios.get(MESSAGES_ENDPOINT(conversationId, page)).then(res => {
      const { messages: newMessages } = res.data.data;

      setMessages(currentMessages => {
        return [...currentMessages, ...newMessages];
      });

      setHasMore(
        res.data.meta.pagination.current_page <
          res.data.meta.pagination.total_pages,
      );
    });
  };

  if (!actionCableOn) {
    useEffect(() => {
      clearTimeout(currentTimer);
      startTimer();
    }, []);
  }

  return (
    <div>
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMoreMessages}
        loader={<Loading key={0} />}
        threshold={100}
        hasMore={hasMore}
      >
        {messages
          .sort((a, b) => b.id - a.id)
          .map(message => (
            <Message
              key={message.id}
              message={message}
              userId={userId}
              isAdmin={isAdmin}
            />
          ))}
      </InfiniteScroll>
    </div>
  );
};

export default Conversation;
