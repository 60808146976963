import React, {useState} from 'react'

import SearchBar from './SearchBar';
import Filters from './Filters'
import SearchFilterBadge from './SearchFilterBadge'

const SearchAndFilters = ({ searchFiltersRef, updateEvents, filterChoices }) => {

  const getBadges = () => {
    let _filterBadges = []
    for (const [key, values] of Object.entries(searchFiltersRef.current)) {
      if (values.length > 0){
        _filterBadges.push({filter: key, values: values.map(v => v.name).join(', ')})
      }
    }
    return _filterBadges;
  }

  const [filterBadges, setFilterBadges] = useState(getBadges());

  const updateBadges = () => {
    let _filterBadges = getBadges();
    setFilterBadges(_filterBadges);
    updateEvents();
  };


  return (
    <div className='finder clearfix'>
      {filterBadges.map(fb => (
        <SearchFilterBadge filter={fb.filter} values={fb.values} searchFiltersRef={searchFiltersRef} updateBadges={updateBadges}/>
      ))}
      <Filters searchFiltersRef={searchFiltersRef} updateBadges={updateBadges} filterChoices={filterChoices}/>
      <SearchBar searchFiltersRef={searchFiltersRef} updateBadges={updateBadges}/>
    </div>
  )
};

export default SearchAndFilters;