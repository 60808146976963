import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const PROFILE_LINK = id => `/profile/${id}`;

const Message = ({ message: { creator }, message, userId, isAdmin }) => {
  const creatorName = creator.id === userId ? 'me ' : `${creator.name} `;
  const messageClass = creator.id === userId ? 'current-user' : 'other-user';

  return (
    <div className="message">
      <div className="current-user-label">
        {'From '}
        {isAdmin && (
          <span>
            <a href={PROFILE_LINK(creator.id)}>{creatorName}</a>
          </span>
        )}
        {!isAdmin && <span>{creatorName}</span>}
        <span
          className="time-stamp"
          data-post-date={moment(message.created_at).format(
            'LT MMM D YYYY',
            true,
          )}
        >
          <span className="in-words" data-words="true">
            {moment(message.created_at).fromNow()}
          </span>
          <span className="in-time" data-time="true">
            on {moment(message.created_at).format('LT MMM D YYYY', true)}
          </span>
        </span>
      </div>

      {creator.id !== userId && (
        <div className="message-avatar">
          <img
            className="avatar"
            src={creator.avatar_url.replace('smallest', 'normal')}
            alt="Normal"
          />
        </div>
      )}

      <div className={messageClass}>
        <div className="post-content">
          <div dangerouslySetInnerHTML={{ __html: message.body }} />
        </div>
      </div>
    </div>
  );
};

Message.propTypes = {
  message: PropTypes.shape().isRequired,
  userId: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default Message;
