import React from 'react';
import classNames from 'classnames';

const TicketStatsIcon = ({ status, title, count }) => {

  let iconClass = classNames({
    'fa fa-ticket-horz-blank fa-2x': (status == 'unredeemed'),
    'fa green fa-ticket-horz-blank fa-2x': (status == 'guests'),
    'fa red fa-ticket-alert fa-2x': (status == 'failed'),
    'fa green fa-ticket-checked fa-2x': (status == 'redeemed')
  });

  return (
    <span className="stat-button">
      <strong>
        {count}
      </strong>
      <i className={iconClass} title={title}></i>
    </span>
  )
}

export default TicketStatsIcon;
