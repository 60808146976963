import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

const Message = ({
  message: { creator },
  message,
  onDelete,
  toggleMute,
  userId,
  eventId,
  userCanMute,
  userIsEventManager,
}) => {
  const onClickDelete = event => {
    event.preventDefault();
    onDelete(message);
  };

  const onClickToggleMuted = event => {
    event.preventDefault();
    toggleMute(message);
  };

  const mutedClass = classNames('fa fa-user-block fa-2x', {
    muted: !creator.muted,
  });

  const mutedText = creator.muted ? 'Unmute User' : 'Mute User';

  /* Do not show mute option if:
    - User is message author
    - User doesn't have mute permissions
    - Message author is an event manager
    - Message author is a gm or eo and user is not an event manager
   */
  const canMute =
    creator.id !== userId &&
    userCanMute &&
    !creator.is_event_manager &&
    (creator.is_game_master || creator.is_event_organizer
      ? userIsEventManager
      : true);

  const creatorTitle = creator.is_event_manager
    ? 'Event Manager'
    : creator.is_event_organizer
    ? 'Event Host'
    : creator.is_game_master
    ? 'Event Host'
    : '';

  return (
    <div className="post-with-avatar-panel">
      <div className="clearfix">
        <div className="avatar-panel">
          <div className="text-center">
            <div style={{ fontSize: '11px' }} className="red">
              {creatorTitle}
            </div>
            <img
              className="avatar"
              src={creator.avatar_url.replace('smallest', 'normal')}
              alt="Normal"
            />
          </div>
          <div style={{ marginTop: '5px' }} className="text-center">
            {creator.id !== userId && (
              <div className="inline-block">
                <a
                  title="Message User"
                  style={{ textDecoration: 'none' }}
                  href={`/direct_messages/new?event_id=${eventId}&contact_id=${creator.id}`}
                >
                  <i className="fa fa-comments fa-2x" />
                </a>
              </div>
            )}
            {canMute && (
              <div className="inline-block">
                <a
                  title={mutedText}
                  rel="nofollow"
                  style={{ textDecoration: 'none' }}
                  onClick={onClickToggleMuted}
                >
                  <i className={mutedClass} />
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="post">
          <div className="post-header">
            <div className="left">
              Posted by&nbsp;
              {userIsEventManager ? (
                <span data-postauthor={creator.name}>
                  <a title={creator.name} href={`/profile/${creator.id}`}>
                    {creator.name}&nbsp;
                  </a>
                </span>
              ) : (
                `${creator.name} `
              )}
              <span
                className="time-stamp"
                data-post-date={moment(message.created_at).format(
                  'LT MMM D YYYY',
                  true,
                )}
              >
                <span className="in-words" data-words="true">
                  {moment(message.created_at).fromNow()}
                </span>
                <span className="in-time" data-time="true">
                  on {moment(message.created_at).format('LT MMM D YYYY', true)}
                </span>
              </span>
            </div>
            <div className="text-right">
              <div className="right icon">
                {userIsEventManager && (
                  <div>
                    <a
                      title="Edit Post"
                      href={`/events/${eventId}/messages/${message.id}/edit`}
                    >
                      <i className="fa fa-edit" />
                    </a>
                    &nbsp;
                    <a
                      title="Delete Post"
                      rel="nofollow"
                      onClick={onClickDelete}
                    >
                      <i className="fa fa-trash" />
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className="clear" style={{ clear: 'both' }} />
          </div>
          <div className="post-container">
            <div
              className="post-content"
              style={{ margin: '0px', minHeight: '3em', clear: 'left' }}
            >
              <div
                style={{ wordWrap: 'break-word' }}
                dangerouslySetInnerHTML={{ __html: message.body }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Message.propTypes = {
  message: PropTypes.shape().isRequired,
  onDelete: PropTypes.func.isRequired,
  toggleMute: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  eventId: PropTypes.number.isRequired,
  userCanMute: PropTypes.bool.isRequired,
  userIsEventManager: PropTypes.bool.isRequired,
};

export default Message;
