
document.addEventListener('DOMContentLoaded', () => {
  //
  // When the page finishes loading, loop over each form in the ticket refund section:
  //   - Disable the fee field for each checked box or if the system credit option is selected.
  //   - Disable the submit button and select control of the form if all checkboxes are checked.
  //
  const cancelEncounterButton = document.getElementById("cancel_refund_encounter_link");
  const refundForms           = document.querySelectorAll('div.ticket_refunds form');
  const refundedCartsElement  = document.getElementById("refunded_cart_ids_vault");

  refundForms.forEach((form) => {
    const selectControl = form.querySelector('select');

    if (document.getElementById("refunds_page_two") === null) { return; };

    form.querySelectorAll('input[type="checkbox"]:not(:checked)').forEach((checkbox) => {
      if (selectControl.value == "system_credit") {
        selectControl.closest("form").querySelectorAll('input[type=text]').forEach((feeField) => {
          feeField.disabled = true;
          feeField.value = '$0.00';
        });
      } else {
        const fees = selectControl.closest("form").querySelectorAll('input[type=text]');

        fees.forEach((fee) => {
          const cancellation      = fee.classList.contains("canceled");
          const ticketPriceString = fee.closest("tr").querySelector('td:nth-child(5)').innerText.replace('$', '');
          const ticketPrice       = parseFloat(ticketPriceString);
          const charge            = ticketPrice * (cancellation ? 0.00 : 0.05);

          fee.value = '$' + charge.toFixed(2);
          fee.disabled = cancellation;
        });
      };
    });

    form.querySelectorAll('input[type=checkbox]').forEach((checkbox) => {
      const feeField = checkbox.closest("tr").querySelector("input[type=text]");

      if (feeField === null) { return; };

      const cancellation = feeField.classList.contains("canceled");

      if (checkbox.checked || cancellation || (selectControl.value == "system_credit")) {
        feeField.disabled = true;
      } else {
        feeField.disabled = false;
      };
    });

    if (form.querySelectorAll("input[type=checkbox]:not(:checked)").length == 0) {
      const genericCheckbox = form.querySelector("input[type=checkbox][name=generic_tickets]");

      if (genericCheckbox === null || genericCheckbox.checked) {
        form.querySelectorAll("input[type=submit], select[name=refund_method]").forEach((control) => {
          control.disabled = true;
          control.classList.add("disabled");
        });
      };
    };

    if (document.querySelectorAll("div.ticket_refunds input[type=submit]:not(:disabled)").length == 0) {
      cancelEncounterButton.classList.add("disabled");
    } else {
      cancelEncounterButton.classList.remove("disabled");
    };
  });

  //
  // When any form is submitted, grab the refunded cart IDs and set the
  // hidden field in the form with the values.
  //
  document.querySelectorAll('div#refunds_page_two input[type=submit]').forEach((submitButton) => {
    submitButton.addEventListener("click", event => {
      const refundedCartIDs = refundedCartsElement.value;
      const target          = submitButton.closest("form").querySelector("input[name=refunded_cart_ids]");

      target.value = refundedCartIDs;
    });
  });

  //
  // If a ticket is selected on page 2, it is **excluded** from the refund. Therefore,
  // the fee field associated with the refund is disabled and set to $0.00.
  //
  document.querySelectorAll("div#refunds_page_two input[type=checkbox]").forEach((checkbox) => {
    checkbox.addEventListener("change", event => {
      const cancelEncounterButton = document.getElementById("cancel_refund_encounter_link");
      const feeField              = checkbox.closest("tr").querySelector("input[type=text]");
      const form                  = checkbox.closest("form");
      const genericCheckbox       = form.querySelector("input[type=checkbox][name=generic_tickets]");
      const selectControl         = form.querySelector("select");
      const submitOrSelects       = form.querySelectorAll("input[type=submit], select[name=refund_method]");
      const ticketPriceString     = checkbox.closest("tr").querySelector("td:nth-child(5)").innerText.replace("$", "");
      const ticketPrice           = parseFloat(ticketPriceString);
      const charge                = ticketPrice * 0.05;

      if (feeField) {
        const cancellation = feeField.classList.contains("canceled");

        feeField.disabled = checkbox.checked || cancellation || (selectControl.value == "system_credit");

        if (feeField.disabled) {
          feeField.value = "$0.00";
        } else {
          feeField.value = "$" + charge.toFixed(2);
        };
      };

      const allTicketsExcluded =
        (form.querySelectorAll("input[type=checkbox]:not(:checked)").length == 0) &&
        (genericCheckbox === null || genericCheckbox.checked);

      submitOrSelects.forEach((submitOrSelect) => {
        submitOrSelect.disabled = allTicketsExcluded;

        if (!allTicketsExcluded) {
          submitOrSelect.classList.remove("disabled");
        } else {
          submitOrSelect.classList.add("disabled");
        };
      });

      if (document.querySelectorAll("div#refunds_page_two input[type=submit]:not(:disabled)").length == 0) {
        cancelEncounterButton.classList.add("disabled");
      } else {
        cancelEncounterButton.classList.remove("disabled");
      };
    });
  });

  //
  // Do not follow links with the CSS class of disabled
  //
  document.querySelectorAll("a").forEach((link) => {
    if (link.classList.contains("disabled")) {
      event.preventDefault();
      return false;
    };
  });

  //
  // Enable or disable all generic ticket checkboxes at once. If one checkbox is checked,
  // mark all the checkboxes as checked. (The cashier cannot tell from the printed
  // generic tickets, which is which. Hence, ignore all tickets if ignoring any.)
  //
  document.querySelectorAll("div#refunds_page_two input[type=checkbox][name=generic_tickets]").forEach((checkbox) => {
    checkbox.addEventListener("change", event => {
      document.querySelectorAll("div#refunds_page_two input[type=checkbox][name=generic_tickets]").forEach((genericCheckbox) => {
        if (genericCheckbox === checkbox) {
          return;
        }

        genericCheckbox.checked = checkbox.checked;

        const form               = genericCheckbox.closest("form");
        const submitOrSelects    = form.querySelectorAll("input[type=submit], select[name=refund_method]");
        const allTicketsExcluded = (form.querySelectorAll("input[type=checkbox]:not(:checked)").length == 0)

        submitOrSelects.forEach((submitOrSelect) => {
          submitOrSelect.disabled = allTicketsExcluded;

          if (allTicketsExcluded) {
            submitOrSelect.classList.add("disabled");
          } else {
            submitOrSelect.classList.remove("disabled");
          };
        });
      });
    });
  });

  //
  // Change the refund fee when the refund method changes. If the refund method
  // is system credit, the fee is always $0.00 and cannot be changed.
  //
  document.querySelectorAll("div#refunds_page_two select").forEach((selectControl) => {
    selectControl.addEventListener("change", event => {
      const fees = selectControl.closest("form").querySelectorAll("input[type=text]")

      fees.forEach((fee) => {
        if (selectControl.value == "system_credit") {
          fee.disabled = true;
          fee.value = "$0.00";
        } else {
          const ticketPriceString = fee.closest("tr").querySelector("td:nth-child(5)").innerText.replace("$", "");
          const ticketPrice = parseFloat(ticketPriceString);
          const cancellation = fee.classList.contains("canceled");
          const charge = ticketPrice * (cancellation ? 0.00 : 0.05);

          fee.disabled = cancellation;
          fee.value = "$" + charge.toFixed(2);
        };
      });
    });
  });
});
