import { Controller } from 'stimulus';

const LIST_ELEMENT_CLASS = 'li[data-element]';

export default class SidebarTabsController extends Controller {
  static targets = ['tabContainer'];

  initialize() {
    const container = this.tabContainerTarget;
    const tabItems = container.querySelectorAll(LIST_ELEMENT_CLASS);

    container.addEventListener('click', event => {
      const element = event.target;
      const tabBarHeight = container.offsetHeight;

      if ((element.href !== undefined) && element.href.match(/data_files/i)) {
        return;
      }

      event.preventDefault();
      element.classList.add('selected');
      tabItems.forEach(i => i.classList.remove('selected'));

      const listItem = element.closest('li');

      if (listItem) {
        listItem.classList.add('selected');

        const className = listItem.dataset.element;
        const elements = document.querySelectorAll(
          '[data-controller="sidebar-tabs"]>div',
        );

        const selected = document.querySelector(className);

        this.hideContent(elements);
        this.displayContent(selected, tabBarHeight);
      }
    });
  }

  hideContent(elements) {
    elements.forEach(function(el) {
      el.style.display = 'none';
    });
  }

  displayContent(selected, tabBarHeight) {
    selected.style.display = 'inline-block';
    selected.style.minHeight = `${tabBarHeight}px`;
  }

  connect() {
    const container = this.tabContainerTarget;
    const tabItems = container.querySelectorAll(LIST_ELEMENT_CLASS);

    tabItems[0].click();
  }
}
