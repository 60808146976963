import React from 'react';
import { ActionCableProvider } from 'use-action-cable';
import TicketRedemptions from './TicketRedemptions';
import autoload from '../../helpers/autoload';

const TicketRedemptionsWrapper = ({ conId, hostId, eventId=0, scanWindowOpen=true, actionCableOn=false}) => {
  return (
    <ActionCableProvider url="/cable">
      <TicketRedemptions
        conId={conId}
        hostId={hostId}
        eventId={eventId}
        scanWindowOpen={scanWindowOpen}
        actionCableOn={actionCableOn}
      />
    </ActionCableProvider>
  );
};

export default autoload(TicketRedemptionsWrapper, 'ticket-redemptions');
