import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import axios from 'axios';

import MicroModal from 'micromodal';

const EventRegistration = ({
  id,
  userId,
  status,
  gameId,
  title,
  price,
  startTime,
  duration,
  isAdmin
}) => {
  const [history, setHistory] = useState({ __html: 'Loading . . .' });

  const EVENT_LINK = `/events/${id}`
  const EVENT_EDIT_LINK = `/event_registrations/${id}/edit?contact_id=${userId}`;
  const HISTORY_LINK = `/event_registrations/${id}/show_history`;
  const DUPLICATE_LINK = `/event_registrations/${id}/duplicate?contact_id=${userId}`;
  const BATCH_DUP_LINK = `/event_registrations/${id}/batch_dupe?contact_id=${userId}`;
  const MODAL_ID =`event-history-modal-${id}`;

  const getIdLink = () => {
    if (
      status === 'AC-Accepted for Consideration' ||
      status === 'AA-Active' ||
      status === 'IN-Invisible' ||
      status === 'NS-GM no show'||
      status === 'CN-Canceled'
    ) {
      return EVENT_LINK;
    } else if (
      status === 'IP-In Progress' ||
      status === 'RC-Returned for Correction' ||
      status === 'SR-Submitted for Review'
    ) {
      return EVENT_EDIT_LINK;
    } else {
      return '';
    }
  }

  const onHistoryClick = () => {
    MicroModal.init();
    MicroModal.show(MODAL_ID);
    axios.get(HISTORY_LINK).then(res => {
      setHistory({ __html: res.data });
    });
  }

  return (
    <tr className="even">
      <td>
        <a href={getIdLink()}>{gameId}</a>
        <br />
        <br />
      </td>
      <td>{title}</td>
      <td>{moment(startTime).tz("America/New_York").format('MMM Do (ddd) h:mm a', true)}</td>
      <td>{duration} hrs</td>
      <td>
        <span className="form_comment">Cost: </span>${parseInt(price)}
      </td>
      <td width="50">
        <a href={DUPLICATE_LINK}>Duplicate</a>
      </td>
      {isAdmin && 
        (<td>
          <a href={BATCH_DUP_LINK}>Batch Duplicate</a>
        </td>)
      }
      <td width="50">
        <a data-micromodal-trigger={MODAL_ID} onClick={onHistoryClick}>
          History
        </a>
        <div
          aria-hidden="true"
          className="micromodal-slide"
          id={MODAL_ID}
        >
          <div
            className="modal__overlay"
            data-micromodal-close=""
            tabIndex="-1"
          >
            <div
              aria-labelledby={MODAL_ID}
              aria-modal="true"
              className="modal__container"
              role="dialog"
            >
              <div
                className="modal__content"
                id={`${MODAL_ID}-content`}
              >
                <a
                  aria-label="Close"
                  className="modal__close"
                  data-micromodal-close=""
                >
                  ×
                </a>
                <div
                  id={`${MODAL_ID}-body`}
                  dangerouslySetInnerHTML={history}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default EventRegistration;
