/* global window  */
import qs from 'qs';
import {
  pathOr,
  filter,
  uniqBy,
  prop,
  isEmpty,
  not,
  compose,
  either,
  equals,
  omit,
  pick,
  map,
  when,
  propEq,
  __,
} from 'ramda';
import { filterMapping } from './displayHelpers';

export const urlParamsToObject = () => {
  return qs.parse(window.location.search.substring(1));
};

// TODO: Look to refactor this since its UGLY!!
export const removeFilterObject = (filterToRemove, filters) => {
  const entries = Object.entries(filterToRemove);
  const name = entries[0][0];
  const nfilter = entries[0][1];

  let newFilter = {};
  let filteredArray = [];
  const selectedFilter = filters[name];

  if (Array.isArray(selectedFilter)) {
    filteredArray = selectedFilter.filter(e => e !== nfilter);
    if (filteredArray.length === 1) {
      newFilter = { [name]: filteredArray[0] };
    } else {
      newFilter = { [name]: filteredArray };
    }
    const { [name]: remove, ...rest } = filters;
    newFilter = { ...rest, ...newFilter };
  } else {
    const { [name]: remove, ...rest } = filters;
    newFilter = rest;
  }

  return newFilter;
};

export const urlParamsToQuery = object => {
  return qs
    .stringify(object, {
      encode: true,
      indices: false,
      arrayFormat: 'brackets',
    })
    .replace(/ /g, '+');
};

export const urlParamsToCleanQuery = object => {
  return qs
    .stringify(object, {
      encodeValuesOnly: true,
      indices: false,
      arrayFormat: 'brackets',
    })
    .replace(/ /g, '+');
};

export const replaceUrlState = query => {
  window.history.replaceState({}, '', query);
};

export const initialSearchValues = {
  filters: urlParamsToObject(),
  scheduleData: [],
  page: 1,
  complete: false,
  hasMore: true,
};

const isZero = equals(0);
const isModZero = x => isZero(x % 2);

export const emptyString = data => data.key !== '';
export const removeEmptyString = filter(emptyString);
export const fetchSearch = pathOr('', ['filters', 'search']);
export const filterDupeEvents = uniqBy(prop('event_id'));
export const notEmpty = compose(not, isEmpty);

export const isEven = either(isModZero, isModZero);
export const omitSearch = omit(['search']);
export const cleanFilters = compose(pick(__), map(prop('key')))(filterMapping);
export const filterEmptySearch = when(propEq('search', ''), omit(['search']));
