import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types'


class SearchFilterBadge extends Component {
  static displayName = 'SearchFilterBadge';

  constructor(props) {
    super(props);

    this.handleCloseClick = this.handleCloseClick.bind(this);
  }

  handleCloseClick(e) {
    this.props.onClick(this.props.group, this.props.name);

    e.preventDefault()
  }

  printGroup(group, name) {
    if (group === "day") {
      return this.props.dayMapping[name]
    } else if (group === "hour") {
      return moment(name, 'HH:mm:ss').format('h:mm A')
    } else if (group === "ids"){
      return ""
    } else {
      return name.replace('%26', '&').replace('%23', '#')
    }
  }

  render() {
    let capitalizedGroupName = this.props.group[0].toUpperCase() + this.props.group.slice(1) + ':'
    if (capitalizedGroupName == 'Ids:'){
      capitalizedGroupName = 'Selected Events'
    }
    return (
      <a onClick={this.handleCloseClick}>
        <div className="finder-filter">
          {capitalizedGroupName} {this.printGroup(this.props.group, this.props.name)}
          <div className="right">
            <i className="fa fa-times-circle"></i>
          </div>
        </div>
      </a>
    );
  }

}

export default SearchFilterBadge;
