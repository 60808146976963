import React, { Component } from 'react';
import { complement, isEmpty, isNil, either } from 'ramda';
import classNames from 'classnames';
import TicketRowIcon from './TicketRowIcon';
class TicketRow extends Component {
  static displayName = 'TicketRow';

  render() {
    const contact = this.props.contact;
    const isValidValue = complement(either(isNil, isEmpty))

    let rowClasses = classNames({
      'even': (this.props.even),
      'odd': !(this.props.even)
    });

    return (
      <tr className={rowClasses}>
        <td className="ticket-holder">
          <span>
            {contact.name}
            {isValidValue(contact.pronoun) && ` (${contact.pronoun})` }
          </span>
          {contact.failed &&
            <span className="ticket-error">
              contact.error
            </span>
          }
        </td>
        <td className="right ticket-icon">
          <TicketRowIcon
            contact={contact}
            title={"Electronic Ticket " + contact.status}
          />
        </td>
      </tr>
    );
  }
}

export default TicketRow;
