import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { CountryDropdown } from 'react-country-region-selector';
import NotFound from './NotFound';

class SatelliteConventionAttendeeForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      satelliteConventionAttendee: props.satelliteConventionAttendee,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.updateSatelliteConventionAttendee(name, value);
  }

  handleSubmit(e) {
    e.preventDefault();

    const { satelliteConventionAttendee } = this.state;
    const { onSubmit } = this.props;
    const instance = $(e.target);

    if (instance.parsley().validate()) {
      onSubmit(satelliteConventionAttendee);
    }
  }

  updateSatelliteConventionAttendee(key, value) {
    this.setState(prevState => ({
      satelliteConventionAttendee: {
        ...prevState.satelliteConventionAttendee,
        [key]: value,
      },
    }));
  }

  render() {
    const { convention } = this.props;
    const { satelliteConvention } = this.props;
    const { satelliteConventionAttendee } = this.state;

    return (
      <div>
        <div className="page-title">New Attendee</div>
        <div className="panel">
          <form
            className="satelliteConventionAttendeeForm"
            onSubmit={this.handleSubmit}
          >
            <div className="attributes">
              <div className="attr">
                <div className="name">First Name:</div>
                <div className="value">
                  <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    data-parsley-required
                    data-parsley-trigger="blur change keyup"
                    value={satelliteConventionAttendee.firstName}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>

              <div className="attr">
                <div className="name">Last Name:</div>
                <div className="value">
                  <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    data-parsley-required
                    data-parsley-trigger="blur change keyup"
                    value={satelliteConventionAttendee.lastName}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>

              <div className="attr">
                <div className="name">Email:</div>
                <div className="value">
                  <input
                    type="text"
                    id="email"
                    name="email"
                    data-parsley-required
                    data-parsley-trigger="blur change keyup"
                    value={satelliteConvention.email}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>

              <div className="attr">
                <div className="name" />
                <div className="value">
                  <button type="submit" className="button" validate="true">
                    Submit
                  </button>
                  <Link
                    to={`/satellite_conventions/${satelliteConvention.id}`}
                    className="button"
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

SatelliteConventionAttendeeForm.propTypes = {
  convention: PropTypes.shape(),
  satelliteConvention: PropTypes.shape().isRequired,
  satelliteConventionAttendee: PropTypes.shape(),
  onSubmit: PropTypes.func.isRequired,
};

SatelliteConventionAttendeeForm.defaultProps = {
  convention: {},
  satelliteConventionAttendee: {
    first_name: '',
    last_name: '',
    email: '',
  },
};

export default SatelliteConventionAttendeeForm;
