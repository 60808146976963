import React, { Component } from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import autoload from '../../helpers/autoload';

class BarChart extends Component {
  constructor(props) {
    super(props);
    this.d3Container = React.createRef();
  }

  componentDidMount() {
    this.drawChart();
  }

  componentDidUpdate() {
    this.drawChart();
  }

  getMaxValue(data){
    const allValues = data.flatMap(convention => convention.data.map(entry => entry.value));
    return Math.max(...allValues);
  };

  transformDataToDesiredFormat(dataArray) {
    return dataArray.map(item => ({
        year: item.year,
        values: item.data.map(d => d.value)
    }));
}

  drawChart() {
    const { Allconventiondata, barColor = '#69b3a2' } = this.props;
    const currentYear = new Date().getFullYear();
    const lineColorPalette = ['#53c653','#ff0000','#ff7f00','#cccc00','#00b359','#7f00ff','#ff0080','#993399', '#53c653'];


    if (Allconventiondata && this.d3Container.current) {
      const svg = d3.select(this.d3Container.current);

      const margin = { top: 50, right: 30, bottom: 150, left: 45 };
      const adjustedWidth =
        this.d3Container.current.clientWidth - margin.left - margin.right;
      const adjustedHeight =
        this.d3Container.current.clientHeight - margin.top - margin.bottom;

      svg.selectAll('*').remove();


      const tabularData = this.transformDataToDesiredFormat(Allconventiondata);
      const yOffset = adjustedHeight + margin.top;

      const chart = svg
        .append('svg')
        .attr('preserveAspectRatio', 'xMidYMid meet')
        .attr(
          'viewBox',
          `0 0 ${adjustedWidth + margin.left + margin.right} ${adjustedHeight +
            margin.top +
            margin.bottom}`,
        )
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      const xScale = d3
        .scaleBand()
        .domain([...Array(14).keys()].map(i => `ER:-${i}`).reverse())
        .range([1, adjustedWidth])
        .padding(0.4);

      const yScale = d3
        .scaleLinear()
        .domain([0, this.getMaxValue(Allconventiondata)])
        .range([adjustedHeight, 0]);

      tabularData.forEach((yearObj, index) => {
        yearObj.values.forEach((value, i) => {

          const fillColor = yearObj.year === currentYear ? barColor : lineColorPalette[index % lineColorPalette.length];

          chart.append("text")
          .attr("x", -40)
          .attr("y", yOffset + (index * 20))
          .attr("text-anchor", "start")
          .style("font-size", "10px")
          .style("fill", fillColor)
          .text(yearObj.year);

          chart.append("text")
            .attr("x", xScale(`ER:-${i}`) + xScale.bandwidth() / 2)
            .attr("y", yOffset + (index * 20))
            .attr("text-anchor", "middle")
            .style("font-size", "10px")
            .text(value);
        });
      });

      Allconventiondata.forEach((dataset) => {
        if (dataset.year == currentYear) {
          chart
            .selectAll('rect')
            .data(dataset.data)
            .enter()
            .append('rect')
            .attr('x', d => xScale(d.name))
            .attr('y', d => yScale(d.value))
            .attr('width', xScale.bandwidth())

            .attr('height', d =>
              d.value > 0 ? adjustedHeight - yScale(d.value) : 0,
            )
            .attr('fill', barColor);
        }
      });

      chart
        .append('g')
        .attr('transform', `translate(0, ${adjustedHeight})`)
        .call(d3.axisBottom(xScale));

      chart.append('g').call(d3.axisLeft(yScale));

      const lineGenerator = d3
        .line()
        .x(d => (xScale(d.name) + xScale.bandwidth() / 2 ))
        .y(d => yScale(d.value));

      Allconventiondata.forEach((dataset, index ) => {
        if (dataset.year !== currentYear) {
          const color = lineColorPalette[index % lineColorPalette.length];
          chart.append('path')
            .datum(dataset.data)
            .attr('fill', 'none')
            .attr('stroke', color)
            .attr('stroke-width', 2.5)
            .attr('d', lineGenerator);
        }
      });
    }
  }

  render() {
    const { width, height } = this.props;
    return (
      <div ref={this.d3Container} style={{ width: width, height: height }}>
        <svg style={{ width: '100%', height: '100%' }} />
      </div>
    );
  }
}

BarChart.propTypes = {
  Allconventiondata: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.number.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired
        })
      ).isRequired
    })
  ).isRequired,
  width: PropTypes.string,
  height: PropTypes.number,
  barColor: PropTypes.string
};

export default autoload(BarChart, 'bar-chart');
