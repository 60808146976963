import React from 'react';
import { ActionCableProvider } from 'use-action-cable';
import NotificationsContainer from './NotificationsContainer';
import autoload from '../../helpers/autoload';

const NotificationsWrapper = ({
  userId,
  actionCableOn,
  error,
  alert,
  notice,
  htmlSafe
}) => {
  return (
    <div>
      {actionCableOn ? (
        <ActionCableProvider url="/cable">
          <NotificationsContainer
            userId={userId}
            actionCableOn={actionCableOn}
            error={error}
            alert={alert}
            notice={notice}
            htmlSafe={htmlSafe}
          />
        </ActionCableProvider>
      ) : (
        <NotificationsContainer
          userId={userId}
          actionCableOn={actionCableOn}
          error={error}
          alert={alert}
          notice={notice}
          htmlSafe={htmlSafe}
        />
      )}
    </div>
  );
};

export default autoload(NotificationsWrapper, 'notifications');
