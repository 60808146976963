import React from 'react';

const ManualScanModal = ({ scan, close }) => {
  return (
    <div className="scanning-modal">
      <div className="scanning-modal-header">
        <h4>Manual Scan</h4>
      </div>
      <div className="scanning-modal-content">
        <input type="text" id='manual_scan_code' placeholder="Badge Code" />
      </div>
      <div style={{display:'flex'}}>
        <div className='button' onClick={async () => {
          const code = document.getElementById('manual_scan_code').value.toUpperCase();
          scan(code);
          document.getElementById('manual_scan_code').value = '';
          close();
        }}>Submit</div>
        <div className='button' onClick={close}>Cancel</div>
      </div>
    </div>
  );
};

export default ManualScanModal;
