import MmenuLight from 'mmenu-light';

document.addEventListener('DOMContentLoaded', () => {
  const sticky = document.querySelector(".sticky");

  if (sticky) {
    const topOffset = sticky.offsetTop;
    window.onscroll = () => {
      if (window.pageYOffset != 0 && window.pageYOffset >= topOffset) {
        sticky.classList.add('fixed');
      } else {
        sticky.classList.remove('fixed');
      }
    };
  }

  const element = document.querySelector('#mobile-nav-menu')
  if (element) {
    const menu = new MmenuLight(
      element,
      '(max-width: 736px)',
    );
  
    const navigator = menu.navigation({ title: '', theme: 'dark' });
    navigator.userAgent = 'not setting this breaks things';
  
    const drawer = menu.offcanvas({});
  
    const mobileNavToggle = document.getElementById('topnav-toggle-button');
    mobileNavToggle.onclick = toggleMenu;
  
    function toggleMenu() {
      const openElements = document.getElementsByClassName('mm-ocd--open');
      const mobileNavContainer = document.querySelector('.mm-ocd--left');
      if (openElements.length > 0) {
        drawer.close();
        mobileNavContainer.style.visibility = 'hidden';
      } else {
        drawer.open();
        mobileNavContainer.style.visibility = 'visible';
      }
    }
  
    var elements = document
      .getElementById('mobile-nav-menu')
      .getElementsByTagName('li');
  
    var openSubMenuOnClick = function(event) {
      // If mobile nav menu is not open, do nothing
      var mobileNavOpen = document.getElementsByClassName('mm-ocd--open');
      if (mobileNavOpen.length == 0) {
        return;
      }
  
      var subMenu = this.getElementsByTagName('ul');
      if (subMenu.length > 0) {
        navigator.openPanel(subMenu[0]);
        event.preventDefault();
      }
      event.stopPropagation();
    };
  
    for (var i = 0; i < elements.length; i++) {
      elements[i].addEventListener('click', openSubMenuOnClick, false);
    }
  
    // hide the menu initially so it doesn't intercept clicks
    document.querySelector('.mm-ocd--left').style.visibility = 'hidden';
  }
});
