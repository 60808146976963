import React, {useState} from 'react';

const GmCheckbox = ({ name, value, checked, gmsRef }) => {

  const [isChecked, setIsChecked] = useState(checked);

  const onClick = (event) => {
    gmsRef.current.forEach(gm => {
      if (gm.value === value) {
        gm.checked = !isChecked;
      }
    });
    setIsChecked(!isChecked);
  }

  return (
    <div>
      <div className="attr">
        <div className="name">
          <input type="checkbox" onClick={onClick} defaultChecked={isChecked}/>
        </div>
        <div className="value">{name}</div>
      </div>
    </div>
  );
};

export default GmCheckbox;
