import { Map } from 'react-leaflet';
import L from 'leaflet';
import { initialMapLocation } from './helpers/url';
import { CENTER_XY, UNPROJECT_Z, MAX_XY } from './constants';

class MapExtended extends Map {
  createLeafletElement(props) {
    const LeafletMapElement = super.createLeafletElement(props);
    const mapLocation = initialMapLocation();

    const southWest = LeafletMapElement.unproject([0, MAX_XY], UNPROJECT_Z);
    const northEast = LeafletMapElement.unproject([MAX_XY, 0], UNPROJECT_Z);
    let initialCenter = LeafletMapElement.unproject(
      [parseInt(mapLocation.x, 10), parseInt(mapLocation.y, 10)],
      UNPROJECT_Z,
    );

    // TODO: fix this ugly code
    if (mapLocation.x === -128) {
      initialCenter = LeafletMapElement.unproject(
        [CENTER_XY, CENTER_XY],
        UNPROJECT_Z,
      );
    }

    LeafletMapElement.setMaxBounds(new L.LatLngBounds(southWest, northEast));
    LeafletMapElement.setView(initialCenter, mapLocation.z);

    return LeafletMapElement;
  }
}

export default MapExtended;
