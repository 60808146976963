import React from 'react';

class Checkboxes extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const items = this.props.items
    const rows = items.map((item) =>
      <div key={item.value} className="row">
        <input id={item.id} type="checkbox" name={item.name} value={item.value} onChange={item.onChange.bind(this, this.props.paramName)} defaultChecked={item.checked} />
        <label htmlFor={item.id}>{item.label}</label>
      </div>
    );
    return (
      <div className="col-sm-12 col-md-4 col-lg-4">
        <div className="row">
          <h3>{this.props.header}</h3>
        </div>
        {rows}
      </div>
    )
  }
}

export default Checkboxes;
