import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SatelliteConventionMetrics from './SatelliteConventionMetrics';

class SatelliteConventionList extends React.Component {
  renderSatelliteConventions() {
    const { satelliteConventions } = this.props;

    return satelliteConventions.map(satelliteConvention => (
      <tr
        key={satelliteConvention.id}
        style={{
          textDecoration: satelliteConvention.active ? '' : 'line-through',
        }}
      >
        <td>
          <Link to={`/satellite_conventions/${satelliteConvention.id}`}>
            {satelliteConvention.name}
          </Link>
        </td>
        <td>{`${satelliteConvention.city}, ${satelliteConvention.state} - ${
          satelliteConvention.country
        }`}</td>
        <td>
          {satelliteConvention.satellite_convention_attendees_total_count}
        </td>
        <td>
          {satelliteConvention.satellite_convention_attendees_attended_count}
        </td>
        <td>
          <Link
            to={`/satellite_conventions/${satelliteConvention.id}/edit`}
            className="icon-font"
          >
            <span>
              <i className="fa fa-edit" />
            </span>
          </Link>
        </td>
      </tr>
    ));
  }

  render() {
    const { conventions } = this.props;
    const { convention } = this.props;
    const { onConventionChange } = this.props;
    const { satelliteConventionMetrics } = this.props;
    const { admin } = this.props;

    return (
      <div>
        <div className="page-title">Satellite Conventions</div>
        <div className="panel">
          <div className="ribbon-top">
            <div className="right" style={{ margin: '10px 10px 0 0' }}>
              <select value={convention.id} onChange={onConventionChange}>
                {conventions.map(conv => (
                  <option key={conv.id} value={conv.id}>
                    {conv.name}
                  </option>
                ))}
              </select>
            </div>
            <ul className="left">
              {admin && (
                <li className="btn">
                  <Link to="/satellite_conventions/new">
                    <i className="fa fa-plus-circle fa-2x" />
                    <label>New Satellite Convention</label>
                  </Link>
                </li>
              )}
              <li className="btn">
                <a
                  href={`/satellite_conventions/export?convention_id=${
                    convention.id
                  }`}
                >
                  <i className="fa fa-plus-circle fa-2x" />
                  <label>Export</label>
                </a>
              </li>
            </ul>
          </div>

          <SatelliteConventionMetrics
            satelliteConventionMetrics={satelliteConventionMetrics}
          />

          <table className="records" cellSpacing="0" cellPadding="0">
            <thead>
              <tr>
                <th>Name</th>
                <th>Location</th>
                <th>Badges Sold</th>
                <th>Attendance</th>
                <th />
              </tr>
            </thead>
            <tbody>{this.renderSatelliteConventions()}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

SatelliteConventionList.propTypes = {
  conventions: PropTypes.arrayOf(PropTypes.object).isRequired,
  convention: PropTypes.shape().isRequired,
  satelliteConventionMetrics: PropTypes.arrayOf(PropTypes.object),
  satelliteConventions: PropTypes.arrayOf(PropTypes.object),
  onConventionChange: PropTypes.func.isRequired,
  admin: PropTypes.bool.isRequired,
};

SatelliteConventionList.defaultProps = {
  satelliteConventionMetrics: [],
  satelliteConventions: [],
};

export default SatelliteConventionList;
