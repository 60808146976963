import React from 'react'
import ReactDOM from 'react-dom/client'

function autoload(Component, className) {
  document.addEventListener('DOMContentLoaded', () => {
    const nodes = document.getElementsByClassName(`${className}-component`);
    Array.from(nodes).forEach(node => {
      const props = JSON.parse(node.getAttribute('data-props'));
      ReactDOM.createRoot(node).render(<Component {...props} />);
    });
  });

  return Component;
}

export default autoload