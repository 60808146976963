import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

class SatelliteConventionMetrics extends React.Component {
  renderSatelliteConventionMetrics() {
    const { satelliteConventionMetrics } = this.props;

    return satelliteConventionMetrics.map(satelliteConventionMetric => (
      <div key={satelliteConventionMetric.name} className="attr">
        <div className="name">{`${satelliteConventionMetric.name}:`}</div>
        <div className="value">{satelliteConventionMetric.value}</div>
      </div>
    ));
  }

  render() {
    const { satelliteConventionMetrics } = this.props;
    if (satelliteConventionMetrics === null) return null;

    return (
      <div>
        <div className="panel_titlebar">Metrics</div>
        <div className="attributes">
          {this.renderSatelliteConventionMetrics()}
        </div>
      </div>
    );
  }
}

SatelliteConventionMetrics.propTypes = {
  satelliteConventionMetrics: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SatelliteConventionMetrics;
