/* global $  */
/* global document  */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const SetAsDefaultLink = ({
  tooltipKey,
  message,
  allowHtml,
  handleSetDefault,
  paymentMethod,
}) => {
  const renderMessageWithHTML = messageWithoutHtml => (
    <p dangerouslySetInnerHTML={{ __html: messageWithoutHtml }} />
  );

  const renderMessageWithoutHTML = messageWithHtml => <p>{messageWithHtml}</p>;

  const dropdownId = `drop${tooltipKey}`;

  return (
    <span className="info">
      <label onClick={() => handleSetDefault(paymentMethod)} className="tooltip hover position-right">
        <i className="red fa fa-check"></i>
        <input type="checkbox" />
        <div className="tooltiptext content">
          {allowHtml
            ? renderMessageWithHTML(message)
            : renderMessageWithoutHTML(message)}
        </div>
      </label>
    </span>
  );
};


SetAsDefaultLink.propTypes = {
  tooltipKey: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  allowHtml: PropTypes.bool,
  handleSetDefault: PropTypes.func.isRequired,
  paymentMethod: PropTypes.shape().isRequired,
};

SetAsDefaultLink.defaultProps = {
  allowHtml: false,
};

export default SetAsDefaultLink;
