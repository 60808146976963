import React, { useState, useEffect } from 'react'
import { complement, either, isEmpty, isNil, pathOr } from 'ramda'
import { useActionCable } from 'use-action-cable'
import Notification from './Notification'

const observerOptions = {
  childList: true,
  attributes: true,
  subtree: true
}

const NotificationsContainer = ({ userId, actionCableOn, error, alert, notice, htmlSafe }) => {
  const [notifications, setNotifications] = useState(setFlash())
  const signedIn = userId !== -1
  const callback = (data) => {
    const flashLocation = ['0', 'target', 'dataset', 'flash']
    const flashMessage = pathOr(null, flashLocation)(data)
    const isValidValue = complement(either(isNil, isEmpty))

    if (isValidValue(flashMessage)) {
      try {
        const message = JSON.parse(flashMessage)

        // Only add the notification is the message has a user property
        if (message && message.user) {
          setNotifications((currentNotifications) => [
            ...currentNotifications,
            message
          ])
        }
      } catch (e) {
        console.log('Notification error', e, flashMessage)
      }
    }
  }

  useEffect(() => {
    const targetNode = document.querySelector('[data-flash]')
    const observer = new MutationObserver(callback)
    observer.observe(targetNode, observerOptions)
  }, [])

  const channelHandlers = (channelInfo) => ({
    connected() {
      console.log('Websocket connected to', channelInfo)
    },
    disconnected() {
      console.log('Websocket disconnected from', channelInfo)
    },
    received: (notification) => {
      console.log('Websocket received message', {
        ...channelInfo,
        ...notification
      })

      setNotifications((currentNotifications) => [
        ...currentNotifications,
        notification
      ])
    }
  })

  if (actionCableOn && signedIn) {
    useActionCable(
      { channel: 'NotificationsChannel', id: userId },
      channelHandlers({ channel: 'NotificationsChannel', id: userId }),
    );
  }

  function removeNotification(nId) {
    setNotifications((currentNotifications) =>
      currentNotifications.filter((notification) => notification.nId != nId)
    )
  }

  function setFlash() {
    let initialNotifications = []

    if (error && error.text) {
      if (!error.icon) {
        error.icon = 'fa-alert-o'
      }
      initialNotifications.push({
        nId: 1,
        level: 'high',
        icon: error.icon,
        text: error.text,
        link: error.link,
        timeout: error.timeout,
        htmlSafe: htmlSafe
      })
    }
    if (alert && alert.text) {
      if (!alert.icon) {
        alert.icon = 'fa-alert-o'
      }
      initialNotifications.push({
        nId: 2,
        level: 'med',
        icon: alert.icon,
        text: alert.text,
        link: alert.link,
        timeout: alert.timeout,
        htmlSafe: htmlSafe
      })
    }
    if (notice && notice.text) {
      if (!notice.icon) {
        notice.icon = 'fa-check-square-o'
      }
      if (notice.timeout === null) {
        notice.timeout = 5000
      }
      initialNotifications.push({
        nId: 3,
        level: 'low',
        icon: notice.icon,
        text: notice.text,
        link: notice.link,
        timeout: notice.timeout,
        htmlSafe: htmlSafe
      })
    }
    return initialNotifications
  }

  return (
    <div className="notifications">
      {notifications.map((notification) => (
        <Notification
          key={notification.nId}
          nId={notification.nId}
          level={notification.level}
          icon={notification.icon}
          text={notification.text}
          link={notification.link}
          timeout={notification.timeout}
          htmlSafe={notification.htmlSafe}
          removeNotification={removeNotification}
        />
      ))}
    </div>
  )
}

export default NotificationsContainer
