import React, { useState, useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useActionCable } from 'use-action-cable';
import Scanner from '../scanner/Scanner';
import ScannerBar from './ScannerBar';
import FilterBar from './FilterBar';
import ScanRow from './ScanRow';
import ManualScanModal from './ManualScanModal';
import axios from 'axios';

const HOST_INDEX_ENDPOINT   = (hostId, conId, page, filters) => `/api/v1/hosts/${hostId}/cons/${conId}/scans?page=${page}&filters=${filters}`;
const EVENT_INDEX_ENDPOINT  = (eventId, page, filters) => `/api/v1/events/${eventId}/scans?page=${page}&filters=${filters}`;
const HOST_REDEEM_ENDPOINT  = (hostId, conId) => `/api/v1/hosts/${hostId}/cons/${conId}/scans/redeem`;
const EVENT_REDEEM_ENDPOINT = eventId => `/api/v1/events/${eventId}/scans/redeem`;
const GUEST_REDEEM_ENDPOINT = id => `/api/v1/scans/${id}/guest_redeem`;
const ISSUE_TICKET_ENDPOINT = id => `/api/v1/scans/${id}/issue_ticket`;
const REFRESH_SCAN_ENDPOINT = id => `/api/v1/scans/${id}`;
const DISMISS_SCAN_ENDPOINT = id => `/api/v1/scans/${id}`;

const TicketRedemptions = ({ conId, hostId, eventId=0, scanWindowOpen=true, actionCableOn }) => {
  const [focus, setFocus] = useState(document.hasFocus()); // indicates if page has focus / tab is active
  const [scans, setScans] = useState([]); // list of ScanToRedeem objects loaded on page
  const [receivedScan, setReceivedScan] = useState({}); // most recent ScanToRedeem object received from websocket
  const [filterList, setFilterList] = useState([]); // list of ScanToRedeem states currently filtering by
  const [guestScanning, setGuestScanning] = useState({enabled: false, host:{}}); // controls if guest scanning is active, control with toggleGuestScanning
  const [showSpinner, setShowSpinner] = useState(false); // toggle to show spinner
  const [optionsOpenID, setOptionsOpenID] = useState(null);
  const [hasMore, setHasMore] = useState(true); // indicates if there are more scans to load
  const [currentPage, setCurrentPage] = useState(1); // current page of scans loaded, used for infinite scroll
  const [showManualScan, setShowManualScan] = useState(false); // toggle to show manual scan modal
  const [scanCounts, setScanCounts] = useState({
    "redeemed": 0,
    "partially_redeemed": 0,
    "guest_redeemed": 0,
    "can_issue": 0,
    "can_over_issue": 0,
    "can_comp": 0,
    "can_over_comp": 0,
    "can_sell_or_comp": 0,
    "can_over_sell_or_comp": 0,
    "pending_sale": 0,
    "event_selection": 0,
    "past_event": 0,
    "future_event": 0,
    "ticket_error": 0,
    "event_error": 0,
    "badge_error": 0,
    "approval_pending": 0,
    "approval_denied": 0
  }); // overall ScanToRedeem state counts
  const timerID = useRef(null);
  const hostScanning = eventId === 0;

  useEffect(() => {
    let scan = receivedScan;
    if (!scan) return;
  
    if (scan.hidden) {
      setScans(scans.filter(s => s.id !== scan.id));
      setScanCounts({...scanCounts, [scan.state]: Math.max(scanCounts[scan.state] - 1,0)});
      return;
    }

    let existing = scans.find(s => s.id === scan.id);
    if (existing) {
      setScanCounts({...scanCounts, [existing.state]: Math.max(scanCounts[existing.state] - 1,0), [scan.state]: scanCounts[scan.state] + 1});
    } else {
      setScanCounts({...scanCounts, [scan.state]: scanCounts[scan.state] + 1});
    }

    // if the scan list is long, updated scans are inserted 3 from the top to avoid misclicks
    if (scans.length < 4) {
      setScans([...scans.filter(s => s.id !== scan.id), scan]);
    } else {
      setScans([...scans.slice(0, 3).filter(s => s.id !== scan.id), scan, ...scans.slice(3).filter(s => s.id !== scan.id)]);
    }
  }, [receivedScan]);


  const channelHandlers = channelInfo => ({
    connected() {
      console.log('Websocket connected to', channelInfo);
    },
    disconnected() {
      console.log('Websocket disconnected from', channelInfo);
    },
    received: scan => {
      console.log('Websocket received message', { ...channelInfo, 'scan': scan });
      setReceivedScan(scan);
    },
  });

  if (actionCableOn) {
    if (hostScanning) {
      useActionCable(
        { channel: 'HostScansChannel', id: hostId },
        channelHandlers({ channel: 'HostScansChannel', id: hostId }),
      );
    } else {
      useActionCable(
        { channel: 'EventScansChannel', id: eventId },
        channelHandlers({ channel: 'EventScansChannel', id: eventId }),
      );
    }
  } else {
    useEffect(() => {
      if ((focus || Object.keys(scanCounts).length === 0) && !guestScanning.enabled) {
        timerID.current = setInterval(fetchRecentScans, 20000);
      } else {
        clearInterval(timerID.current);
      }

      return () => {
        clearInterval(timerID.current);
      };
    }, [focus, guestScanning.enabled, filterList]);
  }

  // fetches the first page of scans for the current filters.
  // adds new scans to the top of the list, and floats any updated existing
  // scans to their correct position. removes any existing scans that don't 
  // match the current filters.
  const fetchRecentScans = async () => {
    let endpoint = hostScanning ? HOST_INDEX_ENDPOINT(hostId, conId, 1, filterList.join(',')) : EVENT_INDEX_ENDPOINT(eventId, 1, filterList.join(','));
    await axios
      .get(endpoint)
      .then(response => {
        let scanIds = response.data.scans.map(scan => scan.id);

        if (filterList.length > 0) {
          setScans([...response.data.scans, ...scans.filter(scan => (!scanIds.includes(scan.id) && filterList.includes(scan.state)))]);
        } else {
          setScans([...response.data.scans, ...scans.filter(scan => !scanIds.includes(scan.id))]);
        }

        setScanCounts(response.data.meta.state_counts)
        setHasMore(currentPage < response.data.meta.pagination.total_pages)
      })
      .catch(error => {console.log(error)});
  }

  // fetches the state counts for the current filters.
  const fetchScanCounts = async () => {
    let endpoint = hostScanning ? HOST_INDEX_ENDPOINT(hostId, conId, 1, filterList.join(',')) : EVENT_INDEX_ENDPOINT(eventId, 1, filterList.join(','));
    await axios
      .get(endpoint)
      .then(response => {
        setScanCounts(response.data.meta.state_counts)
      })
      .catch(error => {console.log(error)});
  }

  // fetches the next page of scans for the current filters.
  const fetchMoreScans = async () => {
    let endpoint = hostScanning ? HOST_INDEX_ENDPOINT(hostId, conId, currentPage + 1, filterList.join(',')) : EVENT_INDEX_ENDPOINT(eventId, currentPage + 1, filterList.join(','));
    await axios
      .get(endpoint)
      .then(response => {
        let scanIds = response.data.scans.map(scan => scan.id);

        if (filterList.length > 0) {
          setScans([...scans.filter(scan => (!scanIds.includes(scan.id) && filterList.includes(scan.state))), ...response.data.scans]);
        } else {
          setScans([...scans.filter(scan => !scanIds.includes(scan.id)), ...response.data.scans]);
        }

        setScanCounts(response.data.meta.state_counts)
        setHasMore(response.data.meta.pagination.page < response.data.meta.pagination.total_pages)
        setCurrentPage(response.data.meta.pagination.page);
      })
      .catch(error => {console.log(error)});
  }

  // whenever filters change, reset the scan list and fetch the first page of scans
  useEffect(() => {
    setCurrentPage(1);
    fetchRecentScans()
  }, [filterList]);

  const dismissScan = async (id) => {
    setShowSpinner(true);
    await axios
      .delete(DISMISS_SCAN_ENDPOINT(id))
      .then(response => {
        if (response.status == 200) {
          setScans(scans.filter(scan => scan.id !== id));
        }
        fetchScanCounts();
      })
      .catch(error => {console.log(error)});
    setShowSpinner(false);
  };

  const issueTicket = async (id, oversell, comp) => {
    setShowSpinner(true);
    await axios
      .post(ISSUE_TICKET_ENDPOINT(id), { oversell: oversell, comp: comp })
      .then(response => {
        setScans([response.data.scan, ...scans.filter(scan => scan.id !== response.data.scan.id)]);
        fetchScanCounts();
      })
      .catch(error => {console.log(error)});
    setShowSpinner(false);
  };

  const sortScans = (scans) => {
    return scans.sort((a, b) => new Date(a.updated_at) < new Date(b.updated_at) ? 1 : -1);
  }

  const toggleFilters = (filters) => {
    if (filters.some(filter => filterList.includes(filter))) {
      setFilterList(filterList.filter(filter => !filters.includes(filter)));
    } else {
      setFilterList([...filterList, ...filters]);
    }
  };

  const toggleOptions = async (id) => {
    if (id === optionsOpenID) {
      setOptionsOpenID(null);
    } else {
      setOptionsOpenID(id);
      if (!actionCableOn) {
        await axios
          .get(REFRESH_SCAN_ENDPOINT(id))
          .then(response => {
            let index = scans.findIndex(scan => scan.id === id);
            let existing = scans[index];
            let updated = response.data.scan;
            if (existing.state !== updated.state || existing.additional_tickets !== updated.additional_tickets) {
              setScans([...scans.slice(0, index), updated, ...scans.slice(index + 1)])
            }
          })
          .catch(error => {console.log(error)});
      }
    }
  }

  const pendingScan = (badgeCode) => {
    return {badge_code: badgeCode, state: 'pending_response', message: '', ticketholder: {first_name: badgeCode, last_name: ''}, additional_tickets: 0, id: Math.random(), updated_at: new Date()};
  }

  const redeemForEvent = async (id, badgeCode, eventId) => {
    let pending = pendingScan(badgeCode);
    setScans([pending, ...scans.filter(scan => scan.id !== id)]);
    await axios
      .post(EVENT_REDEEM_ENDPOINT(eventId), { badge_code: badgeCode, host_id: hostId })
      .then(response => {
        setScans([response.data.scan, ...scans.filter(scan => scan.id !== response.data.scan.id && scan.id !== pending.id)]);
      }).catch(error => {console.log(error)});
  }

  const onScan = async (badgeCode) => {
    let pending = pendingScan(badgeCode);
    setScans([pending, ...scans]);
    if (guestScanning.enabled) {
      await axios
        .post(GUEST_REDEEM_ENDPOINT(guestScanning.host.id), { badge_code: badgeCode })
        .then(response => {
          setScans([response.data.scan, response.data.guest_scan, ...scans.filter(scan => scan.id !== response.data.guest_scan.id && scan.id !== response.data.scan.id && scan.id !== pending.id)]);
          if (response.data.scan.additional_tickets === 0) {
            if (optionsOpenID === guestScanning.host.id) {
              setOptionsOpenID(null);
            }
            setGuestScanning({enabled:false, host: {}});
          } else {
            setGuestScanning({enabled:true, host: response.data.scan});
          }
        }).catch(error => {console.log(error); setScans(scans.filter(scan => scan.id !== pending.id))});
    } else {
      let endpoint = hostScanning ? HOST_REDEEM_ENDPOINT(hostId, conId) : EVENT_REDEEM_ENDPOINT(eventId);
      await axios
        .post(endpoint, { badge_code: badgeCode })
        .then(response => {
          setScans([response.data.scan, ...scans.filter(scan => scan.id !== response.data.scan.id && scan.id !== pending.id)]);
        }).catch(error => {console.log(error); setScans(scans.filter(scan => scan.id !== pending.id))});
    }
    await fetchScanCounts();
  }

  const toggleGuestScanning = (id) => {
    if (guestScanning.enabled && id === guestScanning.host.id) {
      setGuestScanning({enabled: false, host: {}});
      if (optionsOpenID === id) {
        setOptionsOpenID(null);
      }
    } else {
      let hostScan = scans.find(scan => scan.id === id);
      setGuestScanning({enabled: true, host: hostScan});
      setScans([hostScan, ...sortScans(scans).filter(scan => scan.id !== hostScan.id)])
    }
  }

  return (
    <div className='redemptions-container'>
      {
        showManualScan && <div className='content' style={{padding: 0}}>
          <ManualScanModal scan={onScan} close={() => setShowManualScan(false)}/>
        </div>
      }
      {scanWindowOpen && <ScannerBar focus={focus} guestScanning={guestScanning} setShowManualScan={setShowManualScan} />}
      {scanWindowOpen && <Scanner onFocus={() => setFocus(true)} onBlur={() => setFocus(false)} onScan={onScan} pattern={'^[B,b]-[A-Za-z0-9]{12}$'} timeout={250}/>}
      <FilterBar
        scanCounts={scanCounts}
        filterList={filterList}
        toggleFilters={toggleFilters}
      />
      <InfiniteScroll
        dataLength={scans.length}
        next={fetchMoreScans}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
      >
        <div>
          <table className="scan-ticket-table">
            <tbody style={{columnCount: 5}}>
              {scans.map((scan) =>
                <ScanRow
                  key={scan.id}
                  scan={scan}
                  optionsOpenID={optionsOpenID}
                  toggleOptions={toggleOptions}
                  showSpinner={showSpinner}
                  dismissScan={dismissScan}
                  issueTicket={issueTicket}
                  hostScanning={hostScanning}
                  guestScanning={guestScanning}
                  toggleGuestScanning={toggleGuestScanning}
                  redeemForEvent={redeemForEvent}
                />
              )}
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
    </div>
  )
}

export default TicketRedemptions;