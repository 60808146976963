import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Checkboxes from './Checkboxes';
import SearchQuery from './SearchQuery';
import SearchFilter from './SearchFilter';
import qs from 'qs';
import { equals } from 'ramda';
import 'es6-string-polyfills';

class SearchOptions extends Component {
  static displayName = 'SearchOptions';

  constructor(props) {
    super(props);

    this.state = {
      on: true,
      visible: true,
      defaultOptions: {
        opt: ['hh', 'ta'],
        ag: ['eo', 'ma', 'tn', 'tw', 'un'],
        exp: ['ep', 'no', 'so'],
      },
      searchOptions: props.searchOptions,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleButtonToggle = this.handleButtonToggle.bind(this);
  }

  componentWillMount() {
    this.changeStateFromLocation();
  }

  changeStateFromLocation() {
    let locationObject = qs.parse(window.location.search.substring(1));
    if (
      locationObject['opt'] ||
      locationObject['exp'] ||
      locationObject['ag']
    ) {
      this.setState({ on: true });
    } else {
      this.setState({ on: false });
    }
  }

  handleInputChange(paramName, event) {
    let checkedValues = this.state.searchOptions;
    if (event.target.checked == false) {
      let index = checkedValues[paramName].indexOf(event.target.value);
      if (index !== -1) {
        checkedValues[paramName].splice(index, 1).sort();
        this.setState({ searchOptions: checkedValues });
        this.props.onSubmit(checkedValues);
      }
    } else {
      checkedValues[paramName] = checkedValues[paramName]
        .concat([event.target.value])
        .sort();
      this.setState({ searchOptions: checkedValues });
      this.props.onSubmit(checkedValues);
    }
  }

  handleSubmit(e) {
    // let on = !equals(this.state.searchOptions, this.state.defaultOptions);
    // this.setState({ on, visible: !this.state.visible });
    // this.props.onSubmit(this.state.searchOptions);
    // e.preventDefault();
  }

  handleButtonToggle(e) {
    this.setState({ visible: !this.state.visible });
    e.preventDefault();
  }

  render() {
    const genericTickets = this.props.showGeneric ? (
      <li className="btn">
        <a href={this.props.genericPath}>
          <i className="fa fa-ticket-horz fa-2x"></i>
          <label>Generic Tickets</label>
        </a>
      </li>
    ) : (
      ''
    );
    return (
      <div className="event-finder-options">
        <div className="ribbon-top">
          <ul className="left">
            {/* <li className="btn">
              <a onClick={this.handleButtonToggle}>
                <i
                  className={
                    this.state.on ? 'fa fa-cog fa-2x on' : 'fa fa-cog fa-2x'
                  }
                ></i>
                <label>
                  Search Options
                  {this.state.on && (
                    <span className="on">
                      <b>ON</b>
                    </span>
                  )}
                </label>
              </a>
            </li> */}
            {genericTickets}
          </ul>
          <ul className="right">
            <li className="btn right">
              <a data-micromodal-trigger='share-event-search-modal'>
                <i className="fa fa-share-square-o fa-2x"></i>
                <label>Share Search Results</label>
              </a>
            </li>
          </ul>

          {this.state.visible && (
            <div className="ribbon-row">
              <div className="event-finder-options">
                <SearchFilter
                  dayMapping={this.props.dayMapping}
                  onChange={this.props.onChange}
                  searchFilter={this.props.searchFilter}
                  searchQuery={this.props.searchQuery}
                />

                <SearchQuery
                  onChange={this.props.onQueryChange}
                  defaultQuery={this.props.defaultQuery}
                />


                <form onSubmit={this.handleSubmit}>
                  <div className="grid">
                    <div className="row" style={{width:'90%', margin: '0 auto 1em auto '}}>
                      <Checkboxes
                        header="Show events that:"
                        paramName="opt"
                        items={[
                          {
                            name: 'tickets_available',
                            id: 'tickets_available',
                            value: 'ta',
                            label: 'Have sold out',
                            onChange: this.handleInputChange,
                            checked: this.state.searchOptions.opt.includes('ta'),
                          },
                          {
                            id: 'has_happened',
                            name: 'has_happened',
                            value: 'hh',
                            label: 'Have already happened',
                            onChange: this.handleInputChange,
                            checked: this.state.searchOptions.opt.includes('hh'),
                          },
                        ]}
                      />
                      <Checkboxes
                        header="Age Required:"
                        paramName="ag"
                        items={[
                          {
                            name: '12_and_under',
                            id: '12_and_under',
                            value: 'un',
                            label: 'Kids Only (12 and under)',
                            onChange: this.handleInputChange,
                            checked: this.state.searchOptions.ag.includes('un'),
                          },
                          {
                            id: 'everyone',
                            name: 'everyone',
                            value: 'eo',
                            label: 'Everyone (6+)',
                            onChange: this.handleInputChange,
                            checked: this.state.searchOptions.ag.includes('eo'),
                          },
                          {
                            id: 'teen',
                            name: 'teen',
                            value: 'tn',
                            label: 'Teen (13+)',
                            onChange: this.handleInputChange,
                            checked: this.state.searchOptions.ag.includes('tn'),
                          },
                          {
                            id: 'mature',
                            name: 'mature',
                            value: 'ma',
                            label: 'Mature (18+)',
                            onChange: this.handleInputChange,
                            checked: this.state.searchOptions.ag.includes('ma'),
                          },
                          {
                            id: 'adult',
                            name: 'adult',
                            value: 'tw',
                            label: 'Adult (21+)',
                            onChange: this.handleInputChange,
                            checked: this.state.searchOptions.ag.includes('tw'),
                          },
                        ]}
                      />
                      <Checkboxes
                        header="Experience Required:"
                        paramName="exp"
                        items={[
                          {
                            name: 'none',
                            id: 'none',
                            value: 'no',
                            label: 'None',
                            onChange: this.handleInputChange,
                            checked: this.state.searchOptions.exp.includes('no'),
                          },
                          {
                            id: 'some',
                            name: 'some',
                            value: 'so',
                            label: 'Some',
                            onChange: this.handleInputChange,
                            checked: this.state.searchOptions.exp.includes('so'),
                          },
                          {
                            id: 'expert',
                            name: 'expert',
                            value: 'ep',
                            label: 'Expert',
                            onChange: this.handleInputChange,
                            checked: this.state.searchOptions.exp.includes('ep'),
                          },
                        ]}
                      />
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="large-12 medium-12 small-12 columns">
                      <div className="buttons text-center">
                        <input type="submit" value="Submit" />
                      </div>
                    </div>
                  </div> */}
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SearchOptions;
