import axios from 'axios';
import { handleCategory, objectToUrl } from '../helpers/url';
import { filterParamsEmptyStrings } from '../helpers/utils';

const LG_API_ENDPOINT = '/api/v1/lg_elements';

/* eslint-disable */
export const fetchLgElements = ({
  topLeft,
  bottomRight,
  convention,
  z,
  c,
  preview,
  search,
  category,
  aggregations,
  includeSearch = false,
}) => {
  let object = handleCategory({
    topLeft,
    bottomRight,
    convention,
    z,
    c,
    preview,
    category,
    aggregations,
  });

  if (includeSearch) {
    object = { ...object, search };
  }

  const queryString = objectToUrl(filterParamsEmptyStrings(object));

  return axios.get(`${LG_API_ENDPOINT}?${queryString}`);
};
