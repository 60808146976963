import axios from 'axios';

document.addEventListener('DOMContentLoaded', () => {
  const flashContainer = document.querySelector('[data-flash]');
  const searchContactField = document.getElementById('contact_search_id');
  const searchContactType  = document.getElementById('contact_search_type');
  const target = document.getElementById('contact-search-results');

  if (searchContactField) {
    searchContactField.addEventListener('input', () => {
      const ident = searchContactField.value;
      const type  = searchContactType.value;

      if (ident.length > 3) {
        const location = 'contact_search?id=' + ident + '&type=' + type + '&format=js';

        axios
        .post(location)
        .then(response => {
          if (response.data.length > 0) {
            if (response.headers['content-type'].includes('text/javascript')) {
              window.location = response.data;
            } else {
              target.innerHTML = response.data;
            };
          } else {
            var errorJson = {
              user: -1,
              nId: Math.random().toString(36).substring(7),
              text: "We're sorry, the ID you entered cannot be found.",
              icon: 'fa-alert',
              level: 'high',
              timeout: 5000
            };

            target.innerHTML = "";
            flashContainer.dataset.flash = JSON.stringify(errorJson);
          };
        });
      };
    });
  };
});
