import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { CountryDropdown } from 'react-country-region-selector';
import InfoTooltip from '../../helpers/InfoTooltip';
import GoogleMapsAutocomplete from './GoogleMapsAutocomplete';
import NotFound from './NotFound';

class SatelliteConventionForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      satelliteConvention: props.satelliteConvention,
    };

    this.handleCountryInputChange = this.handleCountryInputChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handlePlaceChange = this.handlePlaceChange.bind(this);
    this.handlePopulateForm = this.handlePopulateForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { satelliteConvention } = this.state;
    const { convention } = this.props;

    if (!satelliteConvention.id) {
      this.updateSatelliteConvention('convention_id', convention.id);
    }
  }

  handlePlaceChange(placeId, placeLat, placeLng) {
    const { satelliteConvention } = this.state;

    const newSatelliteConvention = {
      ...satelliteConvention,
      google_maps_place_id: placeId,
      google_maps_place_lat: placeLat,
      google_maps_place_lng: placeLng,
    };

    this.setState({ satelliteConvention: newSatelliteConvention });
  }

  getContactAndAddressData(contactId) {
    axios
      .get(`/api/v1/contacts/${contactId}`)
      .then(response => {
        const { contact } = response.data.data;

        const { satelliteConvention } = this.state;
        const newSatelliteConvention = {
          ...satelliteConvention,
          name: contact.name,
        };
        newSatelliteConvention.address1 =
          contact.address && contact.address.address1
            ? contact.address.address1
            : '';
        newSatelliteConvention.address2 =
          contact.address && contact.address.address2
            ? contact.address.address2
            : '';
        newSatelliteConvention.city =
          contact.address && contact.address.city ? contact.address.city : '';
        newSatelliteConvention.state =
          contact.address && contact.address.state ? contact.address.state : '';
        newSatelliteConvention.zip =
          contact.address && contact.address.zip_code ? contact.address.zip_code : '';
        if (contact.address && contact.address.country) {
          if (contact.address.country === 'US') {
            newSatelliteConvention.country = 'United States';
          } else {
            newSatelliteConvention.country = contact.address.country;
          }
        } else {
          newSatelliteConvention.country = '';
        }

        this.setState({ satelliteConvention: newSatelliteConvention });
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleCountryInputChange(value, event) {
    const { target } = event;
    const { name } = target;

    this.updateSatelliteConvention(name, value);
  }

  handleInputChange(event) {
    const { target } = event;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.updateSatelliteConvention(name, value);
  }

  handlePopulateForm() {
    const contactGroupId = $('#contact_group_id').val();
    this.getContactAndAddressData(contactGroupId);
  }

  handleSubmit(e) {
    e.preventDefault();

    const { satelliteConvention } = this.state;
    const { onSubmit } = this.props;
    const instance = $(e.target);

    if (instance.parsley().validate()) {
      onSubmit(satelliteConvention);
    }
  }

  updateSatelliteConvention(key, value) {
    this.setState(prevState => ({
      satelliteConvention: {
        ...prevState.satelliteConvention,
        [key]: value,
      },
    }));
  }

  render() {
    const { convention } = this.props;
    const { satelliteConvention } = this.state;
    const { path } = this.props;

    if (!satelliteConvention.id && path === '/satellite_conventions/:id/edit')
      return <NotFound />;

    const cancelURL = satelliteConvention.id
      ? `/satellite_conventions/${satelliteConvention.id}`
      : '/satellite_conventions';
    const title = satelliteConvention.id
      ? 'Edit Satellite Convention'
      : 'New Satellite Convention';
    const submitButtonText = satelliteConvention.id ? 'Update' : 'Create';

    return (
      <div>
        <div className="page-title">{title}</div>
        <div className="panel">
          <form
            className="satelliteConventionForm"
            onSubmit={this.handleSubmit}
          >
            <div className="panel_titlebar">Organization Info</div>
            <div className="attributes">
              <div className="attr">
                <div className="name">Account ID:</div>
                <div className="value">
                  <input
                    type="text"
                    id="contact_group_id"
                    name="contact_group_id"
                    data-parsley-required
                    data-parsley-trigger="blur change keyup"
                    value={satelliteConvention.contact_group_id}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="info">
                  <InfoTooltip
                    tooltipKey="1"
                    message="Must be a Contact Group ID in the GCMS."
                  />
                </div>
              </div>

              <div className="attr">
                <div className="name" />
                <div className="value columns end">
                  <span className="form-note">
                    <a href="#" onClick={this.handlePopulateForm}>
                      Copy name and address from organization
                    </a>
                  </span>
                </div>
              </div>

              <div className="attr">
                <div className="name">Name:</div>
                <div className="value">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    data-parsley-required
                    data-parsley-maxlength="255"
                    data-parsley-trigger="blur change keyup"
                    value={satelliteConvention.name}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="info">
                  <InfoTooltip
                    tooltipKey="2"
                    message="This will be publicly displayed in event listings."
                  />
                </div>
              </div>

              <div className="attr">
                <div className="name">Convention:</div>
                <div className="value">
                  <input
                    type="hidden"
                    id="convention_id"
                    name="convention_id"
                    value={satelliteConvention.convention_id}
                    onLoad={this.handleInputChange}
                    onChange={this.handleInputChange}
                  />
                  {satelliteConvention.id
                    ? satelliteConvention.convention.name
                    : convention.name}
                </div>
              </div>

              <div className="attr">
                <div className="name">Active:</div>
                <div className="value">
                  <input
                    type="checkbox"
                    id="active"
                    name="active"
                    checked={satelliteConvention.active}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="info">
                  <InfoTooltip
                    tooltipKey="3"
                    message="Inactive events are not publicly visible."
                  />
                </div>
              </div>

              <div className="attr">
                <div className="name">Badge Limit:</div>
                <div className="value">
                  <input
                    type="text"
                    id="badge_limit"
                    name="badge_limit"
                    data-parsley-trigger="blur change keyup"
                    value={
                      satelliteConvention.badge_limit
                        ? satelliteConvention.badge_limit
                        : ''
                    }
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="info">
                  <InfoTooltip
                    tooltipKey="2"
                    message="This will be publicly displayed in event listings."
                  />
                </div>
              </div>
            </div>

            <hr />

            <div className="panel_titlebar">Location Info</div>

            <div className="attributes">
              <div className="attr">
                <div className="name">Address1:</div>
                <div className="value">
                  <input
                    type="text"
                    id="address1"
                    name="address1"
                    data-parsley-required
                    data-parsley-maxlength="255"
                    data-parsley-trigger="blur change keyup"
                    value={satelliteConvention.address1}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>

              <div className="attr">
                <div className="name">Address2:</div>
                <div className="value">
                  <input
                    type="text"
                    id="address2"
                    name="address2"
                    data-parsley-maxlength="255"
                    data-parsley-trigger="blur change keyup"
                    value={
                      satelliteConvention.address2
                        ? satelliteConvention.address2
                        : ''
                    }
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>

              <div className="attr">
                <div className="name">City, State, Zip:</div>
                <div className="value">
                  <div className="fluid-city">
                    <input
                      type="text"
                      id="city"
                      name="city"
                      data-parsley-required
                      data-parsley-maxlength="255"
                      data-parsley-trigger="blur change keyup"
                      value={satelliteConvention.city}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="fixed-state">
                    <input
                      type="text"
                      id="state"
                      name="state"
                      value={satelliteConvention.state}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="fixed-zip">
                    <input
                      type="text"
                      id="zip"
                      name="zip"
                      data-parsley-required
                      data-parsley-trigger="blur change keyup"
                      value={satelliteConvention.zip}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="attr">
                <div className="name">Country:</div>
                <div className="value">
                  <CountryDropdown
                    id="country"
                    name="country"
                    data-parsley-required
                    data-parsley-maxlength="255"
                    data-parsley-trigger="blur change keyup"
                    priorityOptions={['US', 'CA']}
                    value={satelliteConvention.country}
                    onChange={this.handleCountryInputChange}
                  />
                </div>
              </div>
            </div>

            <hr />
            <div className="panel_titlebar">Map Location</div>

            <div className="attributes">
              <GoogleMapsAutocomplete onPlaceChange={this.handlePlaceChange} />

              <div className="attr">
                <div className="name">Google Maps Place ID:</div>
                <div className="value">
                  <input
                    type="text"
                    id="google_maps_place_id"
                    name="google_maps_place_id"
                    data-parsley-required
                    data-parsley-maxlength="255"
                    data-parsley-trigger="blur change keyup"
                    value={satelliteConvention.google_maps_place_id}
                    onChange={this.handleInputChange}
                    disabled
                  />
                </div>
              </div>

              <div className="attr">
                <div className="name">Google Maps Place Lat:</div>
                <div className="value">
                  <input
                    type="text"
                    id="google_maps_place_lat"
                    name="google_maps_place_lat"
                    data-parsley-required
                    data-parsley-maxlength="255"
                    data-parsley-trigger="blur change keyup"
                    value={satelliteConvention.google_maps_place_lat}
                    onChange={this.handleInputChange}
                    disabled
                  />
                </div>
              </div>

              <div className="attr">
                <div className="name">Google Maps Place Lng:</div>
                <div className="value">
                  <input
                    type="text"
                    id="google_maps_place_lng"
                    name="google_maps_place_lng"
                    data-parsley-required
                    data-parsley-maxlength="255"
                    data-parsley-trigger="blur change keyup"
                    value={satelliteConvention.google_maps_place_lng}
                    onChange={this.handleInputChange}
                    disabled
                  />
                </div>
              </div>

              <hr />

              <span className="panel_titlebar">Description</span>
              <InfoTooltip
                tooltipKey="4"
                message="This will be publicly displayed in event listings."
              />

              <div className="attr">
                <div className="small-12 small-centered columns">
                  <p>
                    <textarea
                      id="description"
                      name="description"
                      data-parsley-required
                      data-parsley-trigger="blur change keyup"
                      value={satelliteConvention.description}
                      onChange={this.handleInputChange}
                    />
                  </p>
                </div>
              </div>
              <hr />
              <div className="panel_titlebar">Preview</div>
              <div className="row">
                <div className="small-12 small-centered columns">
                  <p>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: satelliteConvention.description,
                      }}
                    />
                  </p>
                </div>
              </div>

              <hr />

              <div className="attr">
                <div className="name" />
                <div className="value">
                  <button type="submit" className="button" validate="true">
                    {submitButtonText}
                  </button>
                  <Link to={cancelURL} className="button">
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

SatelliteConventionForm.propTypes = {
  convention: PropTypes.shape(),
  satelliteConvention: PropTypes.shape(),
  onSubmit: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};

SatelliteConventionForm.defaultProps = {
  convention: {},
  satelliteConvention: {
    contact_group_id: '',
    name: '',
    description: '',
    convention_id: '',
    active: false,
    badge_limit: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    google_maps_place_id: '',
    google_maps_place_lat: '',
    google_maps_place_long: '',
  },
};

export default SatelliteConventionForm;
