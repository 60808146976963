import React, { useState, Fragment, useEffect, useRef } from 'react';
import { equals, isEmpty, isNil } from 'ramda';
import PropTypes from 'prop-types';
import Control from '@skyeer/react-leaflet-custom-control';
import { CSSTransition } from 'react-transition-group';
import { isSearchOpen } from './helpers/url';
import { notEmpty, isValueSet, truncateString } from './helpers/utils';

/* eslint-disable */
const Search = ({
  onSearchChange,
  onSelectElement,
  mapLocation: { search: currentTerm = '', category: currentCategory },
  onSelectCategory,
  elements,
  categories,
  searchedElements,
  loaded,
  currentUserId,
}) => {
  const [search, setSearch] = useState(currentTerm);
  const [previousSearch, setPreviousSearch] = useState('');
  const [backClicked, setBackClicked] = useState(false);
  const [category, setCategory] = useState(currentCategory);
  const [showSearch, setShowSearch] = useState(isSearchOpen());
  const inputRef = useRef(null);
  const firstRef = useRef(null);


  const handleInputChange = event => {
    event.preventDefault();
    const { value } = event.target;

    setPreviousSearch(search);
    setSearch(value);
    onSearchChange(value);
  };

  const handleKeyPress = event => {
    event.preventDefault();

    if (
      event.key == 'Backspace' &&
      isEmpty(search) &&
      isEmpty(previousSearch)
    ) {
      setBackClicked(true);
    }

    if (event.key == 'Enter') {
      const element = searchedElements[0];
      if (element) {
        firstRef.current.focus();
        onSelectElement(element);
      }
    }

    setPreviousSearch(search);
  };

  const handleOnBlur = () => {
    if (currentTerm.length > 0) {
      // universal analytics commented out. To be replaced with GA4 tags at a later time.

      // dimension1 = UserId, dimension5 = SearchTerm
      // ga('send', 'event', 'Looking Glass', 'Searching', {
      //   dimension1: currentUserId,
      //   dimension5: currentTerm,
      // });
    }
  };

  const openNav = () => {
    setShowSearch(true);
  };

  const searchText = category ? `Search ${category}` : 'Search Looking Glass';

  const selectCategory = value => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    
    setCategory(value);
    onSelectCategory(value);
  };

  const closeNav = () => {
    setShowSearch(false);
  };

  const displayCategory = value => {
    if (notEmpty(searchedElements)) {
      return null;
    }

    if (isValueSet(search)) {
      return null;
    }

    if (isValueSet(category)) {
      return equals(value, category);
    }

    return isEmpty(category) || isNil(category);
  };

  const noResults = isValueSet(search) && isEmpty(searchedElements);

  const clearCategory = () => {
    setCategory(undefined);
    onSelectCategory(undefined);
  };

  const getIcon = element => {
    const { icon } = element._source;

    return icon;
  };

  const clearSearchAndCategory = () => {
    setCategory(undefined);
    setSearch('');
    onSearchChange('', true);
  };

  useEffect(() => {
    // deal with the backspace clearing the search and category

    if (backClicked) {
      setCategory(undefined);
      onSearchChange('', true);
      setBackClicked(false);
    }
  }, [backClicked]);

  return (
    <Control position="topleft">
      <Fragment>
        {!showSearch && (
          <div className="open-button-container">
            <button type="button" id="openbtn" onClick={openNav}>
              <i className="fa fa-chevron-right" />
            </button>
          </div>
        )}
        {showSearch && (
          <CSSTransition
            in={showSearch}
            appear
            timeout={300}
            classNames="my-node"
          >
            <aside id="search-sidebar">
              <div id="search-text-input">
                <div className="search-elements-container">
                  <ul>
                    {category && (
                      <li>
                        <div className="search-element-pill">
                          <a className="element-close" onClick={clearCategory}>
                            <i className="fa fa-times-circle"></i>
                          </a>
                          <span>{category}</span>
                        </div>
                      </li>
                    )}
                    <li className="keyword-search">
                      <input
                        ref={inputRef}
                        onChange={handleInputChange}
                        onKeyUp={handleKeyPress}
                        onBlur={handleOnBlur}
                        type="text"
                        name="SearchInput"
                        value={search}
                        placeholder={searchText}
                        autoComplete="off"
                      />
                    </li>
                    {isValueSet(search) && (
                      <button
                        type="button"
                        id="searchIcon"
                        onClick={clearSearchAndCategory}
                      >
                        <i className="fa fa-close" />
                      </button>
                    )}
                    {isEmpty(search) && (
                      <button type="button" id="searchIcon">
                        <i className="fa fa-search" />
                      </button>
                    )}
                  </ul>
                </div>
              </div>
              <button type="button" id="closebtn" onClick={closeNav}>
                <i className="fa fa-chevron-left" />
              </button>
              <div id="search-results-container">
                {loaded && (
                  <div className="search-results">
                    {categories &&
                      Object.keys(categories).map(
                        key =>
                          displayCategory(key) && (
                            <button
                              key={key}
                              type="button"
                              onClick={() => selectCategory(key)}
                              className="search-category"
                            >
                              <div className="category-icon">
                                <i className={`fa fa-${categories[key]}`} />
                              </div>
                              <div className="category-name">{key}</div>
                            </button>
                          ),
                      )}
                    {noResults && (
                      <button type="button" className="search-result">
                        <div className="result-name">Sorry, nothing found</div>
                      </button>
                    )}
                    {category &&
                      searchedElements &&
                      searchedElements.map((element, index) => {
                        if (index === 0) {
                          return (
                            <button
                              ref={firstRef}
                              tabIndex="-1"
                              key={element._source.id}
                              type="button"
                              onClick={() => onSelectElement(element)}
                              className="search-result"
                            >
                              <div className="result-icon">
                                <i className={`fa fa-${getIcon(element)}`} />
                              </div>
                              <div className="result-name">
                                {truncateString(element._source.name, 38)}
                              </div>
                            </button>
                          );
                        }

                        return (
                          <button
                            tabIndex="-1"
                            key={element._source.id}
                            type="button"
                            onClick={() => onSelectElement(element)}
                            className="search-result"
                          >
                            <div className="result-icon">
                              <i className={`fa fa-${getIcon(element)}`} />
                            </div>
                            <div className="result-name">
                              {truncateString(element._source.name, 38)}
                            </div>
                          </button>
                        );
                      })}
                    {!category &&
                      searchedElements &&
                      searchedElements.map((element, index) => {
                        if (index === 0) {
                          return (
                            <button
                              ref={firstRef}
                              tabIndex="-1"
                              key={element._source.id}
                              type="button"
                              onClick={() => onSelectElement(element)}
                              className="search-result"
                            >
                              <div className="result-icon">
                                <i className={`fa fa-${getIcon(element)}`} />
                              </div>
                              <div className="result-name">
                                {truncateString(element._source.name, 38)}
                              </div>
                            </button>
                          );
                        }
                        return (
                          <button
                            tabIndex="-1"
                            key={element._source.id}
                            type="button"
                            onClick={() => onSelectElement(element)}
                            className="search-result"
                          >
                            <div className="result-icon">
                              <i className={`fa fa-${getIcon(element)}`} />
                            </div>
                            <div className="result-name">
                              {truncateString(element._source.name, 38)}
                            </div>
                          </button>
                        );
                      })}
                  </div>
                )}
              </div>
            </aside>
          </CSSTransition>
        )}
      </Fragment>
    </Control>
  );
};

export default Search;

Search.propTypes = {
  onSelectCategory: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onSelectElement: PropTypes.func.isRequired,
  mapLocation: PropTypes.shape().isRequired,
  elements: PropTypes.arrayOf(PropTypes.object).isRequired,
};
