import React from 'react';

const FilterBar = ({scanCounts, filterList, toggleFilters}) => {
  const redeemedFilters = ['redeemed', 'guest_redeemed'];
  const pendingFilters = ['pending_sale', 'approval_pending'];
  const attentionFilters = ['partially_redeemed', 'can_issue', 'can_over_issue', 'can_comp', 'can_over_comp', 'can_sell_or_comp', 'can_over_sell_or_comp', 'event_selection', 'past_event', 'future_event', 'ticket_error'];
  const errorFilters = ['event_error', 'badge_error', 'approval_denied'];

  const filtersActive = (filters) => {
    return filters.some(filter => filterList.includes(filter));
  }

  const activeFilterClass = (filters) => { 
    return filtersActive(filters) ? 'scan-filter-button scan-filter-active' : 'scan-filter-button';
  }

  const filterCount = (filters) => {
    return filters.reduce((count, filter) => {
      if (scanCounts[filter] == undefined) {
        return 0
      }
      return count + scanCounts[filter];
    }, 0);
  }

  // This is a hack to prevent the double click from triggering the filter toggle
  // if the user refocuses the window by double clicking the filter button. Both
  // clicks are sent causing the filter to toggle twice, which is probably
  // not what they are trying to do. Triple clicks and on function normally.
  //
  // Currently disabled, here if someone complains about the issue above.
  const ignoreDoubleClick = (e, filters) => {
    // if (e.detail == 2) return;
    toggleFilters(filters);
  }

  return (
    <div className='scan-filter-bar'>
      <div className={activeFilterClass(redeemedFilters)} onClick={(e) => ignoreDoubleClick(e, redeemedFilters)}>
        <span className='scan-filter-box'>
          <i className='e-ticket-success-color fa fa-check fa-2x'></i>
          <div className='scan-filter-count'>{filterCount(redeemedFilters)}</div>
        </span>
      </div>
      <div className='scan-filter-separator'/>
      <div className={activeFilterClass(pendingFilters)} onClick={(e) => ignoreDoubleClick(e, pendingFilters)}>
        <span className='scan-filter-box'>
          <i className='e-ticket-pending-color fa fa-refresh fa-2x'></i>
          <div className='scan-filter-count'>{filterCount(pendingFilters)}</div>
        </span>
      </div>
      <div className='scan-filter-separator'/>
      <div className={activeFilterClass(attentionFilters)} onClick={(e) => ignoreDoubleClick(e, attentionFilters)}>
        <span className='scan-filter-box'>
          <i className='e-ticket-attention-color fa fa-alert fa-2x'></i>
          <div className='scan-filter-count'>{filterCount(attentionFilters)}</div>
        </span>
      </div>
      <div className='scan-filter-separator'/>
      <div className={activeFilterClass(errorFilters)} onClick={(e) => ignoreDoubleClick(e, errorFilters)}>
        <span className='scan-filter-box'>
          <i className='e-ticket-error-color fa fa-exclamation fa-2x'></i>
          <div className='scan-filter-count'>{filterCount(errorFilters)}</div>
        </span>
      </div>
    </div>
  )
};

export default FilterBar;