import React from 'react';
import PropTypes from 'prop-types';

const ScheduleSearchBar = ({ onSearch, search, enabled }) => {
  const handleInputChange = event => {
    event.preventDefault();
    const { value } = event.target;

    onSearch(value);
  };

  const handleSubmit = event => {
    event.preventDefault();
  };

  return (
    <div className="search-area">
      <form onSubmit={handleSubmit} role="search" className="form_menu">
        <input
          type="text"
          name="search"
          id="search"
          defaultValue={search}
          className="event-finder-search"
          onChange={handleInputChange}
          placeholder={
            enabled ? 'Search for keywords' : 'Events loading...hang on...'
          }
          disabled={!enabled}
        />
      </form>
    </div>
  );
};

export default ScheduleSearchBar;

ScheduleSearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
};
