/*
 * JSONDateParser
 *
 * Parses dates input as JSON string format
 */

function JSONDateParser(dateString) {
  this.dateString = dateString;
}

JSONDateParser.prototype.weekDay = function () {
  const localDate = new Date(this.year(), this.month() - 1, this.day(), '00', '00', '00');
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const dayNumber = localDate.getDay();

  return days[dayNumber];
};

JSONDateParser.prototype.year = function () {
  const year = this.dateString.substring(0, 4);

  return year;
};

JSONDateParser.prototype.month = function () {
  const month = this.dateString.substring(5, 7);

  return month;
};

JSONDateParser.prototype.day = function () {
  const day = this.dateString.substring(8, 10);

  return day;
};

JSONDateParser.prototype.hours = function () {
  let hours = this.dateString.substring(11, 13);

  if (hours < 1) {
    hours = 12
  } else if (hours >= 13) {
    hours = hours - 12;
  }

  return parseInt(hours);
};

JSONDateParser.prototype.minutes = function () {
  const minutes = this.dateString.substring(14, 16);

  return minutes;
};

JSONDateParser.prototype.meridian = function () {
  const hours = this.dateString.substring(11, 13);
  if (hours > 11) {
    return 'PM';
  } else {
    return 'AM';
  }
};

JSONDateParser.prototype.timezone = function () {
  const easternTimeZone = "America/New_York";
  const zoneFormat = "z";
  const zone = moment(this.dateString).tz(easternTimeZone).format(zoneFormat);

  return (zone);
}


export default JSONDateParser;
