import React, { useState } from 'react';
import classNames from 'classnames'

const SelectAllCheckbox = ({ checked, count, onClick, outerFilter}) => {

  const handleClick = () => {
    onClick(outerFilter);
  }

  return (
    <div 
      className={classNames({'modal-filter-checkbox': true, checked: checked})}
      onClick={handleClick}
    >
      Select All ({count})
    </div>
  );
};

export default SelectAllCheckbox;