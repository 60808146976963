const slideToggle = element => {
  if (element.classList.contains('active')) {
    element.style.height = '0px';

    element.addEventListener(
      'transitionend',
      function() {
        element.classList.remove('active');
      },
      {
        once: true,
      },
    );
  } else {
    element.classList.add('active');
    element.style.height = 'auto';

    const height = element.clientHeight + 'px';

    element.style.height = '0px';

    setTimeout(function() {
      element.style.height = height;
    }, 0);
  }
};

export default slideToggle;
