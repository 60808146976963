/* global moment  */
import React from 'react';
import {
  prop,
  find,
  compose,
  propEq,
  __,
  pathOr,
  without,
  map,
  equals,
} from 'ramda';
import moment from 'moment-timezone';

export const eventDisplayTime = event => {
  /* eslint-disable camelcase */
  const { start_date, event_duration } = event;
  const easternTimeZone = 'America/New_York';
  const zoneFormat = 'z';

  if (start_date) {
    const momentDate = moment(start_date).utcOffset(start_date);
    const zone = moment(start_date)
      .tz(easternTimeZone)
      .format(zoneFormat);
    const day = momentDate.format('ddd');
    const time = momentDate.format('h:mm a');

    let duration = parseFloat(event_duration);
    let hours = Math.floor(duration);
    let minutes = Math.round((duration - hours) * 60);

    duration = `${hours > 0 ? hours + ' hr' : ''} ${
      minutes > 0 ? minutes + ' min' : ''
    }`.trim();

    return `${day} at ${time} ${zone} for ${duration}`;
  }

  return '';
};

export const categoryUri = event => {
  /* eslint-disable camelcase */
  const { role, event_type } = event;

  if (role.includes('event_organizer') || role.includes('game_master')) {
    return '/event_types/WebHOST100px_1.png';
  }

  return `/event_types/Web${event_type.replace(/\s.*/, '')}100px_1.gif`;
};

export const gameSystemDisplay = event => {
  /* eslint-disable camelcase */
  const { game_system, rules_edition } = event;

  if (game_system.length > 0) {
    let gameSystemText = game_system;

    if (rules_edition.length > 0) {
      gameSystemText = `${gameSystemText}, ${rules_edition} Edition`;
    }

    return <div className="small">{gameSystemText}</div>;
  }

  return null;
};

export const ticketIconDisplay = event => {
  /* eslint-disable camelcase */
  const { e_ticketed, status } = event;

  if (equals(status, 'pending')) {
    return <i className="fa fa-shopping-cart fa-2x" title="Pending Ticket"></i>;
  }

  if (e_ticketed) {
    return <i className="fa fa-ticket-e fa-2x" title="Electronic Ticket"></i>;
  }
  return <i className="fa fa-ticket-horz fa-2x" title="Paper Ticket"></i>;
};

export const ticketsCount = event => {
  /* eslint-disable camelcase */
  const {
    role,
    friend_count = '',
    my_ticket_count = '',
    ticket_sold_count = '',
    max_players = '',
  } = event;

  let ticketCount1 = '';
  let ticketCount2 = '';

  if (role === 'attendee') {
    ticketCount1 = `Me: ${my_ticket_count}`;
    if (friend_count) {
      ticketCount2 = `Friends: ${friend_count}`;
    }
  }

  if (role.includes('event_organizer') || role.includes('game_master')) {
    ticketCount1 = `Sold: ${ticket_sold_count}`;
    ticketCount2 = `Max: ${max_players}`;
  }

  return (
    <div className="ticket-count">
      <div>{ticketCount1}</div>
      <div>{ticketCount2}</div>
    </div>
  );
};

export const filterMapping = [
  { key: 'event_type', display: 'Category', path: ['event_type', 'buckets'] },
  { key: 'game_system', display: 'Game', path: ['game_system', 'buckets'] },
  { key: 'day', display: 'Day', path: ['day', 'buckets'] },
  { key: 'start_hour', display: 'Time', path: ['start_hour', 'buckets'] },
  {
    key: 'group_sponsor',
    display: 'Host',
    path: ['printable_group_sponsor', 'buckets'],
  },
  { key: 'role', display: 'Role', path: ['role', 'buckets'] },
  { key: 'event_status', display: 'Status', path: ['event_status', 'buckets'] },
];

const roleDisplay = {
  attendee: 'Attendee',
  game_master: 'Game Master',
  event_organizer: 'Event Organizer',
};

const statusDisplay = {
  'AA-Active': 'Active',
  'CN-Canceled': 'Canceled',
  'NS-GM no show': 'No Show',
};

const daySorter = {
  Wednesday: 1,
  Thursday: 2,
  Friday: 3,
  Saturday: 4,
  Sunday: 5,
};

export const pluckMetadata = (filter, metadata) =>
  compose(
    pathOr([], __, metadata),
    prop('path'),
    find(propEq('key', filter)),
  )(filterMapping);

const toString = data => data.toString();

export const allValuesFromMetadata = (filter, metadata) =>
  compose(
    map(toString),
    without(''),
    map(prop('key')),
    pluckMetadata,
  )(filter, metadata);

export const filterToArray = filters => {
  const arrayFilters = [];

  Object.keys(filters).forEach(currentFilter => {
    if (Array.isArray(filters[currentFilter])) {
      filters[currentFilter].forEach(inner => {
        arrayFilters.push({ filter: currentFilter, name: inner });
      });
    } else {
      arrayFilters.push({
        filter: currentFilter,
        name: filters[currentFilter],
      });
    }
  });

  return arrayFilters;
};

export const displayFilter = filter =>
  compose(prop('display'), find(propEq('key', filter)))(filterMapping);

export const displayName = (filter, name) => {
  if (filter === 'start_hour') {
    return moment(name, 'HH:mm:ss').format('h:mm A');
  }

  if (filter === 'role') {
    return roleDisplay[name];
  }

  if (filter === 'event_status') {
    return statusDisplay[name];
  }

  return name;
};

export const sortDays = data => {
  return data.sort((a, b) => {
    return daySorter[a.key] - daySorter[b.key];
  });
};

export const sortData = (filter, data) => {
  if (filter === 'day') {
    return sortDays(data);
  }

  return data;
};

export const formatForDisplay = (filter, data) => {
  const pluckedData = pluckMetadata(filter, data);

  return sortData(filter, pluckedData);
};

export const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100000,
    bottom: 0,
    backgroundColor: 'rgba(10, 10, 10, 0.60)',
  },
  content: {
    border: 'solid #bca98b 1px',
    background: '#f4f1ec',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '7px',
    outline: 'none',
    padding: '0',
    overflow: 'hidden',
  },
};

export const popupHeaderStyle = {
  color: '#000',
  fontSize: '22px',
  textAlign: 'center',
  marginTop: '15px',
};
