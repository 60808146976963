import React from 'react';

const SearchFilterBadge = ({ group, name, onClick }) => {
  const handleCloseClick = (e) => {
    onClick(group, name);
    e.preventDefault();
  }

  const printGroup = (group, name) => {
    if (group === "ids"){
      return "";
    } else {
      return name.replace('%26', '&').replace('%23', '#');
    }
  }

  let capitalizedGroupName = group[0].toUpperCase() + group.slice(1) + ':';
  if (capitalizedGroupName === 'Ids:'){
    capitalizedGroupName = 'Selected';
  }

  return (
    <a onClick={handleCloseClick}>
      <div className="finder-filter">
        {capitalizedGroupName} {printGroup(group, name)}
        <div className="right">
          <i className="fa fa-times-circle"></i>
        </div>
      </div>
    </a>
  );
}

export default SearchFilterBadge;
