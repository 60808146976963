import React from 'react';

const divStyle = {
  height: '100px',
  padding: '10px',
  fontSize: '1.5em',
  textAlign: 'center',
  verticalAlign: 'middle',
  lineHeight: '30px'
}
const Error = (props) => (
  <div
    style={divStyle}>
    {props.message}
  </div>
);

export default Error;