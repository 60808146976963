import React from 'react';
import PropTypes from 'prop-types';

class GoogleMapsAutocomplete extends React.Component {
  constructor(props) {
    super(props);

    this.map = null;
    this.autocomplete = null;
    this.infowindow = null;
    this.infowindowContent = null;
    this.marker = null;
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
    this.handlePlaceClicked = this.handlePlaceClicked.bind(this);
  }

  componentDidMount() {
    this.map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: -33.8688, lng: 151.2195 },
      zoom: 13,
    });

    const input = document.getElementById('pac-input');

    this.autocomplete = new google.maps.places.Autocomplete(input);
    this.autocomplete.bindTo('bounds', this.map);

    // Specify just the place data fields that you need.
    this.autocomplete.setFields(['place_id', 'geometry', 'name']);

    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

    this.infowindow = new google.maps.InfoWindow();
    this.infowindowContent = document.getElementById('infowindow-content');
    this.infowindow.setContent(this.infowindowContent);

    this.marker = new google.maps.Marker({ map: this.map });

    this.marker.addListener('click', this.handlePlaceClicked);

    this.autocomplete.addListener('place_changed', this.handlePlaceChanged);

    // Prevent Enter key in Autocomplete field from triggering form submit
    google.maps.event.addDomListener(input, 'keydown', function(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    });
  }

  handlePlaceClicked() {
    this.infowindow.open(this.map, this.marker);
  }

  handlePlaceChanged() {
    this.infowindow.close();

    const place = this.autocomplete.getPlace();

    if (!place.geometry) {
      return;
    }

    if (place.geometry.viewport) {
      this.map.fitBounds(place.geometry.viewport);
    } else {
      this.map.setCenter(place.geometry.location);
      this.map.setZoom(17);
    }

    // Set the position of the marker using the place ID and location.
    this.marker.setPlace({
      placeId: place.place_id,
      location: place.geometry.location,
    });

    this.marker.setVisible(true);

    this.infowindowContent.children['place-name'].textContent = place.name;
    this.infowindow.open(this.map, this.marker);

    const { onPlaceChange } = this.props;
    const placeId = place.place_id;
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();

    onPlaceChange(placeId, lat, lng);
  }

  render() {
    return (
      <div>
        <div>
          <div style={{ display: 'none' }}>
            <input
              id="pac-input"
              className="controls"
              type="text"
              placeholder="Enter a location"
            />
          </div>
          <div id="map" style={{ height: '600px' }} />
          <div id="infowindow-content">
            <span id="place-name" className="title" />
          </div>
        </div>
      </div>
    );
  }
}

GoogleMapsAutocomplete.propTypes = {
  onPlaceChange: PropTypes.func.isRequired,
};

export default GoogleMapsAutocomplete;
