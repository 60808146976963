import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import PropTypes from 'prop-types';
import axios from 'axios';
import { replace } from 'ramda';
import FinderResult from './FinderResult';
import Loading from './Loading';
import Error from './Error';
import { equals } from 'ramda';
import qs from 'qs';

class FinderResults extends Component {
  static displayName = 'FinderResults';

  constructor(props) {
    super(props);

    this.state = {
      totalEvents: 0,
      conventionId: this.props.conventionId,
      events: [],
      hasMoreEvents: true,
      loading: false,
      error: null,
      nextHref: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (!equals(this.props, prevProps)) {
      this.setState({
        error: null,
        conventionId: this.props.conventionId,
        events: [],
        hasMoreEvents: true,
        nextHref: null,
        loading: false,
      });
    }
  }

  resultsMessage() {
    let locationObject = qs.parse(window.location.search.substring(1));
    let opts = locationObject.opt
    if (!opts) {
      return ' events';
    } else if (opts.includes('ta') && opts.includes('hh')) {
      return ' events with tickets remaining that haven’t started yet';
    } else if (opts.includes('ta')) {
      return ' events with tickets remaining';
    } else if (opts.includes('hh')) {
      return ' events that haven’t started yet';
    } else {
      return '';
    }
  }

  loadItems(page) {
    const baseUri = '/api/event_search?';
    const formattedUrl = replace(/game\[\]=\+/g, 'game[]=%2B')(this.props.searchQuery)

    let url = baseUri + formattedUrl;

    if (this.state.nextHref) {
      url = this.state.nextHref;
    }

    if (this.state.hasMoreEvents && !this.state.loading) {
      this.setState({ loading: true, scrollPosition: window.pageYOffset });
      axios.get(url).then(res => {
        if (res.data.convention_id != this.state.conventionId) {
          // user's current convention changed in a different tab
          // reload page for new styles
          window.location.reload(true);
        } else if (res.data.records.length > 0) {
          this.setState({
            totalEvents: res.data.total_count,
            conventionId: res.data.convention_id,
            events: [...this.state.events, ...res.data.records],
            nextHref: res.data.next_path,
            hasMoreEvents: res.data.has_more,
            loading: false,
          });
        } else if (res.data.error) {
          this.setState({
            error: res.data.error,
            totalEvents: 0,
            hasMoreEvents: false,
            loading: false,
          });
        } else {
          this.setState({
            totalEvents: 0,
            conventionId: res.data.convention_id,
            hasMoreEvents: false,
          });
        }
      });
      window.scroll(0, this.state.scrollPosition);
    }
  }

  render() {
    var items = [];
    this.state.events.map((event, i) => {
      items.push(<FinderResult key={i} event={event} />);
    });

    return (
      <div>
        <div
          style={{
            textAlign: 'center',
            width: '100%',
            verticalAlign: 'middle',
            display: 'relative',
          }}
        >
          <p style={{ maxWidth: 'none' }}>
            {'Found '}
            {this.state.totalEvents}
            {this.resultsMessage()}
          </p>
        </div>
        <div className="result-header">
          <div className="row">
            <div className="large-1 small-1 columns icon">&nbsp;</div>
            <div className="large-5 small-5 columns title">Title</div>
            <div className="large-4 small-3 columns time">
              Start Time
              <i className="fa fa-sort-asc" style={{ lineHeight: 0 }}></i>
            </div>
            <div className="large-1 small-2 columns duration">Duration</div>
            <div className="large-1 small-1 columns cost-ticketsleft">
              <div className="text-right cost" id="head">
                Cost
              </div>
            </div>
          </div>
        </div>
        <div className="results_container">
          <div className="results">
            <div className="event-info">
              <div className="event-info-data">
                <div className="result-set-pages">
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={this.loadItems.bind(this)}
                    loader={<Loading key={0} />}
                    threshold={150}
                    hasMore={this.state.hasMoreEvents}
                  >
                    {this.state.error ? (
                      <Error message={this.state.error} />
                    ) : (
                      items
                    )}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FinderResults;
