import React, { useEffect } from 'react';
import classNames from 'classnames';

const Notification = ({
  nId,
  level,
  icon,
  text,
  link,
  timeout,
  htmlSafe,
  removeNotification,
}) => {
  let _timeout = null;
  const iconClass = classNames(`fa ${icon}`);
  const alertClass = classNames(`notice ${level}`);
  const htmlSafeText =
    htmlSafe === true ? (
      <div dangerouslySetInnerHTML={{ __html: text }}></div>
    ) : (
      <div>{text}</div>
    );

  const noticeTextElement = link ? (
    <div className="notice-text">
      <a href={link} target="_blank" onClick={removeOnClick}>
        {htmlSafeText}
      </a>
    </div>
  ) : (
    <div className="notice-text">{htmlSafeText}</div>
  );

  useEffect(() => {
    clearTimeout(_timeout);
    if (timeout && timeout > 0) {
      _timeout = setTimeout(() => {
        removeNotification(nId);
      }, timeout);
    }
  });

  function removeOnClick() {
    removeNotification(nId);
  }

  return (
    <div className={alertClass}>
      <button className="notice-close" onClick={removeOnClick}>
        <i className="fa fa-times-circle"></i>
      </button>

      <div className="notice-icon">
        <i className={iconClass}></i>
      </div>

      {noticeTextElement}
    </div>
  );
};

export default Notification;
