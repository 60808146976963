import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import SortLink from './SortLink';
import PaymentMethodTableRow from './PaymentMethodTableRow';

class PaymentMethodsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentMethods: [],
      sortQuery: {
        card_type_and_digits: 'asc',
        card_expiry: 'asc',
        state: 'asc',
      },
      currentSortKey: 'card_type_and_digits',
      showStateColumn: props.showStateColumn,
      showDetailsLink: props.showDetailsLink,
    };

    this.handleSort = this.handleSort.bind(this);
    this.handleSetDefault = this.handleSetDefault.bind(this);
    this.handleDeactivate = this.handleDeactivate.bind(this);
  }

  handleDeactivate(paymentMethod) {
    axios
      .post(
        `/api/v1/contacts/${this.props.contactId}/payment_methods/${paymentMethod.id}/deactivate`,
        { state: 'inactive' },
      )
      .then(() => {
        this.handleSort();
      })
      .catch(error => {
        // console.log(error);
      });
  }

  handleSort(sortKey = 'card_type_and_digits', sortValue = 'asc') {
    const sortQueryString = `?q[s]=${sortKey}+${sortValue}`;
    const path = `/api/v1/contacts/${this.props.contactId}/payment_methods${sortQueryString}`;

    const { sortQuery } = this.state;
    sortQuery[sortKey] = sortValue === 'asc' ? 'desc' : 'asc';

    axios
      .get(path)
      .then(response =>
        this.setState({
          paymentMethods: response.data.data.payment_methods,
          sortQuery,
          currentSortKey: sortKey,
        }),
      )
      .catch(error => {
        // console.log(error);
      });
  }

  handleSetDefault(defaultPaymentMethod) {
    axios
      .put(
        `/api/v1/contacts/${this.props.contactId}/payment_methods/${defaultPaymentMethod.id}`,
        { default_method: true },
      )
      .then(() => {
        this.handleSort();
      })
      .catch(error => {
        // console.log(error);
      });
  }

  componentDidMount() {
    this.handleSort();
  }

  render() {
    return (
      <table className="records">
        <thead>
          <tr>
            <th></th>
            <th>
              <SortLink
                colName="Credit & Debit Cards"
                sortKey="card_type_and_digits"
                sortValue={this.state.sortQuery.card_type_and_digits}
                active={this.state.currentSortKey == 'card_type_and_digits'}
                handleSort={this.handleSort}
              />
            </th>
            <th>
              <SortLink
                colName="Cardholder"
                sortKey="cardholder_name"
                sortValue={this.state.sortQuery.cardholder_name}
                active={this.state.currentSortKey == 'cardholder_name'}
                handleSort={this.handleSort}
              />
            </th>
            <th>
              <SortLink
                colName="Expiration Date"
                sortKey="card_expiry"
                sortValue={this.state.sortQuery.card_expiry}
                active={this.state.currentSortKey == 'card_expiry'}
                handleSort={this.handleSort}
              />
            </th>
            {this.state.showStateColumn && (
              <th>
                <SortLink
                  colName="State"
                  sortKey="state"
                  sortValue={this.state.sortQuery.state}
                  active={this.state.currentSortKey == 'state'}
                  handleSort={this.handleSort}
                />
              </th>
            )}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {this.state.paymentMethods.map(paymentMethod => {
            return (
              <PaymentMethodTableRow
                key={paymentMethod.id}
                contactId={this.props.contactId}
                paymentMethod={paymentMethod}
                handleSetDefault={this.handleSetDefault}
                handleDeactivate={this.handleDeactivate}
                showStateColumn={this.state.showStateColumn}
                showDetailsLink={this.state.showDetailsLink}
              />
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default PaymentMethodsTable;
