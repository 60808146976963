import slideToggle from './slideToggle';
import MicroModal from 'micromodal';

document.addEventListener('DOMContentLoaded', () => {
  const avatarDropdown = document.querySelector('.button.avatar_dropdown');

  if (avatarDropdown) {
    avatarDropdown.addEventListener('click', event => {
      event.preventDefault();

      const changeAvatarPanel = document.querySelector(
        '#change_avatar_panel.slide-toggle',
      );
      slideToggle(changeAvatarPanel);
    });
  }

  // Triggered on modal click for featured images.
  // Sets the pass through variable(s)
  const microModalTriggers = document.querySelectorAll(
    'a[data-micromodal-trigger]',
  );

  microModalTriggers.forEach(element => {
    element.addEventListener('click', event => {
      const dataNum = element.dataset.num;

      const picture = document.querySelector('.data-picture');
      if(picture !== null) {
        picture.textContent = dataNum;
      }
    });
  });

  // Triggers the toggling of the featured images. Clicking
  // on a featured image on the slider will copy over the image the top
  // panel and allow the user to see the image selected. Clicking
  // update will save the image that is selected
  // TODO: this needs to be DRY'ed up. It exists due to time constraints.
  const largeImages = document.querySelectorAll('a[data-large]');

  largeImages.forEach(element => {
    element.addEventListener('click', event => {
      const featuredPictureNum = document.querySelector('.data-picture').textContent;

      const largeImage = element.dataset.large;

      const picture = document.querySelector('#picture-' + featuredPictureNum)
      const noPicture = document.querySelector('#no-picture-' + featuredPictureNum)

      if(picture){
        picture.src = largeImage;
      }

      if(noPicture){
        noPicture.src = largeImage;
      }

      const id = element.dataset.id;

      document.querySelector('#featured_picture_featured_picture_' + featuredPictureNum + '_id').value = id

      MicroModal.close('featured-pictures');

      return false
    });
  });
});
