import React from 'react';
import PropTypes from 'prop-types';

class InfoTooltip extends React.Component {
 renderMessageWithHTML(message) {
    return <p dangerouslySetInnerHTML={{ __html: message }} />;
  }

  renderMessageWithoutHTML(message) {
    return <p>{message}</p>;
  }

  render() {
    const { tooltipKey } = this.props;
    const { message } = this.props;
    const { allowHtml } = this.props;

    const dropdownId = `drop${tooltipKey}`;

    return (
      <span className="info">
        <label className="tooltip">
          <i className="fa fa-info-circle fa-lg" />
          <input
            id={dropdownId}
            className="f-dropdown content"
            type="checkbox"
          />
          <div className="tooltiptext">
            {allowHtml
              ? this.renderMessageWithHTML(message)
              : this.renderMessageWithoutHTML(message)}
          </div>
        </label>
      </span>
    );
  }
}

InfoTooltip.propTypes = {
  tooltipKey: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  allowHtml: PropTypes.bool,
};

InfoTooltip.defaultProps = {
  allowHtml: false,
};

export default InfoTooltip;
