// Schedule API call
export const SCHEDULE_ENDPOINT = '/api/v2/schedule';
export const SCHEDULE_META_ENDPOINT = '/api/v2/schedule/meta_data';
export const SCHEDULE_INDEX_STATUS_ENDPOINT =
  '/api/v2/schedule/schedule_index_status';

export const DELAYED_JOBS_ENDPOINT = '/api/v2/delayed_jobs';
export const USPS_SHIPMENTS_ENDPOINT = '/api/v2/usps_shipments';

export const CONVERSATIONS_ENDPOINT_PAGED = page =>
  `/api/v1/conversations?page=${page}&newest=true`;
export const CONVERSATIONS_ENDPOINT = changesSince =>
  `/api/v1/conversations?changes_since=${changesSince}&newest=true`;
export const MESSAGES_ENDPOINT = (conversationId, page) =>
  `/api/v1/conversations/${conversationId}/messages?page=${page}&descending=true`;
