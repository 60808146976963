import React from 'react';
import classNames from 'classnames'

const InnerFilterCheckbox = ({ filter, outerFilter, handleClick }) => {
  return (
    <div 
      className={classNames({'modal-filter-checkbox': true, checked: filter.checked})}
      onClick={() => handleClick(outerFilter, filter)}
    >
      {filter.name}
    </div>
  );
};

export default InnerFilterCheckbox;
