import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FinderResult = ({ exhibitor }) => {
  const onLinkClick = () => {
    if (typeof gtag === 'function') {
      gtag('event', 'exhibitor_search', { clicked_exhibitor: exhibitor._source.name });
    }
  };


  const validateAndFormatWebsiteURL = (website) => {
    if (!website) return null; // If no website is provided, return null

    // Check if the website starts with http:// or https://
    if (website.startsWith('http://') || website.startsWith('https://')) {
      return website;
    }

    // Attempt to correct common formatting errors
    // Example: "https//www.example.com" -> "https://www.example.com"
    if (website.startsWith('https//')) {
      return `https://${website.slice(7)}`;
    }
    if (website.startsWith('http//')) {
      return `http://${website.slice(6)}`;
    }

    // If the website starts with "www.", assume "http://" should be prepended
    if (website.startsWith('www.')) {
      return `http://${website}`;
    }

    // If the URL is not correctable or doesn't start with "www.", discard it
    return null;
  };

  const iconClass = exhibitor._source.search_category === 'Exhibitors' ? 'fa fa-2x fa-exhibit-booth' : `fa fa-2x fa-${exhibitor._source.map_feature.properties.icon}`;
  const eventStatusClass = classNames('row result-data');
  const website = exhibitor._source.map_feature.properties.names
    ? exhibitor._source.map_feature.properties.names
        .filter(name => name.name === exhibitor._source.name && name.website)
        .map(name => name.website)
        .find(url => url)
    : null;
  const websiteURL = validateAndFormatWebsiteURL(website);

  // Function to format location string based on search_category
  const getLocationString = () => {
    const { search_category, booth_num_search } = exhibitor._source;
    let locationPrefix = '';
    let locationDetail = '';
  
    switch (search_category) {
      case 'Exhibitors':
        locationPrefix = '<span class="hide-for-small">Exhibit Hall :&nbsp;</span>';
        locationDetail = `Booth ${booth_num_search}`;
        break;
      case 'Artists':
        locationPrefix = '<span class="hide-for-small">Exhibit Hall :&nbsp;</span>';
        locationDetail = `Art Show : Table ${booth_num_search}`;
        break;
      case 'Authors':
        locationPrefix = '<span class="hide-for-small">Exhibit Hall :&nbsp;</span>';
        locationDetail = `Authors Avenue : Table ${booth_num_search}`;
        break;
      default:
        return booth_num_search;
    }
  
    return { __html: `${locationPrefix} ${locationDetail}` };
  };

  return (
    <div className='finder' key={exhibitor._id} id={`exhibitor-${exhibitor._id}`}>
      <div className={eventStatusClass}>
        <div className="large-1 small-1 columns text-center icon mobile-icon-class flex-center">
          <div className="i">
            <i className={iconClass} style={{ color: 'black' }}></i>
          </div>
        </div>
        <div className="large-5 small-5 columns name name-flex">
          <span>{exhibitor._source.name}</span>
          {websiteURL && (
            <a className="link-pointer external-link icon" href={websiteURL} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '6px' }} title="Visit Exhibitor Website">
              <i className="fa fa-external-link fa-1-5x"></i>
            </a>
          )}
        </div>
        <div className="large-6 small-6 columns exhibit-marker">
          <a className="link-pointer icon" id="exhibitor-10436" target="_blank" onClick={onLinkClick} href={exhibitor._source.map_location}>
            <i className="fa fa-map-marker fa-1-5x" style={{ marginRight: '5px' }}></i>
          </a>
          <a className="link-pointer" id="exhibitor-10436" target="_blank" onClick={onLinkClick} href={exhibitor._source.map_location} dangerouslySetInnerHTML={getLocationString()}></a>
        </div>
      </div>
    </div>
  );
};

FinderResult.propTypes = {
  exhibitor: PropTypes.object.isRequired,
};

export default FinderResult;