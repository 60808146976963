import React, { Component } from 'react';

class SortLink extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const arrow = this.props.sortValue == 'asc' ? ' ▼' : ' ▲';
    const linkText = this.props.colName + (this.props.active ? arrow : '');

    return (
      <a
        onClick={() =>
          this.props.handleSort(this.props.sortKey, this.props.sortValue)
        }
        className="sort_link"
      >
        {linkText}
      </a>
    );
  }
}

export default SortLink;
