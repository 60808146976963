// Entry point for the build script in your package.json

import './custom'
import './react'
import './stimulus/index.js'
import './channels'
import axios from 'axios'

// setups CSRF Token for API calls
const setupCSRFToken = () => {
  const csrfToken = document.querySelector("meta[name=csrf-token]").content;
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
}
window.addEventListener('DOMContentLoaded', setupCSRFToken);