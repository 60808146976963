import { Controller } from 'stimulus';
import Sortable from "sortablejs";

export default class SortableController extends Controller {
  static values = {
    rebuildUrl: String
  }

  connect() {
    this.initializeSortable(this.element)
  }

  initializeSortable(element) {
    Sortable.create(element, {
      animation: 250,
      handle: ".handle",
      group: "nested",
      fallbackOnBody: true,
      swapThreshold: 0.15,
      emptyInsertThreshold: 5,
      dragClass: "sortable-drag",
      ghostClass: "sortable-ghost",
      onEnd: this.onEnd.bind(this),
      dragoverBubble: true,
      forceFallback: false,
      fallbackClass: "sortable-fallback",
      fallbackOnBody: true,
      fallbackTolerance: 5,
      onMove: (evt, originalEvent) => {
        if (evt.related.classList.contains('sortable-item')) {
          return -1; // Insert before
        }
        return true;
      }
    })

    element.querySelectorAll(".nested-list").forEach(nestedList => {
      this.initializeSortable(nestedList)
    })
  }

  onEnd(event) {
    const itemEl = event.item;
    const itemId = itemEl.dataset.nodeId;
    const newParentEl = itemEl.parentNode.closest('.sortable-item') || itemEl.parentNode;
    const parentId = newParentEl.dataset.nodeId || null;
    const newIndex = Array.from(itemEl.parentNode.children).indexOf(itemEl);

    fetch(this.rebuildUrlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        id: itemId,
        position: newIndex,
        parent_id: parentId
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
      } else {
        console.error('Failed to update order');
      }
    })
    .catch(error => {
      console.error('Error updating order:', error);
    });
  }
}