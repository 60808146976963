import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/scale.css';

// Uncomment as needed
// import 'react-s-alert/dist/s-alert-css-effects/slide.css';
// import 'react-s-alert/dist/s-alert-css-effects/bouncyflip.css';
// import 'react-s-alert/dist/s-alert-css-effects/flip.css';
// import 'react-s-alert/dist/s-alert-css-effects/genie.css';
// import 'react-s-alert/dist/s-alert-css-effects/jelly.css';
// import 'react-s-alert/dist/s-alert-css-effects/stackslide.css';

const defaults = {
  position: 'top-right',
  effect: 'scale',
  timeout: 3500,
  offset: 45,
};

export { Alert };

export const noticeMsg = (message, options = {}) => {
  options['customFields'] = { flashMessageType : 'notice' }
  Alert.success(message, Object.assign(defaults, options))
};

export const alertMsg = (message, options = {html: true}) => {
  options['customFields'] = { flashMessageType : 'alert' }
  Alert.info(message, Object.assign(defaults, options));
};

export const errorMsg = (message, options = {}) => {
  options['customFields'] = { flashMessageType : 'error' }
  Alert.error(message, Object.assign(defaults, options));
};
