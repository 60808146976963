import React, { useEffect, useCallback, useRef } from 'react';

/*
  Component to handle external barcode / QR code scanners that send input as keyboard events.
  props:
    onScan: function to call when a valid scan is detected
    onFocus: function to call when the window gains focus
    onBlur: function to call when the window loses focus
    pattern: regex pattern to match against the scanned code
    timeout: time in ms to wait after the last keypress before checking and clearing the code
*/
const Scanner = ({
  onScan = (code) => {},
  onFocus = () => {},
  onBlur = () => {},
  pattern = '.+',
  timeout = 500,
}) => {
  const code = useRef('');
  const timer = useRef(null);

  const handleKeyPress = useCallback((e) => {
    clearTimeout(timer.current);
    code.current += e.key;
    if (code.current.match(new RegExp(pattern))) {
      onScan(code.current);
      code.current = '';
    } else {
      timer.current = setTimeout(() => {
        code.current = '';
      }, timeout);
    }
  }, [onScan, pattern, timeout]);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress);
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('keypress', handleKeyPress);
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    }
  }, [handleKeyPress, onFocus, onBlur]);

  return <div id='Scanner'></div>;
}

export default Scanner;