import React from 'react';
import PropTypes from 'prop-types';
import 'es6-string-polyfills';
import SearchQuery from './SearchQuery';
import SearchFilter from './SearchFilter';

const SearchOptions = ({ search, onQueryChange, filters, setSearchFilter }) => {
  return (
    <div className="event-finder-options">
      <div className="ribbon-top">
        <div className="ribbon-row" style={{ overflow: 'hidden' }}>
          <div className="event-finder-options">
            <SearchFilter
              filters={filters}
              search={search}
              setSearchFilter={setSearchFilter}
            />

            <SearchQuery
              onChange={onQueryChange}
              defaultQuery={search || ''}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

SearchOptions.propTypes = {
  search: PropTypes.string,
  onQueryChange: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  setSearchFilter: PropTypes.func.isRequired,
};

export default SearchOptions;
