import {
  any,
  complement,
  compose,
  either,
  equals,
  filter,
  find,
  head,
  includes,
  isEmpty,
  isNil,
  omit,
  map,
  not,
  path,
  pathEq,
  prop,
  propOr,
  split,
  symmetricDifference,
  uniq,
  uniqBy,
  unless,
} from 'ramda';

const filterDupeElements = uniqBy(prop('_id'));
const getIds = compose(uniq, map(path(['_source', 'id'])));
const notEquals = compose(not, equals);
const notEmpty = complement(isEmpty);
const hasNewElements = compose(not, isEmpty, symmetricDifference);
const isEmptyValue = either(isNil, isEmpty);
const isValueSet = complement(isEmptyValue);
const anySet = any(notEmpty);
const omitSearch = omit(['search']);
const toFloat = val => parseFloat(val);
const categoryIncluded = includes('category:');
const removeCategory = omit(['category']);
const categoryMissing = value => not(includes('category:', value));
const filterParamsEmptyStrings = filter(complement(isEmpty));
const idPath = pathEq(['_source', 'id']);
const findById = id => find(idPath(id));
const getDataset = propOr(null, 'dataset');
const getCategory = compose(
  path(['searchable_group', 'searchable_group', 'buckets']),
);

const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num)}...`;
};

const notIncludedInArray = (id, array) => not(includes(id, array));

const getCategoryAndIcon = aggs => {
  const data = {};
  const categories = getCategory(aggs);
  const getIcon = compose(prop('key'), head);

  categories.forEach(element => {
    const { key, icon } = element;

    if (isEmptyValue(key)) {
      return;
    }

    data[key] = getIcon(icon.buckets);
  });

  return data;
};

const getSelect = compose(
  unless(isNil, parseInt),
  prop('select'),
  filterParamsEmptyStrings,
);

const splitLayerId = layer => parseInt(split('-')(layer)[1], 10);

export {
  anySet,
  categoryIncluded,
  categoryMissing,
  filterDupeElements,
  filterParamsEmptyStrings,
  findById,
  getCategoryAndIcon,
  getIds,
  getDataset,
  hasNewElements,
  idPath,
  isEmptyValue,
  isValueSet,
  notEmpty,
  notEquals,
  notIncludedInArray,
  omitSearch,
  removeCategory,
  getSelect,
  splitLayerId,
  truncateString,
  toFloat,
};
