/* global window  */
import qs from 'qs';
import { compose, propEq, not, split } from 'ramda';

import { INITIAL_Z, INITIAL_C, CENTER_XY } from '../constants';
import {
  categoryIncluded,
  getSelect,
  filterParamsEmptyStrings,
  omitSearch,
} from './utils';

const urlParamsToObject = () => {
  return qs.parse(window.location.search.substring(1));
};

const objectToUrl = object => {
  return qs
    .stringify(object, {
      encodeValuesOnly: true,
      indices: false,
      arrayFormat: 'brackets',
    })
    .replace(/ /g, '+');
};

const isSearchOpen = () => compose(not, propEq('sb', '0'))(urlParamsToObject());
const isCoordsOpen = () => compose(propEq('cd', 'true'))(urlParamsToObject());

const urlParamsToQuery = object => {
  return qs
    .stringify(object, {
      encode: true,
      indices: false,
      arrayFormat: 'brackets',
    })
    .replace(/ /g, '+');
};

const getSelectId = () => getSelect(urlParamsToObject());

const handleCategory = object => {
  let cleanObject = object;
  const { search } = cleanObject;

  if (search && categoryIncluded(search)) {
    cleanObject = omitSearch(cleanObject);
    const [key, value] = split(':', search);
    cleanObject[key] = value;
  }

  return cleanObject;
};

const getCategoryAndSearchFromTerm = term => {
  let search;
  let category;

  if (term && categoryIncluded(term)) {
    const [, value] = split(':', term);

    category = value;

    return { category, search };
  }

  search = term;

  return { category, search };
};

const urlParamsToCleanQuery = object => {
  return qs
    .stringify(handleCategory(object), {
      encodeValuesOnly: true,
      indices: false,
      arrayFormat: 'brackets',
    })
    .replace(/ /g, '+');
};

const replaceUrlState = query => {
  window.history.replaceState({}, '', query);
};

const initialMapLocation = () => {
  const params = filterParamsEmptyStrings(urlParamsToObject());
  const {
    x = CENTER_XY,
    y = CENTER_XY,
    z = INITIAL_Z,
    c = INITIAL_C,
    search,
    category,
    select,
    preview,
    sb,
    cd,
  } = params;

  const urlParams = { x, y, z, c, search, category, select, preview, sb, cd };

  return urlParams;
};

const updateUrlWithMapLocation = mapLocation => {
  const query = urlParamsToCleanQuery(filterParamsEmptyStrings(mapLocation));

  if (query === '') {
    replaceUrlState(window.location.pathname);
  } else {
    replaceUrlState(`${window.location.pathname}?${query}`);
  }
};

export {
  objectToUrl,
  isCoordsOpen,
  isSearchOpen,
  getSelectId,
  urlParamsToObject,
  urlParamsToQuery,
  urlParamsToCleanQuery,
  replaceUrlState,
  initialMapLocation,
  updateUrlWithMapLocation,
  handleCategory,
  getCategoryAndSearchFromTerm,
};
