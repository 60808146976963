import React, { useState, useEffect } from 'react';
import Scanner from '../scanner/Scanner';
import BadgeScan from './BadgeScan';
import autoload from '../../helpers/autoload';
import axios from 'axios';

const SCANS_ENDPOINT      = id => `/api/v2/attendee_checkpoints/${id}/scans`;
const SCAN_IN_ENDPOINT    = id => `/api/v2/attendee_checkpoints/${id}/scan_in`;
const SCAN_OUT_ENDPOINT   = id => `/api/v2/attendee_checkpoints/${id}/scan_out`;
const UNDO_SCAN_ENDPOINT  = id => `/api/v2/attendee_checkpoints/${id}/undo_scan`;

const AttendanceTracking = ({ checkpointId, mode }) => {
  const [currentMode, setCurrentMode] = useState(mode);
  const [badgeScans, setBadgeScans] = useState([]);
  const [focus, setFocus] = useState(true);

  // Fetch current scan history on page load
  useEffect(() => {
    const fetchBadgeScans = async () => {
      await axios
        .get(SCANS_ENDPOINT(checkpointId))
        .then(response => {
          setBadgeScans(response.data.scans)
        })
        .catch(error => {console.log(error)});
    };
    fetchBadgeScans();
  }, []);

  // Switch between in and out scanning, updates the url without
  // reloading the page or altering the browser history.
  const onClickToggleMode = event => {
    event.preventDefault();
    if (currentMode === 'in') {
      setCurrentMode('out');
      history.replaceState(
        null,
        '',
        location.origin + location.pathname + '?mode=out',
      );
    } else {
      setCurrentMode('in');
      history.replaceState(
        null,
        '',
        location.origin + location.pathname + '?mode=in',
      );
    }
  };

  // Hits API with badgeCode and adds the returned scan to the front of
  // badgeScans. If the scan is already in the list (it was scanned in and
  // then out), it is replaced by the new updated scan. Only the last 100
  // scans are kept on the page at a time.
  const handleBadgeScan = async badgeCode => {
    const endpoint =
      currentMode === 'in'
        ? SCAN_IN_ENDPOINT(checkpointId)
        : SCAN_OUT_ENDPOINT(checkpointId);
    await axios
      .post(endpoint, { badge_code: badgeCode })
      .then(response => {
        setBadgeScans(currentScans => {
          const newScans = [response.data, ...currentScans];
          return newScans.filter(
            (scan, index, self) =>
              index === self.findIndex(s => s.id === scan.id)
          ).slice(0, 100);
        });
      })
      .catch(error => {console.log(error)});
  };

  // Hits API with scanId to undo the scan. If the scan was an 'in' or 'out' scan,
  // it is removed if the undo is successful. If the scan was an 'inout' scan,
  // it is changed to an 'in' scan.
  const undoScan = async scanId => {
    await axios
      .post(UNDO_SCAN_ENDPOINT(checkpointId), { attendee_visit_id: scanId })
      .then(response => {
        let success = response.data.success;
        if (success) {
          let targetIndex = badgeScans.findIndex(scan => scan.id === scanId);
          if (badgeScans[targetIndex].status === 'inout') {
            badgeScans[targetIndex].status = 'in';
            badgeScans[targetIndex].out_scanned_at = null;
          } else {
            badgeScans.splice(targetIndex, 1);
          }
          setBadgeScans([...badgeScans]);
        }
      })
      .catch(error => {console.log(error)});
  };

  const currentModeButton = text => {
    return (
      <div className="button" onClick={onClickToggleMode}>
        {text}
      </div>
    );
  };

  return (
    <div className="attendance-tracking">
      {focus ?
        (<div className="attendance-scan-bar attendance-scan-prompt">
          <span>{(currentMode === 'in') ? 'Scan badges going IN' : 'Scan badges going OUT'} &nbsp;</span>
          <i className="fa fa-scanner fa-2x" title="Scan a badge"></i>
        </div>) 
        :
        (<div className="attendance-scan-bar-unfocus">
         <span>Select to enable scanning &nbsp;</span>
        </div>)
      }
      <div className="attendance-tracking-ribbon">
        {currentMode === 'in'
          ? currentModeButton("Switch to scanning OUT")
          : currentModeButton('Switch to scanning IN')}
      </div>
      <Scanner
        onScan={handleBadgeScan}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        pattern={'^[B,b]-[A-Za-z0-9]{12}$'}
        timeout={250}
      />
      <table className="attendance-tracking-table" style={{width: '100%', tableLayout: 'fixed', textAlign: 'center'}}>
        <thead>
          <tr>
            <th style={{width: '30%'}}></th>
            <th style={{width: '30%'}}>IN</th>
            <th style={{width: '30%'}}>OUT</th>
            <th style={{width: '10%'}}></th>
          </tr>
        </thead>
        <tbody>
          {badgeScans.map((badgeScan, index) => (
            <BadgeScan
              key={index}
              index={index}
              id={badgeScan.id}
              attendeeName={badgeScan.attendee_name}
              badgeCode={badgeScan.badge_code}
              inTime={badgeScan.in_scanned_at}
              outTime={badgeScan.out_scanned_at}
              status={badgeScan.status}
              onUndoScan={undoScan}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default autoload(AttendanceTracking, 'attendance-tracking');
