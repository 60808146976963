import React, { useState, useEffect } from 'react';
import moment from 'moment';

const BadgeScan = ({
  index,
  id,
  attendeeName,
  badgeCode,
  inTime,
  outTime,
  status,
  onUndoScan,
}) => {

  const [rowStyle, setRowStyle] = useState({});
  const [undoable, setUndoable] = useState(false);

  useEffect(() => {
    let compareTime;
    if (status === 'in') {
      setRowStyle({ background: '#6DB76D', border: '1px solid white'});
      compareTime = inTime;
    } else if (status === 'out') {
      setRowStyle({ background: '#FFDC67', border: '1px solid white'});
      compareTime = outTime;
    } else {
      setRowStyle({ border: '1px solid white'});
      compareTime = outTime;
    }

    // Badge scans are undoable on the server for up to a minute after they are scanned
    // There is a ~2 second buffer on the timeout to give the API request 
    // time to process. 
    let difference = moment().diff(moment(compareTime), 'seconds');
    setUndoable(false);
    if (difference < 59) {
      let timeout = setTimeout(() => {
        setUndoable(false);
      }, (59 - difference) * 1000);

      setUndoable(true);
      return () => clearTimeout(timeout);
    }
  }, [index, id, status, inTime, outTime]);

  const undoButton = (id) => {
    const onClickUndo = () => {
      if (confirm('Are you sure you want to undo this scan?')) {
        setUndoable(false);
        onUndoScan(id);
      };
    };
    return (
      <div className="btn center attendance-tooltip" onClick={onClickUndo}>
        <i className="tooltip fa fa-rotate-left fa-2x" style={{margin: 'auto'}} title="Undo Scan"></i>
      </div>
    )
  };

  return (
    <tr style={rowStyle}>
      <td style={{paddingTop: '8px', paddingBottom: '8px'}}>{badgeCode}</td>
      <td>{inTime ? moment(inTime).format('h:mm A') : 'UNKNOWN'}</td>
      <td>{outTime ? moment(outTime).format('h:mm A') : ''}</td>
      <td>{undoable ? undoButton(id) : ''}</td>
    </tr>
  );
};

export default BadgeScan;
