document.addEventListener('DOMContentLoaded', () => {
  const existingPaymentMethodRadioButtons = document.querySelectorAll("input.existing_payment_method_radio");
  const newPaymentMethodRadioButton       = document.getElementById("new_payment_method_radio");
  const newPaymentMethodForm              = document.getElementById("exhibitor-cc-form-attributes");

  if (newPaymentMethodRadioButton === null) {
    return;
  }

  const form = newPaymentMethodRadioButton.closest('form');

  if (newPaymentMethodForm === null) {
    return;
  }

  existingPaymentMethodRadioButtons.forEach((radioButton) => {
    radioButton.addEventListener("change", (e) => {
      if (e.target.checked) {
        newPaymentMethodForm.classList.add("hide");

        form.querySelectorAll("input[data-parsley-required=true]")
          .forEach((field) => {
            field.setAttribute("data-parsley-required", false);
          });

        var parsleyForm = new Parsley(form);
        parsleyForm.reset();
      };

      var parsleyForm = new Parsley(form);
      parsleyForm.reset();
    });
  });

  newPaymentMethodRadioButton.addEventListener("change", (e) => {
    if (e.target.checked) {
      newPaymentMethodForm.classList.remove("hide");

      form.querySelectorAll("input[data-parsley-required=false]")
        .forEach((field) => {
          field.setAttribute("data-parsley-required", false);
        });

      var parsleyForm = new Parsley(form);
      parsleyForm.reset();
    } else {
      newPaymentMethodForm.classList.add("hide");
    };
  });
});
