import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const SearchQuery = ({ onChange, defaultQuery }) => {
  const [searchText, setSearchText] = useState(defaultQuery || '');

  // Update search text when defaultQuery changes
  useEffect(() => {
    setSearchText(defaultQuery || '');
  }, [defaultQuery]);

  const handleInputChange = event => {
    const newValue = event.target.value;
    onChange(newValue);
    setSearchText(newValue);
  };

  const handleOnSubmit = event => {
    event.preventDefault();
    onChange(searchText);
  };

  return (
    <div className="search-area">
      <form onSubmit={handleOnSubmit} role="search" className="form_menu">
        <input
          type="text"
          name="search"
          id="search"
          value={searchText}
          placeholder="Search exhibitors by name or booth number"
          className="event-finder-search"
          onChange={handleInputChange}
        />
      </form>
    </div>
  );
};

SearchQuery.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultQuery: PropTypes.string,
};

export default SearchQuery;
