import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda';
import classNames from 'classnames'

class SearchFilterSelector extends Component {
  constructor() {
    super();
  }

  isGroupChecked(key) {
    if (this.props.searchFilter[key].length > 0)
      return true
    else {
      return false
    }
  }

  render() {
    const customStyles = {
      color: '#000',
      fontSize: "22px",
      textAlign: 'center',
      marginTop: '15px'
    }

    return (
      <div>
        <div className='event-finder-modal-title'>
          <h2 style={customStyles}>Event Filters</h2>
        </div>

        <div onClick={() => this.props.updateModalContent('event_type', 'category')}
          className={classNames({ 'modal-filter-checkbox': true, 'checked': this.isGroupChecked('category') })}>Category</div>
        <div onClick={() => this.props.updateModalContent('game_system', 'game')}
          className={classNames({ 'modal-filter-checkbox': true, 'checked': this.isGroupChecked('game') })}>Game</div>
        <div onClick={() => this.props.updateModalContent('start_day', 'day')}
          className={classNames({ 'modal-filter-checkbox': true, 'checked': this.isGroupChecked('day') })}>Day</div>
        <div onClick={() => this.props.updateModalContent('start_hour', 'hour')}
          className={classNames({ 'modal-filter-checkbox': true, 'checked': this.isGroupChecked('hour') })}>Time</div>
        <div onClick={() => this.props.updateModalContent('group_sponsor', 'host')}
          className={classNames({ 'modal-filter-checkbox': true, 'checked': this.isGroupChecked('host') })}>Host</div>

        <div className="event-filter-close">
          <button onClick={this.props.closeModal} className=" button">Cancel</button>
        </div>
      </div>
    );
  }
}

export default SearchFilterSelector
