import { Controller } from 'stimulus';
import axios from 'axios';

const MENU_ITEMS_ENDPOINT = (id) => `/api/v1/contacts/${id}/menus`;
var unloaded = true;

export default class DropdownMenuController extends Controller {
  static targets = ['currentUser', 'dropdownContent'];

  // Toggles visibility of the dropdown menu.
  // If no menu items loaded yet, fetches them from api.
  //
  toggleDropdownMenu(event) {
    const dropdown = document.querySelector('.account-nav-dropdown')
    if (dropdown && dropdown.style.display != 'block') {
      if (unloaded) {
        unloaded = false;
        this.fetchDropdownContent();
      }
      dropdown.style.display = 'block';
    } else {
      dropdown.style.display = 'none';
    }
  }

  // Hides the dropdown menu
  //
  hideDropdownMenu(event) {
    const dropdown = document.querySelector('.account-nav-dropdown');
    if (dropdown && dropdown.style.display != 'none') {
     dropdown.style.display = 'none';
    }
  }

  // Reveals the dropdown menu.
  // If no menu items loaded yet, fetches them from api.
  //
  showDropdownMenu(event) {
    const dropdown = document.querySelector('.account-nav-dropdown');
    if (dropdown && dropdown.style.display != 'block') {
      if (unloaded) {
        unloaded = false;
        this.fetchDropdownContent();
      }
      dropdown.style.display = 'block';
    }
  }

  // Hits the menus api endpoint and then creates the appropriate html
  // for each items and inserts it into the dropdown menu.
  //
  async fetchDropdownContent() {
    const id = this.currentUserTarget.getAttribute('user-id');

    await axios.get(MENU_ITEMS_ENDPOINT(id))
    .then(response => {
      const items = response.data.data.items;
      const dropdownContent = this.dropdownContentTarget;

      items.forEach(item => {
        if (item.key == 'hr'){
          const hr = document.createElement('hr');
          dropdownContent.appendChild(hr);
        } else {
          const li = document.createElement('li');
          const span = document.createElement('span');
          span.innerHTML = item.title

          if (item.icon) {
            var icon = document.createElement('i');
            icon.classList.add(item.icon);
          }
          if (item.image) {
            var image = document.createElement('img')
            image.classList.add('avatar', 'no-border', 'show-for-large-up')
            image.src = item.image
          }
          if (item.link) {
            var a = document.createElement('a');
            a.setAttribute('href', item.link);
          }
          // Special case for rendering plain text in corect position
          if (!item.link && !item.image && !item.icon) {
            span.classList.add('user-id');
            span.style.marginLeft = '3.3em';
          }

          const parent = a || li;
          if (image) parent.appendChild(image);
          if (icon) parent.appendChild(icon);
          parent.appendChild(span);
          if (a) li.appendChild(a);
          dropdownContent.appendChild(li);
        }
      });
    })
    .catch(error => {
      console.log(error);
    });
  }
}