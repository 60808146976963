import React from 'react';
import { ActionCableProvider } from 'use-action-cable';
import Conversation from './Conversation';
import autoload from '../../helpers/autoload';

const ConversationWrapper = ({ userId, conversationId, isAdmin }) => {
  return (
    <ActionCableProvider url="/cable">
      <Conversation
        userId={userId}
        conversationId={conversationId}
        isAdmin={isAdmin}
      />
    </ActionCableProvider>
  );
};

export default autoload(ConversationWrapper, 'conversation');
