import React, { useState, useEffect } from 'react';

const SearchBar = ({ searchFiltersRef, updateBadges }) => {
  const [query, setQuery] = useState('');

  const handleOnSubmit = (event) => {
    event.preventDefault();
    searchFiltersRef.current['query'] = [{ name: query, value: query }];
    updateBadges();
    setQuery('');
  };

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  return (
    <div className="search-area" styles={{float: 'left'}}>
      <form onSubmit={handleOnSubmit} role="search" className="form_menu" onChange={e => e.preventDefault()}>
        <input
          type="text"
          name="search"
          id="search"
          placeholder="Search by Game ID, Title, or Event ID"
          className="event-finder-search"
          onChange={handleInputChange}
          value={query}
        />
      </form>
    </div>
  );
};

export default SearchBar;
