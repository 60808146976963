import React from 'react';
import PropTypes, { number } from 'prop-types';
import moment from 'moment';

const ConversationListItem = ({ conversation, currentConversationId }) => {
  const linkToConversation = `/my_messages/${
    conversation.id
  }-${conversation.subject.replace(/\s+/g, '-').toLowerCase()}`;

  const currentConversationClass =
    conversation.id === currentConversationId
      ? 'current-conversation'
      : 'other-conversation';

  return (
    <div>
      <a href={linkToConversation} className={currentConversationClass}>
        <div className="conversation">
          <div className="conversation-from right">
            <span className="no-link">From </span>
            {`${conversation.last_message.creator.name} `}
            <span
              className="time-stamp"
              data-post-date={moment(
                conversation.last_message_created_at,
              ).format('LT MMM D YYYY', true)}
            >
              <span className="in-words" data-words="true">
                {moment(conversation.last_message_created_at).fromNow()}
              </span>
              <span className="in-time" data-time="true">
                on{' '}
                {moment(conversation.last_message_created_at).format(
                  'LT MMM D YYYY',
                  true,
                )}
              </span>
            </span>
          </div>

          <div className="conversation-subject">
            {conversation.unread_messages && (
              <span className="subject new">
                <i className="fa fa-comment-exclaim"> </i>
                {conversation.subject}
              </span>
            )}

            {!conversation.unread_messages && (
              <span className="subject new">{conversation.subject}</span>
            )}
          </div>

          <div className="last-message-avatar">
            <img
              className="avatar"
              src={conversation.last_message.creator.avatar_url.replace(
                'smallest',
                'normal',
              )}
              alt="Normal"
            />
          </div>

          <div className="conversation-details">
            <div className="conversation-from">
              <span className="no-link">From</span>
              {conversation.last_message.creator.name}
              <span
                className="time-stamp"
                data-post-date={moment(
                  conversation.last_message_created_at,
                ).format('LT MMM D YYYY', true)}
              >
                <span className="in-words" data-words="true">
                  {moment(conversation.last_message_created_at).fromNow()}
                </span>
                <span className="in-time" data-time="true">
                  on{' '}
                  {moment(conversation.last_message_created_at).format(
                    'LT MMM D YYYY',
                    true,
                  )}
                </span>
              </span>
            </div>
            <div className="conversation-excerpt">
              <div className="excerpt-text">
                <div
                  dangerouslySetInnerHTML={{
                    __html: conversation.last_message.body,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="clear"></div>
      </a>
    </div>
  );
};

export default ConversationListItem;
