/* global window  */
import * as Validator from 'validatorjs';
export const FORM_ATTRIBUTE = '[data="validate-form"]';
export const RULES_ATTRIBUTE = '[data-rules]';

const generateRulesFromHash = dataRules => {
  const rules = JSON.parse(dataRules);
  const topLevels = Object.keys(rules).filter(key => rules[key] === true);
  const initialRules = topLevels;

  const lowerLevels = Object.keys(rules).filter(
    key => !topLevels.includes(key),
  );

  lowerLevels.forEach(lowerLevel => {
    const value = rules[lowerLevel];
    if (typeof value === 'object' && value !== null) {
      initialRules.push(lowerLevel);

      Object.keys(value).forEach(key =>
        initialRules.push(`${key}:${value[key]}`),
      );
    } else {
      initialRules.push(`${lowerLevel}:${value}`);
    }
  });

  console.log(dataRules);
  console.log(initialRules.join('|'));

  return initialRules.join('|');
};

export const getRulesAndData = element => {
  let rules = {};
  let data = {};

  const elementRules = element.querySelectorAll(RULES_ATTRIBUTE);

  elementRules.forEach(requiredElement => {
    const { rules: dataRules } = requiredElement.dataset;
    const pipedDataRules = generateRulesFromHash(dataRules);
    const name = requiredElement.name;

    rules[name] = pipedDataRules;
    data[name] = requiredElement.value;
  });

  return { rules, data };
};

export const clearPreviousErrors = element => {
  element.querySelectorAll('.error-message').forEach(error => {
    error.remove();
  });

  element.querySelectorAll('.inline-error').forEach(error => {
    error.classList.remove('inline-error');
  });
};

export const addErrorsToForm = (element, errors) => {
  Object.keys(errors).forEach(key => {
    const domElement = element.querySelector(`[name="${key}"]`);

    domElement.classList.add('inline-error');
    const markup = `
      <div class="error-message" data-error-message="${key}">
        <span class="filled">
        <label class="inline inline-error-message">
          ${errors[key]}
        </label>
      </div>
    `;

    domElement.insertAdjacentHTML('afterend', markup);
  });
};

export const messages = {
  required: 'This value is required.',
  email: 'This format of the email is invalid',
  numeric: 'must be a number',
  max: {
    string: 'This should be lower than or equal to :max.',
    numeric: 'Must be less than or equal to :max',
  },
  min: {
    string: 'This should be greater than or equal to :min characters.',
    numeric: 'Must be greater than or equal to :min',
  },
};

export const validateForms = () => {
  const validatedForms = document.querySelectorAll(FORM_ATTRIBUTE);

  validatedForms.forEach(formElement => {
    formElement.addEventListener('submit', function(event) {
      event.preventDefault();

      // Get the required rules and values on the form
      const { rules, data } = getRulesAndData(formElement);

      // Clear any previous errors on the last submit attempt
      clearPreviousErrors(formElement);

      const validation = new Validator(data, rules, messages);

      if (validation.fails()) {
        const { errors } = validation.errors;

        addErrorsToForm(formElement, errors);
      }

      if (validation.passes()) {
        clearPreviousErrors(formElement);

        formElement.submit();
      }
    });
  });
};
