import React from "react";
import Banner from "./Banner";

export default function ScreenCaptureBannerView({
  title,
  content,
  onClick,
  buttonText,
  altButtonText,
  altOnClick,
  isMobileScreen,
  mobileImage,
  desktopImage,
  raw,
  marginTop='50%',
  marginRight='0'
}) {
  const imageSrc = isMobileScreen ? mobileImage : desktopImage;
  return (
    <div className='content' style={{position: 'relative'}}>
      <h2 className="slide-title">{title}</h2>
      <Banner
        style={{position: 'absolute', marginTop: marginTop, transform: "translate(0%, -50%)", marginRight: marginRight}}
        content={content}
        buttonText={buttonText ?? 'GOT IT'}
        onClick={onClick}
        altButtonText={altButtonText}
        altOnClick={altOnClick}
        raw={raw}
      />
      <img className="slide-tooltip-image" src={imageSrc} />
    </div>
  )
}