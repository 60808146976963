import {
  __,
  complement,
  findIndex,
  includes,
  insert,
  isEmpty,
  map,
  prop,
  propEq,
  remove,
  uniqBy,
} from 'ramda';

const filterIds = map(prop('id'));
const filterDupeElements = uniqBy(prop('id'));
const isMatchingId = (id, allIds) => includes(__, allIds)(id);
const notEmpty = complement(isEmpty);
const updateConversation = (conversation, conversations) => {
  const currentIndex = findIndex(propEq('id', conversation.id))(conversations);
  let conversationsToSave = remove(currentIndex, 1, conversations);
  conversationsToSave = insert(currentIndex, conversation, conversationsToSave);

  return conversationsToSave;
};

const getUpdatedConversations = (currentConversations, newConversations) => {
  let conversationsToSave = [...currentConversations];
  const allIds = filterIds(conversationsToSave);
  newConversations.forEach(conversation => {
    if (isMatchingId(conversation.id, allIds)) {
      conversationsToSave = updateConversation(conversation, conversationsToSave);
    }else {
      conversationsToSave.unshift(conversation);
    }
  });

  return conversationsToSave;
};

export { getUpdatedConversations, filterDupeElements, notEmpty };