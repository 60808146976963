import React, { useState, useEffect, useRef } from "react";
import Tippy from '@tippyjs/react';
// type Props = {
//   title: string;
//   position: { top: number; left: number };
//   positionMobile: { top: number; left: number };
//   direction: string;
//   content: string;
//   onClick: () => void;
//   buttonContent: string;
// }; 

export default function ScreenCaptureTooltipView({
  title,
  position,
  positionMobile,
  arrowOffset,
  isMobileScreen,
  direction,
  content,
  onClick,
  buttonContent,
  altButtonText,
  altOnClick,
  mobileImage,
  desktopImage,
  icon,
  raw
}) {
  const containerRef = useRef(null);
  const tooltipAnchorRef = useRef(null);

  useEffect(() => {
    if (tooltipAnchorRef.current) {
      tooltipAnchorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center'  });
    }
  }, []);

  const [tooltipShown, setTooltipShown] = useState(false);
  if (!title || !position || !positionMobile || !direction || !onClick || !buttonContent) {
    throw new Error('ScreenCaptureTooltipView requires all props to be defined');
  }

  // Select the image and position based on the screen size
  const imageSrc = isMobileScreen ? mobileImage : desktopImage;
  const tooltipPosition = isMobileScreen ? positionMobile : position;

  const logImageDetails = () => {
    if (containerRef.current) {
      const img = containerRef.current.querySelector('img');
      if (img) {
        const width = img.offsetWidth;
        const height = img.offsetHeight;
        const aspectRatio = width / height;
      }
    }
  };

  // useEffect hook to handle window resize
  useEffect(() => {
    logImageDetails();
    const handleResize = () => {
      logImageDetails();
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobileScreen, position, positionMobile]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTooltipShown(true);
    }, 500);

    return () => clearTimeout(timeout);
  }, []);
    

  return (
    <div className='content'>
      <h2 className='slide-title'>{title}</h2>
      <div className='slide-tooltip-container' ref={containerRef}>
        <img className="slide-tooltip-image" src={imageSrc}
          alt="tooltip" />
        <Tippy
          content={
            raw ?
              <div style={{ display: 'flex', flexDirection: 'column'}}>
                {raw}
                <div className='next-button-row' style={{display: 'flex', justifyContent: altOnClick ? 'space-between' : 'end'}}>
                {(altButtonText && <div className='banner-button back-button' onClick={altOnClick}>{altButtonText}</div>)}
                {(buttonContent && <div className='banner-button next-button' onClick={onClick}>{buttonContent}</div>)}
                </div>
              </div>
              :
              <div style={{ display: 'flex', flexDirection: 'column'}}>
                <p className='tooltip-content-p'>{content}</p>
                {icon && <i className={icon}></i>}
                <div className='next-button-row' style={{display: 'flex', justifyContent: altOnClick ? 'space-between' : 'end'}}>
                {(altButtonText && <div className='banner-button back-button' onClick={altOnClick}>{altButtonText}</div>)}
                {(buttonContent && <div className='banner-button next-button' onClick={onClick}>{buttonContent}</div>)}
                </div>
              </div>
          }
          visible={tooltipShown} hideOnClick={false} interactive={true} placement={direction} trigger={'manual'}
          zIndex={1}
          popperOptions={{
            strategy: 'fixed',
            modifiers: [
              {
                name: 'flip',
                options: {
                  fallbackPlacements: ['left', 'right'],
                },
              },
            ],
          }}
        >
          <div className="tooltip-anchor" style={{ ...tooltipPosition }} ref={tooltipAnchorRef}></div>
        </Tippy>
      </div>
    </div>
  );
}