import React from 'react';
import PropTypes from 'prop-types';

const DeactivateLink = ({
  handleDeactivate,
  paymentMethod,
  showStateColumn,
}) => {
  return (
    <span>
      <a onClick={() => handleDeactivate(paymentMethod)}>
        {showStateColumn ? 'Deactivate' : 'Delete'}
      </a>
    </span>
  );
};

DeactivateLink.propTypes = {
  handleDeactivate: PropTypes.func.isRequired,
  paymentMethod: PropTypes.shape().isRequired,
  showStateColumn: PropTypes.bool,
};

DeactivateLink.defaultProps = {
  showStateColumn: false,
};

export default DeactivateLink;
