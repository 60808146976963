import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import JSONDateParser from '../../../helpers/jsonDateParser'

class FinderResult extends Component {
  static displayName = 'FinderResult';

  static propTypes = {
    event: PropTypes.object.isRequired,
  };

  formatDate(dateString) {
    let date = new JSONDateParser(dateString);

    return (
      date.weekDay() +
      ' ' +
      date.hours() +
      ':' +
      date.minutes() +
      ' ' +
      date.meridian() +
      ' ' +
      date.timezone()
    );
  }

  formatDuration(event) {
    let duration = parseFloat(event.event_duration);
    let hours = Math.floor(duration);
    let minutes = Math.floor((duration - hours) * 60);

    return `${hours > 0 ? hours + ' hr' : ''} ${minutes > 0 ? minutes + ' min' : ''}`.trim();
  }

  onLinkClick(event) {
    if (typeof gtag === 'function') {
      gtag('event', 'event_moderation', {'clicked_event': event.title});
    }
  }

  render() {
    const event = this.props.event;

    return (
      <a href={`/events/moderation/${event.id}?` + this.props.currQuery}>
        <div
          className={
            this.props.selectedEventId === parseInt(event.id)
              ? 'current-conversation'
              : ''
          }
        >
          <div className="conversation event-message-moderation">
            <div
              className={
                event.last_message && event.last_message.is_unread
                  ? 'unread-row'
                  : ''
              }
            >
              <div
                className="conversation-details no-link"
                style={{ marginLeft: '10px' }}
              >
                <div
                  className={
                    event.last_message && event.last_message.is_unread
                      ? 'event-title unread-message'
                      : 'event-title'
                  }
                >
                  <span>{event.title.slice(0, 35)}</span>
                  <div className="pill gray" title="Number of Messages">
                    {event.message_count}
                  </div>
                </div>
                {`${this.formatDate(event.start_date)} ${
                  event.event_duration
                    ? `for ${this.formatDuration(event)}`
                    : ''
                }`}
                <div className="subject" style={{ overflow: '' }}>
                  {event.last_message && (
                    <div>
                      <div className="last-message-avatar">
                        <img
                          className="avatar"
                          src={event.last_message.creator.avatar_url}
                          alt="Smallest"
                        ></img>
                      </div>
                      {event.last_message.creator.role != '' && (
                        <div className="pill black">
                          {event.last_message.creator.role}
                        </div>
                      )}
                      {`  ${event.last_message.creator.login} `}
                      <div className="timestamp">
                        <span
                          className="time-stamp"
                          data-post-date={event.last_message.message.created_at}
                        >
                          <span className="in-words" data-words="true">
                            {moment(
                              event.last_message.message.created_at,
                            ).fromNow()}
                          </span>
                          <span className="in-time" data-time="true">
                            {moment(
                              event.last_message.message.created_at,
                            ).format('LT MMM D YYYY', true)}
                          </span>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="clear"></div>
            </div>
          </div>
        </div>
      </a>
    );
  }
}

export default FinderResult;
