/* global document  */
/* global window  */
import axios from 'axios';
import gifFrames from 'gif-frames';
import MicroModal from 'micromodal';
import moment from 'moment-timezone';

document.addEventListener('DOMContentLoaded', () => {
  window.MicroModal = MicroModal;
  window.gifFrames = gifFrames;
  window.moment = moment;

  function initializeModals() {
    MicroModal.init({
      onShow: modal => {
        const ajaxUrl = modal.getAttribute('ajax-url');
        if (ajaxUrl !== null) {
          const modalId = modal.getAttribute('id');
          const bodyId = `${modalId}-body`;
          axios.get(ajaxUrl).then(response => {
            document.getElementById(bodyId).innerHTML = response.data;
          });
        }
      },
      openClass: 'is-open',
      disableScroll: true,
      disableFocus: false,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      debugMode: false,
    });
  }

  // Initial modals setup
  initializeModals();

  // Observe dynamic changes in the DOM
  const observer = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
      if (mutation.addedNodes.length > 0) {
        mutation.addedNodes.forEach(node => {
          if (node.nodeType === Node.ELEMENT_NODE) {
            // Reinitialize modals when new elements with data-micromodal-trigger are added
            if (node.matches('[data-micromodal-trigger]') || node.querySelector('[data-micromodal-trigger]')) {
              initializeModals();
            }
          }
        });
      }
    });
  });

  // Start observing the entire body for dynamically added elements
  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });
});
