import React, { useState, useEffect, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import axios from 'axios';
import Loading from './Loading';
import FinderResult from './FinderResult';

const FinderResults = ({ conventionId, search, searchFilter, isApiReady, parentSortOrder, setParentSortOrder }) => {
  const [totalExhibitors, setTotalExhibitors] = useState(0);
  const [exhibitors, setExhibitors] = useState([]);
  const [hasMoreExhibitors, setHasMoreExhibitors] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [nextHref, setNextHref] = useState(null);

  // Reset state whenever filters change
  useEffect(() => {
    resetState();
  }, [conventionId, search, searchFilter]);

  const toggleSortOrder = (field) => {
    let newSortOrder

    if (field === null) {
      newSortOrder = parentSortOrder === null ? 'exhibitor-desc' : null;
    } else if (field === 'booth') {
      newSortOrder = parentSortOrder === 'booth' ? 'booth-desc' : 'booth';
    }

    // Update the parent sort order state
    setParentSortOrder(newSortOrder);

    // Reset state to trigger a new API call with the updated sort order
    resetState();

    // Update URL with new sort order without reloading the page
    const queryParams = new URLSearchParams(window.location.search);
    if (newSortOrder.includes('desc')) {
      queryParams.set('order', newSortOrder);
    } else {
      queryParams.set('order', newSortOrder.replace('-desc', ''));
    }
    window.history.replaceState(null, '', `${window.location.pathname}?${queryParams.toString()}`)
  };

  const resetState = () => {
    setTotalExhibitors(0);
    setExhibitors([]);
    setHasMoreExhibitors(true);
    setLoading(false);
    setError(null);
    setNextHref(null);
  };


  const loadItems = () => {
    // Do nothing if API is not ready
    if (!isApiReady) return;

    let baseUri = '/api/v1/exhibitors';
    let url = nextHref ? nextHref : baseUri;

    // Append search query to the URL if it exists
    if (search) {
      url += nextHref && nextHref.includes('?') ? `&search=${encodeURIComponent(search)}` : `?search=${encodeURIComponent(search)}`;
    }

    // Append category parameters to the URL if they exist
    if (searchFilter.category.length > 0) {
      const categoryParams = searchFilter.category.map(cat => `category[]=${encodeURIComponent(cat)}`).join('&');
      url += `${url.includes('?') ? '&' : '?'}${categoryParams}`;
    }

    if (parentSortOrder) {
      url += `${url.includes('?') ? '&' : '?'}order=${encodeURIComponent(parentSortOrder)}`;
    }

    if (hasMoreExhibitors && !loading) {
      setLoading(true);
      const currentScrollPosition = window.scrollY;
      axios.get(url).then((res) => {
        if (res.data.records.length > 0) {
          setTotalExhibitors(res.data.total_count);
          setExhibitors([...exhibitors, ...res.data.records]);
          setNextHref(res.data.next_path);
          setHasMoreExhibitors(res.data.has_more);
          setLoading(false);
          window.scroll(0, currentScrollPosition); // Restore scroll position
        } else if (res.data.error) {
          setError(res.data.error);
          setTotalExhibitors(0);
          setHasMoreExhibitors(false);
          setLoading(false);
        } else {
          setTotalExhibitors(0);
          setHasMoreExhibitors(false);
        }
      });
    }
  };

  const resultsMessage = totalExhibitors === 1 ? 'exhibitor' : 'exhibitors';

  return (
    <div>
      <div style={{ textAlign: 'center', width: '100%', verticalAlign: 'middle', display: 'relative' }}>
        <p style={{ maxWidth: 'none' }}>
          {`Listing ${totalExhibitors} ${resultsMessage}`}
        </p>
      </div>
      <div className="result-header">
        <div className="row">
          <div className="large-1 small-1 columns icon">&nbsp;</div>
          <div className="large-5 small-5 columns title" onClick={() => toggleSortOrder(null)} style={{ cursor: 'pointer' }}>
            Exhibitor
            {!parentSortOrder && <i className="fa fa-sort-asc" style={{ lineHeight: 0, marginLeft: '1.5px' }}></i>}
            {parentSortOrder === 'exhibitor-desc' && <i className="fa fa-sort-desc" style={{ lineHeight: 0, marginLeft: '1.5px' }}></i>}
          </div>
          <div className="large-6 small-6 columns time" onClick={() => toggleSortOrder('booth')} style={{ cursor: 'pointer' }}>
            Location
            {parentSortOrder === 'booth' && <i className="fa fa-sort-asc" style={{ lineHeight: 0, marginLeft: '1.5px' }}></i>}
            {parentSortOrder === 'booth-desc' && <i className="fa fa-sort-desc" style={{ lineHeight: 0, marginLeft: '1.5px' }}></i>}
          </div>
        </div>
      </div>
      <div className="results_container">
        <div className="results">
          <div className="event-info">
            <div className="event-info-data">
              <div className="result-set-pages">
                <InfiniteScroll
                  pageStart={0}
                  loadMore={loadItems}
                  loader={<Loading key={0} />}
                  threshold={150}
                  hasMore={hasMoreExhibitors}
                >
                  {error ? <Error message={error} /> : null}
                  {exhibitors.map((exhibitor, i) => (
                    <FinderResult key={i} exhibitor={exhibitor} />
                  ))}
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinderResults;
