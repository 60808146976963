import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import JSONDateParser from '../../../helpers/jsonDateParser'

class FinderResult extends Component {
  static displayName = 'FinderResult';

  static propTypes = {
    event: PropTypes.object.isRequired,
  };

  formatGameSystem(event) {
    if (event.game_system.length > 0) {
      return event.game_system + ', ' + event.rules_edition + ' Ed';
    }
  }

  formatDay(dateString) {
    let date = new JSONDateParser(dateString);
    return date.weekDay();
  }

  formatDate(dateString) {
    let date = new JSONDateParser(dateString);
    return (
      date.hours() +
      ':' +
      date.minutes() +
      ' ' +
      date.meridian() +
      ' ' +
      date.timezone()
    );
  }

  formatDuration(event) {
    let duration = parseFloat(event.event_duration);
    let hours = Math.floor(duration);
    let minutes = Math.floor((duration - hours) * 60);

    return `${hours > 0 ? hours + ' hr' : ''} ${minutes > 0 ? minutes + ' min' : ''}`.trim();
  }

  formatEventCost(event) {
    if (Math.floor(event.event_cost) === 0) {
      return 'Free';
    } else {
      return `$${Math.floor(event.event_cost)}`;
    }
  }

  formatTimeZone(dateString) {
    let date = new JSONDateParser(dateString);
    return date.timezone();
  }

  onLinkClick(event) {
    if (typeof gtag === 'function') {
      gtag('event', 'event_finder', {'clicked_event': event.title});
    }
  }

  render() {
    const event = this.props.event._source;
    const iconUrl = `/event_types/Web${event.event_type.replace(
      /\s.*/,
      '',
    )}100px_1.gif`;
    var currentTime = new Date();

    var hasSoldOutClass = {
      event_row_sold_out: !(
        event.tickets_available > 0 || event.max_players_unlimited
      ),
    };

    var hasConcludedClass = {
      event_row_concluded: Date.parse(event.end_date) < currentTime,
    };

    var eventStatusClass = classNames(
      'row result-data',
      hasSoldOutClass,
      hasConcludedClass,
    );

    return (
      <a
        key={event.id}
        id={`event-${event.id}`}
        onClick={this.onLinkClick.bind(this, event)}
        href={`/events/${event.id}`}
      >
        <div className={eventStatusClass}>
          <div className="row show-for-small">
            <div id="event-title" className="small-12 name-small">
              {event.title}
            </div>
          </div>
          <div className="large-1 small-1 columns text-center icon">
            <img border={0} height={40} src={iconUrl} width={40} />
          </div>
          <div className="large-5 small-5 columns name">
            <span id="event-title" className="hide-for-small">
              {event.title}
            </span>
            <span className="subtitle">{this.formatGameSystem(event)}</span>
          </div>
          <div className="large-4 small-3 columns time">
            <span style={{ display: 'block' }}>
              {this.formatDay(event.start_date)}
            </span>
            <span style={{ display: 'block' }}>
              {this.formatDate(event.start_date)}
            </span>
          </div>
          <div className="large-1 small-2 columns duration">
            {this.formatDuration(event)}
          </div>
          <div className="large-1 small-1 columns cost-ticketsleft">
            <div className="text-right cost">{this.formatEventCost(event)}</div>
          </div>
        </div>
      </a>
    );
  }
}

export default FinderResult;
