import React, { useState, useEffect } from 'react';
import classNames from 'classnames'

const OuterFilterCheckbox = ({ id, title, searchFiltersRef, loadInnerFilters }) => {
  const [checked, setChecked] = useState(null);

  const isChecked = () => {
    if (checked === null) {
      setChecked(searchFiltersRef.current[id].length > 0);
    }
    return checked;
  }

  return (
    <div 
      className={classNames({'modal-filter-checkbox': true, checked: isChecked()})}
      onClick={() => loadInnerFilters(id)}
    >
      {title}
    </div>
  );
};

export default OuterFilterCheckbox;
