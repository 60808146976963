import { UNPROJECT_Z } from '../constants';

const getMap = lmap => {
  const { leafletElement: el } = lmap.current;

  return el;
};

const project = (latlng, lmap) => getMap(lmap).project(latlng, UNPROJECT_Z);
const unproject = (coords, lmap) => getMap(lmap).unproject(coords, UNPROJECT_Z);

const topLeftXY = lmap => {
  const topLeft = getMap(lmap)
    .getBounds()
    .getNorthWest();

  return project(topLeft, lmap);
};

const bottomRightXY = lmap => {
  const bottomRight = getMap(lmap)
    .getBounds()
    .getSouthEast();

  return project(bottomRight, lmap);
};

const getMapBounds = lmap => {
  const { x: topX, y: topY } = topLeftXY(lmap);
  const { x: bottomX, y: bottomY } = bottomRightXY(lmap);

  return {
    topLeft: {
      x: topX,
      y: topY,
    },
    bottomRight: {
      x: bottomX,
      y: bottomY,
    },
  };
};

export { getMap, project, unproject, topLeftXY, bottomRightXY, getMapBounds };
