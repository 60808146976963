import React from 'react';
import PropTypes from 'prop-types';

import { displayName, displayFilter } from '../../../helpers/displayHelpers';

const ScheduleFilterBadge = ({ name, filter, removeFilter }) => {
  const handleInputChange = event => {
    event.preventDefault();

    removeFilter({ [filter]: name });
  };

  return (
    <span
      className="foo"
      onClick={handleInputChange}
      role="button"
      onKeyPress={handleInputChange}
      tabIndex={0}
    >
      <div className="finder-filter">
        {displayFilter(filter)}: {displayName(filter, name)}
        <div className="right">
          <i className="fa fa-times-circle"></i>
        </div>
      </div>
    </span>
  );
};

export default ScheduleFilterBadge;

ScheduleFilterBadge.propTypes = {
  name: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
  removeFilter: PropTypes.func.isRequired,
};
