import axios from 'axios';

document.addEventListener('DOMContentLoaded', () => {

  function clearAddressFields() {
    const exhibitorAddressId = document.getElementById(
      'exhibitor_application_exhibitor_attributes_address_attributes_id'
    );
    const exhibitorAddressAddress1 = document.getElementById(
      'exhibitor_application_exhibitor_attributes_address_attributes_address1'
    );
    const exhibitorAddressAddress2 = document.getElementById(
      'exhibitor_application_exhibitor_attributes_address_attributes_address2'
    );
    const exhibitorAddressCity = document.getElementById(
      'exhibitor_application_exhibitor_attributes_address_attributes_city'
    );
    const exhibitorAddressState = document.getElementById(
      'exhibitor_application_exhibitor_attributes_address_attributes_state'
    );
    const exhibitorAddressZip = document.getElementById(
      'exhibitor_application_exhibitor_attributes_address_attributes_zip'
    );
    const exhibitorAddressCountry = document.getElementById(
      'exhibitor_application_exhibitor_attributes_address_attributes_country'
    );
    const exhibitorAddressWebsite = document.getElementById(
      'exhibitor_application_exhibitor_attributes_address_attributes_website'
    );
    const exhibitorAddressPhone = document.getElementById(
      'exhibitor_application_exhibitor_attributes_address_attributes_phone'
    );

    exhibitorAddressId.value = '';
    exhibitorAddressAddress1.value = '';
    exhibitorAddressAddress2.value = '';
    exhibitorAddressCity.value = '';
    exhibitorAddressState.value = '';
    exhibitorAddressZip.value = '';
    exhibitorAddressCountry.value = 'US';
    exhibitorAddressWebsite.value = '';
    exhibitorAddressPhone.value = '';

    return true;
  }

  function clearExhibitorFields() {
    const exhibitorIdField = document.getElementById('exhibitor_application_exhibitor_id');
    const exhibitorExhibitorIdField = document.getElementById('exhibitor_application_exhibitor_attributes_id');
    const exhibitorNameSpan = document.getElementById('exhibitor-name');
    const exhibitorFindOrCreateSpan = document.getElementById('exhibitor-find-or-create');
    const exhibitorUnlinkLinkSpan = document.getElementById('exhibitor-unlink-link');
    const exhibitorProfileLinkSpan = document.getElementById('exhibitor-profile-link');

    exhibitorIdField.value = '';
    exhibitorExhibitorIdField.value = ''; // TODO: Why do I need this twice?
    exhibitorNameSpan.innerHTML = '';
    exhibitorFindOrCreateSpan.style.display = 'inline';
    exhibitorUnlinkLinkSpan.style.display = 'none';
    exhibitorProfileLinkSpan.style.display = 'none';

    resetPrimaryContactFields();
    clearAddressFields();
    clearExhibitorProfileFields();

    return true;
  }

  function clearExhibitorProfileFields() {
    const exhibitorExhibitorProfileId = document.getElementById(
      'exhibitor_application_exhibitor_attributes_exhibitor_profile_attributes_id'
    );
    const exhibitorExhibitorProfileFaxNumber = document.getElementById(
      'exhibitor_application_exhibitor_attributes_exhibitor_profile_attributes_fax_number'
    );
    const exhibitorExhibitorProfileWorkTypeOther = document.getElementById(
      'exhibitor_application_exhibitor_attributes_exhibitor_profile_attributes_work_type_other'
    );
    const exhibitorExhibitorProfileWorkDescription = document.getElementById(
      'exhibitor_application_exhibitor_attributes_exhibitor_profile_attributes_work_description'
    );
    const exhibitorExhibitorProfileBipoc = document.getElementById(
      'exhibitor_application_exhibitor_attributes_exhibitor_profile_attributes_bipoc'
    );
    const exhibitorExhibitorProfileBipocDetails = document.getElementById(
      'exhibitor_application_exhibitor_attributes_exhibitor_profile_attributes_bipoc_details'
    );

    exhibitorExhibitorProfileId.value = '';
    exhibitorExhibitorProfileFaxNumber.value = '';
    document.querySelectorAll('form input[type=checkbox]').forEach(element => element.checked = false)
    exhibitorExhibitorProfileWorkTypeOther.value = '';
    exhibitorExhibitorProfileWorkDescription.value = '';
    exhibitorExhibitorProfileBipoc.checked = false;
    exhibitorExhibitorProfileBipocDetails.value = '';

    return true;
  }

  function clearPrimaryContactFields() {
    const exhibitorPimaryContactIdField = document.getElementById(
      'exhibitor_application_exhibitor_attributes_primary_contact_id'
    );
    const exhibitorPrimaryContactNameSpan = document.getElementById('primary-contact-name');
    const exhibitorPrimaryContactFindOrCreateSpan = document.getElementById('primary-contact-find-or-create');
    const exhibitorPrimaryContactUnlinkLinkSpan = document.getElementById('primary-contact-unlink-link');
    const exhibitorPrimaryContactProfileLinkSpan = document.getElementById('primary-contact-profile-link');

    exhibitorPimaryContactIdField.value = '';
    exhibitorPrimaryContactNameSpan.innerHTML = '';
    exhibitorPrimaryContactFindOrCreateSpan.style.display = 'inline';
    exhibitorPrimaryContactUnlinkLinkSpan.style.display = 'none';
    exhibitorPrimaryContactProfileLinkSpan.style.display = 'none';

    return true;
  };

  /*
   * Used on the Exhbitor Dashboard -> Sharing Tab -> Complete Exhibitor workflow
   *
   * * Process form submit action for modal windows originating from exhibitor form
   *
   */
  function modalFormSubmit(form) {
    const formAction = form.action;
    const formData = new FormData(form);

    const clearModalForm = () => {
      const contactGroupFirstNameField = form.querySelector('#_contact_group_first_name');
      const contactGroupSearchQueryField = document.getElementById('guest_exhibitor_search_query');
      const contactGroupSearchResultsDiv = form.querySelector('.account-search-results');
      const primaryContactFirstNameField = form.querySelector('#_contact_first_name');
      const primaryContactLastNameField = form.querySelector('#_contact_last_name');
      const primaryContactEmailField = form.querySelector('#_contact_email');
      const primaryContactSearchQueryField = document.getElementById('primary_contact_search_query');
      const primaryContactSearchResultsDiv = form.querySelector('.account-search-results');

      if (contactGroupFirstNameField) { contactGroupFirstNameField.value = ''; }
      if (contactGroupSearchQueryField) { contactGroupSearchQueryField.value = ''; }
      if (contactGroupSearchResultsDiv) { contactGroupSearchResultsDiv.innerHTML = 'No results found'; }
      if (primaryContactFirstNameField) { primaryContactFirstNameField.value = ''; }
      if (primaryContactLastNameField) { primaryContactLastNameField.value = ''; }
      if (primaryContactEmailField) { primaryContactEmailField.value = ''; }
      if (primaryContactSearchQueryField) { primaryContactSearchQueryField.value = ''; }
      if (primaryContactSearchResultsDiv) { primaryContactSearchResultsDiv.innerHTML = 'No results found'; }
    }

    const closeForm = () => {
      const errorMessageDiv = form.querySelector('.error');
      const modal = form.closest('.exhibitor-form-modal');
      const modal_id = modal.id;
      const submitButton = form.querySelector('input[type=submit]');

      // The suggested close method below was not working when page contains
      // multiple forms. Needed to close modal by clicking "Cancel" button.
      //
      // After closing the first modal on page, all links to open modals would
      // re-open the first modal instead of their specified target modal.
      //
      // MicroModal.close(modal_id);
      //
      form.querySelector('.button').click();
      errorMessageDiv.innerHTML = '';
      errorMessageDiv.style.display = 'none';

      if(submitButton) {
        submitButton.disabled = false;
      }
    };

    const populateAddressFields = (address) => {
      const exhibitorAddressId = document.getElementById(
        'exhibitor_application_exhibitor_attributes_address_attributes_id'
      );
      const exhibitorAddressAddress1 = document.getElementById(
        'exhibitor_application_exhibitor_attributes_address_attributes_address1'
      );
      const exhibitorAddressAddress2 = document.getElementById(
        'exhibitor_application_exhibitor_attributes_address_attributes_address2'
      );
      const exhibitorAddressCity = document.getElementById(
        'exhibitor_application_exhibitor_attributes_address_attributes_city'
      );
      const exhibitorAddressState = document.getElementById(
        'exhibitor_application_exhibitor_attributes_address_attributes_state'
      );
      const exhibitorAddressZip = document.getElementById(
        'exhibitor_application_exhibitor_attributes_address_attributes_zip'
      );
      const exhibitorAddressCountry = document.getElementById(
        'exhibitor_application_exhibitor_attributes_address_attributes_country'
      );
      const exhibitorAddressWebsite = document.getElementById(
        'exhibitor_application_exhibitor_attributes_address_attributes_website'
      );
      const exhibitorAddressPhone = document.getElementById(
        'exhibitor_application_exhibitor_attributes_address_attributes_phone'
      );

      exhibitorAddressId.value = address.id;
      exhibitorAddressAddress1.value = address.address1;
      exhibitorAddressAddress2.value = address.address2;
      exhibitorAddressCity.value = address.city;
      exhibitorAddressState.value = address.state;
      exhibitorAddressZip.value = address.zip;
      exhibitorAddressCountry.value = address.country;
      exhibitorAddressWebsite.value = address.website;
      exhibitorAddressPhone.value = address.phone;

      return true;
    };

    const populateExhibitorFields = (contactGroup) => {
      const exhibitorIdField = document.getElementById('exhibitor_application_exhibitor_id');
      const exhibitorExhibitorIdField = document.getElementById('exhibitor_application_exhibitor_attributes_id');
      const exhibitorNameSpan = document.getElementById('exhibitor-name');
      const exhibitorFindOrCreateSpan = document.getElementById('exhibitor-find-or-create');
      const exhibitorUnlinkLinkSpan = document.getElementById('exhibitor-unlink-link');
      const exhibitorProfileLinkSpan = document.getElementById('exhibitor-profile-link');

      exhibitorIdField.value = contactGroup.id;
      exhibitorExhibitorIdField.value = contactGroup.id; // TODO: Why do I need this twice?
      exhibitorNameSpan.innerHTML = contactGroup.name + " (ID: " + contactGroup.id + ")";
      exhibitorFindOrCreateSpan.style.display = 'none';
      exhibitorUnlinkLinkSpan.style.display = 'inline';
      exhibitorProfileLinkSpan.style.display = 'inline';

      return true;
    };

    const populateExhibitorProfileFields = (exhibitorProfile) => {
      const exhibitorExhibitorProfileId = document.getElementById(
        'exhibitor_application_exhibitor_attributes_exhibitor_profile_attributes_id'
      );
      const exhibitorExhibitorProfileFaxNumber = document.getElementById(
        'exhibitor_application_exhibitor_attributes_exhibitor_profile_attributes_fax_number'
      );
      const exhibitorExhibitorProfileWorkType = document.getElementById(
        'exhibitor_application_exhibitor_attributes_exhibitor_profile_attributes_work_type'
      );
      const exhibitorExhibitorProfileWorkDescription = document.getElementById(
        'exhibitor_application_exhibitor_attributes_exhibitor_profile_attributes_work_description'
      );

      exhibitorExhibitorProfileId.value = exhibitorProfile.id;
      exhibitorExhibitorProfileFaxNumber.value = exhibitorProfile.fax_number;
      //exhibitorExhibitorProfileWorkType.value = exhibitorProfile.work_type;
      exhibitorExhibitorProfileWorkDescription.value = exhibitorProfile.work_description;

      return true;
    }

    const populatePrimaryContactFields = (primaryContact) => {
      const exhibitorPimaryContactIdField = document.getElementById(
        'exhibitor_application_exhibitor_attributes_primary_contact_id'
      );
      const exhibitorPrimaryContactNameSpan = document.getElementById('primary-contact-name');
      const exhibitorPrimaryContactFindOrCreateSpan = document.getElementById('primary-contact-find-or-create');
      const exhibitorPrimaryContactUnlinkLinkSpan = document.getElementById('primary-contact-unlink-link');
      const exhibitorPrimaryContactProfileLinkSpan = document.getElementById('primary-contact-profile-link');

      exhibitorPimaryContactIdField.value = primaryContact.id;
      exhibitorPrimaryContactNameSpan.innerHTML = primaryContact.name + " (ID: " + primaryContact.id + ")";
      exhibitorPrimaryContactFindOrCreateSpan.style.display = 'none';
      exhibitorPrimaryContactUnlinkLinkSpan.style.display = 'inline';
      exhibitorPrimaryContactProfileLinkSpan.style.display = 'inline';

      return true;
    }

    const processErrorResponse = function(error) {
      let errorMessage;

      const responseData = error.response.data;
      const responseMessages = responseData.messages;
      if(responseMessages) {
        const responseError = responseMessages.errors[0];
        const responseErrorCode = responseError.code;
        const responseErrorField = responseError.fields[0].field;
        const responseErrorMessage = responseError.fields[0].messages[0];
        errorMessage = responseErrorMessage;
      }

      const errorMessageDiv = form.querySelector('.error');
      const submitButton = form.querySelector('input[type=submit]');

      if(errorMessage !== undefined) {
        errorMessageDiv.innerHTML = errorMessage;
      } else {
        errorMessageDiv.innerHTML = 'An unknown error occured';
      }

      errorMessageDiv.style.display = 'block';
      if(submitButton !== null) {
        submitButton.disabled = false;
      }
    };

    const processSuccessResponse = function(response) {
      const contactGroup = response.data.data.contact_group;
      let primaryContact;

      if(contactGroup) {
        primaryContact = contactGroup.primary_contact;
      } else {
        primaryContact = response.data.data.contact;
      }

      if(contactGroup) {
        clearExhibitorFields();
        populateExhibitorFields(contactGroup);

        if(contactGroup.address) {
          populateAddressFields(contactGroup.address);
        }

        if(contactGroup.exhibitor_profile) {
          populateExhibitorProfileFields(contactGroup.exhibitor_profile);
        }
      }

      if(primaryContact) {
        populatePrimaryContactFields(primaryContact);
      } else if(contactGroup && !primaryContact) {
        clearPrimaryContactFields();
      }

      clearModalForm();
      closeForm();
    };

    axios
      .post(formAction, formData)
      .then((response) => { processSuccessResponse(response); })
      .catch((error) => { processErrorResponse(error); });
  }

  function openInNewTab(url) {
    window.open(url, '_blank').focus();
  }

  function resetPrimaryContactFields() {
    const exhibitorPimaryContactIdField = document.getElementById(
      'exhibitor_application_exhibitor_attributes_primary_contact_id'
    );
    const exhibitorPrimaryContactNameSpan = document.getElementById('primary-contact-name');
    const exhibitorPrimaryContactFindOrCreateSpan = document.getElementById('primary-contact-find-or-create');
    const exhibitorPrimaryContactUnlinkLinkSpan = document.getElementById('primary-contact-unlink-link');
    const exhibitorPrimaryContactProfileLinkSpan = document.getElementById('primary-contact-profile-link');

    exhibitorPimaryContactIdField.value = '';
    exhibitorPrimaryContactNameSpan.innerHTML = '[Requires Organization]';
    exhibitorPrimaryContactFindOrCreateSpan.style.display = 'none';
    exhibitorPrimaryContactUnlinkLinkSpan.style.display = 'none';
    exhibitorPrimaryContactProfileLinkSpan.style.display = 'none';

    return true;
  }

  /*
   * Used on the Exhbitor Dashboard -> Sharing Tab -> Complete Exhibitor workflow
   *
   * * Capture form submit action for modal windows originating from exhibitor form
   *
   */
  document
    .querySelectorAll('.exhibitor-form-modal form')
    .forEach(element => element.addEventListener("submit", (e) => {

    e.preventDefault();

    modalFormSubmit(e.target);

    return true;
  }));

  /*
   * Used on the Exhbitor Dashboard -> Sharing Tab -> Complete Exhibitor workflow
   *
   * * Search for Contact Group & Search for Contact
   * * Triggered when user types text into search field
   *
   */
  document
    .querySelectorAll('.account-search-by-name')
    .forEach(element => element.addEventListener('input', (e) => {

    const queryField = e.target;
    const queryString = queryField.value;
    const contactType = queryField.dataset.contactType;
    const idRegex = new RegExp(/^[1-9][0-9]*$/);

    if ((queryString.length > 3) || idRegex.test(queryString)) {
      const location = '/search/find_contact?search_string=' + queryString + '&contact_type=' + contactType;
      const resultsDiv = queryField.closest('.panel').querySelector('.account-search-results');

      axios
        .get(location)
        .then(response => {
          resultsDiv.innerHTML = response.data;
        })
    };

    return true;
  }));

  /*
   * Used on the Exhbitor Dashboard -> Sharing Tab -> Complete Exhibitor workflow
   *
   * * Search Contact Groups for guest exhibitor - selecting a result
   * * Search Contacts for primary contact - selecting a result
   *
   */
  document
    .querySelectorAll('.account-search-results')
    .forEach(element => element.addEventListener("click", (e) => {

    if (e.target && e.target.matches(".account-search-result")) {
      e.preventDefault();

      const selectedLink = e.target;
      const resultId = selectedLink.dataset.id;
      const form = selectedLink.closest('form');
      const resultIdField = form.querySelector('#account_id');

      resultIdField.value = resultId;
      modalFormSubmit(form);
    }

    return true;
  }));

  /*
   * Used on the Exhbitor Dashboard -> Sharing Tab -> Complete Exhibitor workflow
   */
  if (document.querySelector('#exhibitor-unlink-link')) {
    document
      .querySelector('#exhibitor-unlink-link')
      .addEventListener("click", (e) => {

      e.preventDefault();

      clearExhibitorFields();

      return true;
    });
  };

  /*
   * Used on the Exhbitor Dashboard -> Sharing Tab -> Complete Exhibitor workflow
   */
  if (document.querySelector('#exhibitor-profile-link')) {
    document
      .querySelector('#exhibitor-profile-link')
      .addEventListener("click", (e) => {

      e.preventDefault();

      const exhibitorIdField = document.getElementById(
        'exhibitor_application_exhibitor_id'
      );
      const exhibitorId = exhibitorIdField.value;

      openInNewTab('/profile/' + exhibitorId);

      return true;
    });
  };

  /*
   * Used on the Exhbitor Dashboard -> Sharing Tab -> Complete Exhibitor workflow
   */
  if (document.querySelector('#primary-contact-unlink-link')) {
    document
      .querySelector('#primary-contact-unlink-link')
      .addEventListener("click", (e) => {
        e.preventDefault();
        clearPrimaryContactFields();
        return true;
      });
  };

  /*
   * Used on the Exhbitor Dashboard -> Sharing Tab -> Complete Exhibitor workflow
   */
  if (document.querySelector('#primary-contact-unlink-link')) {
    document
      .querySelector('#primary-contact-profile-link')
      .addEventListener("click", (e) => {
        e.preventDefault();

        const exhibitorPimaryContactIdField = document.getElementById(
          'exhibitor_application_exhibitor_attributes_primary_contact_id'
        );
        const exhibitorPimaryContactId = exhibitorPimaryContactIdField.value;

        openInNewTab('/profile/' + exhibitorPimaryContactId);
        return true;
      });
  };
});
