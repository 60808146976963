import React from 'react';

const NoSupport = () => {
  return (
    <div className="no-support">
      <img
        src="https://images.gencon.com/looking_glass/lg_logo_vector.svg"
        alt="Looking Glass Logo"
      />
      <p>
        Internet Explorer and Edge versions prior to 79 do not support Looking
        Glass.
      </p>
      <p> Please update your browser.</p>
    </div>
  );
};

export default NoSupport;
