import React from 'react';
import classNames from 'classnames';

const TicketRowIcon = ({ contact, title }) => {
  let eTicketed = contact.tickets.length > 0 && contact.tickets[0].e_ticketed;
  let unredeemedCount = contact.tickets.filter((x, i) => { return !x.redeemed; }).length;
  let multipleTickets = unredeemedCount > 1;
  let ticketMessage = '';

  if (eTicketed) {
    title = 'Electronic Ticket ' + contact.status[0].toUpperCase() + contact.status.slice(1);
  } else {
    title = 'Paper Ticket ' + contact.status[0].toUpperCase() + contact.status.slice(1);
  }

  let iconClass = classNames({
    'fa fa-4x': true,
    'fa-ticket-horz': (contact.status == 'unredeemed' && !eTicketed && !multipleTickets), // unredeemed single paper ticket
    'fa-ticket-e': (contact.status == 'unredeemed' && eTicketed && (unredeemedCount == 1)), // unredeemed single e-ticket
    'fa-ticket-horz-blank': (contact.status == 'unredeemed' && multipleTickets), // unredeemed multiple tickets
    'e-ticket-attention-color fa-ticket-horz-blank': (contact.status == 'guests'), // partially redeemed ticket
    'e-ticket-success-color fa-ticket-checked': (contact.status == 'redeemed'), // redeemed ticket
    'e-ticket-error-color fa-ticket-alert': (contact.status == 'failed'), // error
  });

  if(contact.pending){
    iconClass = 'fa e-ticket-pending-color fa-ticket-in-cart fa-4x'
  }

  if (contact.status == 'unredeemed' && unredeemedCount > 1) {
    ticketMessage = '+' + (unredeemedCount - 1);
  }

  if (contact.status == 'guests') {
    ticketMessage = '+' + unredeemedCount;
  }

  return (
    <span className="ticket-count-container">
      <div className="ticket-count-num">
        {ticketMessage}
      </div>
      <i className={iconClass} title={title} ></i>
    </span>
  )
}

export default TicketRowIcon;
