import React, { useState, useRef } from 'react';

import GmCheckbox from './GmCheckbox';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';

const BulkGmUpdater = ({ searchFiltersRef, eventIds, gamemasters }) => {
  const BULK_ADD_ENDPOINT = `/api/v2/bulk_update/add_gms`;
  const BULK_UPDATE_ENDPOINT = `/api/v2/bulk_update/update_gms`;
  const BULK_REMOVE_ENDPOINT = `/api/v2/bulk_update/remove_gms`;

  const [updateGmsShown, setUpdateGmsShown] = useState(true);
  const [gmsField, setGmsField] = useState('');

  const gamemastersRef = useRef(
    gamemasters.map(gm => ({ value: gm.value, name: gm.name, checked: false })),
  );

  const prettyFilterName = name => {
    if (name === 'query') {
      name = 'search';
    }
    if (name.length > 1) {
      name = name.charAt(0).toUpperCase() + name.slice(1);
    }
    return name.match(/[A-Z][a-z]+/g).join(' ');
  };

  const getFilters = () => {
    let filters = [];
    for (const [key, values] of Object.entries(searchFiltersRef.current)) {
      if (values.length > 0) {
        values.map(v => v.name).join(', ');
        filters.push(
          <p>
            <b>{prettyFilterName(key)}</b>
            {': '}
            {values.map(v => v.name).join(', ')}
          </p>,
        );
      }
    }
    return filters;
  };

  const handleInputChange = event => {
    setGmsField(event.target.value);
  };

  const addGms = async () => {
    let body = {
      events: eventIds,
      emails: gmsField.split(',').map(i => i.trim()),
    };
    await axios.post(BULK_ADD_ENDPOINT, body).then(res => {
      if (res.data.flash) {
        const targetNode = document.querySelector('[data-flash]');
        targetNode.dataset.flash = JSON.stringify(res.data.flash);
      }
    });
  };

  const replaceGms = async () => {
    let body = {
      events: eventIds,
      emails: gmsField.split(',').map(i => i.trim()),
    };
    await axios.patch(BULK_UPDATE_ENDPOINT, body).then(res => {
      if (res.data.flash) {
        const targetNode = document.querySelector('[data-flash]');
        targetNode.dataset.flash = JSON.stringify(res.data.flash);
      }
    });
  };

  const removeGms = async () => {
    let toRemove = gamemastersRef.current.filter(gm => gm.checked);
    let body = { events: eventIds, gamemasters: toRemove.map(gm => gm.value) };
    await axios.patch(BULK_REMOVE_ENDPOINT, body).then(res => {
      if (res.data.flash) {
        const targetNode = document.querySelector('[data-flash]');
        targetNode.dataset.flash = JSON.stringify(res.data.flash);
      }
    });
    gamemastersRef.current = gamemastersRef.current.filter(gm => !gm.checked)
    setUpdateGmsShown(true);
  };

  const onReplaceClick = async event => {
    if (gmsField !== '' && eventIds.length > 0) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="er-popup-overlay">
              <div className="er-confirmation-popup">
                <h3>Are you sure?</h3>
                <p>
                  {`This will replace the GMs for `}
                  <strong>{eventIds.length}</strong>
                  {' events with the GMs submitted.'}
                  <br></br>
                </p>
                <button onClick={onClose}>Cancel</button>
                <button
                  onClick={() => {
                    onClose();
                    replaceGms();
                  }}
                >
                  Replace
                </button>
              </div>
            </div>
          );
        },
      });
      event.preventDefault();
    }
  };

  const onAddClick = event => {
    if (gmsField !== '' && eventIds.length > 0) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="er-popup-overlay">
              <div className="er-confirmation-popup">
                <h3>Are you sure?</h3>
                <p>
                  {`This will add the submitted GMs to `}
                  <strong>{eventIds.length}</strong>
                  {' events.'}
                  <br></br>
                </p>
                <button onClick={onClose}>Cancel</button>
                <button
                  onClick={() => {
                    onClose();
                    addGms();
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          );
        },
      });
      event.preventDefault();
    }
  };

  const onRemoveClick = event => {
    if (
      eventIds.length > 0 &&
      gamemastersRef.current.filter(gm => gm.checked).length > 0
    ) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="er-popup-overlay">
              <div className="er-confirmation-popup">
                <h3>Are you sure?</h3>
                <p>
                  {`This will remove the selected GMs from `}
                  <strong>{eventIds.length}</strong>
                  {' events.'}
                  <br></br>
                </p>
                <button onClick={onClose}>Cancel</button>
                <button
                  onClick={() => {
                    onClose();
                    removeGms();
                  }}
                >
                  Remove
                </button>
              </div>
            </div>
          );
        },
      });
      event.preventDefault();
    }
  };

  return (
    <div>
      <p>
        {'Changing GMs for '}
        <strong>{eventIds.length}</strong>
        {' events'}
      </p>
      <p>{'Filters applied: '}</p>
      {getFilters().map(filter => filter)}
      <p></p>

      <div className="ribbon-row">
        <ul className="left">
          <li
            className="btn"
            onClick={event => {
              event.preventDefault();
              setUpdateGmsShown(true);
            }}
          >
            <a href="#add-gms">
              <i className="fa fa-plus fa-2x">
                <label>Add/Replace GMs</label>
              </i>
            </a>
          </li>
          <li
            className="btn"
            onClick={event => {
              event.preventDefault();
              setUpdateGmsShown(false);
            }}
          >
            <a href="#remove-gms">
              <i className="fa fa-trash fa-2x">
                <label>Remove GMs</label>
              </i>
            </a>
          </li>
        </ul>
      </div>

      <div className="gm-tab-content">
        {updateGmsShown ? (
          <div className="attributes wide">
            <p></p>
            <div className="attr">
              <div className="name">
                {'GM Emails:'}
                <br></br>
                {'(comma deliminted)'}
              </div>
              <div className="value">
                <textarea
                  onChange={handleInputChange}
                  name="bulk_update"
                  id="bulk_update_gm_emails"
                ></textarea>
              </div>
            </div>
            <div className="attr">
              <div className="name">&nbsp;</div>
              <button
                onClick={onAddClick}
                className="bulk-update-button"
                title="Add"
              >
                Add
              </button>

              <button
                onClick={onReplaceClick}
                className="bulk-update-button"
                title="Replace"
              >
                Replace
              </button>
            </div>
          </div>
        ) : (
          <div className="gm-tab-content">
            <div className="attributes wide">
              <p></p>
              {gamemastersRef.current.map(gm => (
                <GmCheckbox
                  key={gm.value}
                  value={gm.value}
                  name={gm.name}
                  checked={gm.checked}
                  gmsRef={gamemastersRef}
                />
              ))}

              <div className="attr">
                <div className="name">&nbsp;</div>
                <button
                  onClick={onRemoveClick}
                  className="bulk-update-button"
                  title="Remove"
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BulkGmUpdater;
