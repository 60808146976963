document.addEventListener('DOMContentLoaded', () => {
  const appCheckbox = document.querySelector('#exhibitor_application_bipoc');
  const guestAppCheckbox = document.querySelector(
    '#exhibitor_application_exhibitor_attributes_exhibitor_profile_attributes_bipoc'
  );
  const bipocDetails = document.querySelector('.bipoc_details');

  if (appCheckbox) {
    appCheckbox.addEventListener('click', function(e) {
      if (this.checked) {
        bipocDetails.style.display = '';
      } else {
        document.querySelector('#exhibitor_application_bipoc_details').value = '';
        bipocDetails.style.display = 'none';
      };
    });
  };

  if (guestAppCheckbox) {
    guestAppCheckbox.addEventListener('click', function(e) {
      if (this.checked) {
        bipocDetails.style.display = '';
      } else {
        document.querySelector(
          '#exhibitor_application_exhibitor_attributes_exhibitor_profile_attributes_bipoc_details'
        ).value = '';
        bipocDetails.style.display = 'none';
      };
    });
  };

  const profileCheckbox = document.querySelector('#exhibitor_profile_bipoc');

  if (profileCheckbox) {
    profileCheckbox.addEventListener('click', function(e) {
      if (this.checked) {
        bipocDetails.style.display = '';
      } else {
        document.querySelector('#exhibitor_profile_bipoc_details').value = '';
        bipocDetails.style.display = 'none';
      };
    });
  };
});

