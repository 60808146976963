import React from 'react';

const divStyle = {
  height: '100px',
  padding: '10px',
  fontSize: '1.5em',
  textAlign: 'center',
  verticalAlign: 'middle',
  lineHeight: '70px'
}
const Loading = () => (
  <div
    style={divStyle}>
    Loading ...
  </div>
);

export default Loading;