/* global document  */

// Toggles the 'hidden' attribute of a target tab-content element by ID.
//
const switchToTab = tabID => {
  var activeTab = null;
  const tabs = document.getElementsByClassName('tab-content');
  for (var i = 0; i < tabs.length; i++) {
    if (tabs[i].id === tabID) {
      tabs[i].style.display = 'block';
    } else {
      tabs[i].style.display = 'none';
    }
  }
};

document.addEventListener('DOMContentLoaded', () => {
  const removeBtn = document.querySelector('#remove-btn');
  const addBtn = document.querySelector('#add-replace-btn');

  if (removeBtn) {
    removeBtn.addEventListener('click', event => {
    event.preventDefault();

    switchToTab('remove-tab');
  });
  }

  if (addBtn) {
    addBtn.addEventListener('click', event => {
    event.preventDefault();

    switchToTab('add-replace-tab');
  });
  }
});
