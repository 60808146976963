import React, { useEffect } from 'react';

import EventRegistration from './EventRegistration';

const EventRegistrations = ({ eventRegistrations, userId, isAdmin }) => {
  return (
    <div>
      {eventRegistrations.map(statusGroup => (
        <div>
          <div className="panel_titlebar">{statusGroup.status}</div>
          <p>{statusGroup.description}</p>
          <table cellPadding="5" className="records">
            <tbody>
              <tr>
                <th width="75">Game ID</th>
                <th>Event Title</th>
                <th width="150">Start Time</th>
                <th width="50">Duration</th>
                <th colSpan="4"></th>
              </tr>
              {statusGroup.events.map(er => (
                <EventRegistration
                  key={er.id}
                  id={er.id}
                  userId={userId}
                  status={er.status}
                  gameId={er.game_code}
                  title={er.title}
                  startTime={er.start_date}
                  duration={er.duration}
                  price={er.price}
                  isAdmin={isAdmin}
                />
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default EventRegistrations;
