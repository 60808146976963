import React, {useState} from 'react'

const SearchFilterBadge = ({ filter, values, searchFiltersRef, updateBadges }) => {

  const handleClick = (e) => {
    e.preventDefault();
    searchFiltersRef.current[filter] = [];
    updateBadges();
  }

  const prettyFilterName = (name) => {
    if (name === 'query') {
      name = 'search';
    }
    if (name.length > 1){
      name = name.charAt(0).toUpperCase() + name.slice(1);
    }
    return name.match(/[A-Z][a-z]+/g).join(" ");
  }

  return (
    <div className="finder-filter-link">
       <a onClick={handleClick}>
        <div className="finder-filter">
          {prettyFilterName(filter)}: {values}
          <div className="right">
            <i className="fa fa-times-circle"></i>
          </div>
        </div>
      </a>
    </div>
  );
}

export default SearchFilterBadge;