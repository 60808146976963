// Load all the controllers within this directory and all subdirectories.

import { Application } from 'stimulus';
import DropdownMenuController from './src/dropdown_menu_controller';
import EditTransactionController from './src/edit_transaction_controller';
import EventRegStatsController from './src/event_reg_stats_controller';
import FlatpickrController from './src/flatpickr_controller';
import ForumsController from './src/forums_controller';
import IssueTicketController from './src/issue_ticket_controller';
import MessagesController from './src/messages_controller';
import ReceivablesController from './src/receivables_controller';
import SidebarController from './src/sidebar_controller';
import SidebarTabsController from './src/sidebar-tabs_controller';
import SortableController from './src/sortable_controller';
import TooltipController from './src/tooltip_controller';
import WidgetController from './src/widget_controller';


document.addEventListener('DOMContentLoaded', () => {
  window.Stimulus = Application.start();
  Stimulus.register("edit-transaction", EditTransactionController);
  Stimulus.register("dropdown-menu", DropdownMenuController);
  Stimulus.register("event-reg-stats", EventRegStatsController);
  Stimulus.register("flatpickr", FlatpickrController);
  Stimulus.register("forums", ForumsController);
  Stimulus.register("issue-ticket", IssueTicketController);
  Stimulus.register("messages", MessagesController);
  Stimulus.register("receivables", ReceivablesController);
  Stimulus.register("sidebar", SidebarController);
  Stimulus.register("sidebar-tabs", SidebarTabsController);
  Stimulus.register("sortable", SortableController);
  Stimulus.register("tooltip", TooltipController);
  Stimulus.register("widget", WidgetController);
});
