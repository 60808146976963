/* global window  */
/* eslint-disable */
import Bowser from 'bowser';

const browser = Bowser.getParser(window.navigator.userAgent);

const isValidBrowser = () => {
  const { name, version } = browser.getBrowser();

  if (name === 'Microsoft Edge') {
    if (parseInt(version) < 79) {
      return false;
    }
  }

  if (name === 'Internet Explorer') {
    return false;
  }

  return true;
};

export { isValidBrowser };
