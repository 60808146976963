import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from 'react-modal';
import { without } from 'ramda';
import Loading from './Loading';
import SearchFilterCheckboxes from './SearchFilterCheckboxes';
import axios from 'axios';
import SearchFilterBadge from './SearchFilterBadge';

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 100000,
    bottom: 0,
    backgroundColor: 'rgba(10, 10, 10, 0.60)',
  },
  content: {
    border: 'solid #bca98b 1px',
    background: '#f4f1ec',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '7px',
    outline: 'none',
    padding: '0',
    overflow: 'hidden',
  },
};

const SearchFilter = ({ search, filters, setSearchFilter }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectorOpen, setSelectorOpen] = useState(true);
  const [groupSelected, setGroupSelected] = useState('');
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (modalIsOpen) {
      setSelectorOpen(true);
    }
  }, [modalIsOpen]);

  const openModal = () => {
    setLoading(true);
    setGroupSelected('category');
    axios.get(`/api/v1/exhibitors/meta_data?search=${search}`)
      .then(response => {
        setSelectedGroup(response.data);
        setSelectorOpen(false);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
    setModalIsOpen(true);
  };

  const closeModal = selected => {
    setSearchFilter(selected);
    setModalIsOpen(false);
  };

  const handleBadgeAdd = (group, name) => {}

  const selectModal = () => {
    if (selectorOpen && loading) {
      return <Loading />;
    }
  
    return (
      <SearchFilterCheckboxes
        searchFilter={filters}
        groupSelected={groupSelected}
        selectedGroup={selectedGroup}
        onSelect={handleBadgeAdd}
        closeModal={closeModal}
      />
    );
  };

  const handleBadgeRemoval = (group, name) => {
    const newGroup = without(String(name), filters[group]);
    setSearchFilter({ [group]: newGroup });
  };

  const isGroupChecked = key => filters[key].length > 0;

  return (
    <div className="finder-filters">
      <div className="finder-filter-link">
        {Object.entries(filters).map(([group, names]) => 
          names.map(name => {
            if (name) {
              return (
                <SearchFilterBadge
                  key={`${group}-${name}`}
                  group={group}
                  name={name}
                  onClick={handleBadgeRemoval}
                />
              );
            }
            return null;
          })
        )}
      </div>
      <div className="finder-filters">
        <div className="dash-buttons " style={{width: '98%', marginLeft: 'auto', marginRight: 'auto'}}>
          <div  className="dash-button">
            <div onClick={openModal} className={classNames({ 'modal-filter-checkbox flex-v-center flex-space-between': true, 'checked': isGroupChecked('category') })}>
              <div className='flex-v-center'>
                <i className="fa fa-building"></i>
                {" Category"}
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          ariaHideApp={false}
          style={customStyles}
          contentLabel="Example Modal"
        >
          {selectModal()}
        </Modal>
      </div>
    </div>
  );
};

SearchFilter.propTypes = {
  search: PropTypes.string,
  filters: PropTypes.object,
  setSearchFilter: PropTypes.func.isRequired,
};

export default SearchFilter;
