/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* global document  */
/* global ga  */
/* global refreshFsLightbox  */
import L from 'leaflet';
import { test, equals, min } from 'ramda';

import { PROMO_WIDTH, SVG } from '../constants';
import {
  findById,
  notEquals,
  isEmptyValue,
  notIncludedInArray,
  splitLayerId,
} from './utils';
import { unproject, getMap } from './coordinates';
import { getSelectId } from './url';

const hasEmbed = content => {
  if (test(/<iframe/, content)) {
    return true;
  }

  return false;
};

const promoHeight = content => {
  if (hasEmbed(content)) {
    return 750;
  }

  return 480;
};

const getTranslate3d = transform => {
  const values = transform.split(/\w+\(|\);?/);
  if (!values[1] || !values[1].length) {
    return [];
  }
  return values[1].split(/,\s?/g);
};

const adjustedForRightOffset = (svgElement, currentTransform) => {
  const bodyRect = document.body.getBoundingClientRect();
  const elemRect = svgElement.getBoundingClientRect();
  const width = min(PROMO_WIDTH, bodyRect.width - 30);

  const values = getTranslate3d(currentTransform);
  if (bodyRect.right - elemRect.right + elemRect.width > width) {
    return currentTransform;
  }

  let first = parseInt(values[0], 10) - width;
  first = first + elemRect.width + bodyRect.right - elemRect.right - 30;
  const transform = `translate3d(${first}px, ${values[1]}, ${values[2]})`;

  return transform;
};

const adjustedForRightOffsetForMobile = (svgElement, currentTransform) => {
  const bodyRect = document.body.getBoundingClientRect();
  const elemRect = svgElement.getBoundingClientRect();

  const values = getTranslate3d(currentTransform);
  if (bodyRect.right - elemRect.right + elemRect.width > bodyRect.width) {
    return currentTransform;
  }

  let first = parseInt(values[0], 10) - bodyRect.width;
  first = first + elemRect.width + bodyRect.right - elemRect.right;
  const transform = `translate3d(${first}px, ${values[1]}, ${values[2]})`;

  return transform;
};

const adjustedForBottomOffset = (svgElement, currentTransform, height) => {
  const bodyRect = document.body.getBoundingClientRect();
  const elemRect = svgElement.getBoundingClientRect();

  const distantFromBottom = bodyRect.bottom - elemRect.bottom;
  const distantWithHeightOfTile = distantFromBottom + elemRect.height;

  if (distantWithHeightOfTile > height) {
    return { transform: currentTransform, movedUp: 0 };
  }

  const distantToMoveUp = height - distantWithHeightOfTile;

  const values = getTranslate3d(currentTransform);
  const second = parseInt(values[1], 10) - distantToMoveUp - 20;
  const transform = `translate3d(${values[0]}, ${second}px, ${values[2]})`;

  return { transform, movedUp: distantToMoveUp };
};

const adjustedForTopOffset = (
  svgElement,
  currentTransform,
  isMobile,
  movedUp,
) => {
  const elemRect = svgElement.getBoundingClientRect();

  const distantFromTop = elemRect.top - movedUp;
  let difference = 0;

  const mobileTop = 150;
  const webTop = 60;

  if (isMobile) {
    if (distantFromTop >= mobileTop) {
      return currentTransform;
    }
    difference = mobileTop - distantFromTop;
  } else {
    if (distantFromTop >= webTop) {
      return currentTransform;
    }
    difference = webTop - distantFromTop;
  }

  const values = getTranslate3d(currentTransform);
  const second = parseInt(values[1], 10) + difference;

  const transform = `translate3d(${values[0]}, ${second}px, ${values[2]})`;

  return transform;
};

const elementExists = id => document.getElementById(`layer-${id}`);
const clickedOutsideTiles = e =>
  e.target.classList.contains('leaflet-tile') ||
  e.target.classList.contains('close-button-lg');

const drawSvg = element => {
  const { tile_size: size, searchable } = element._source;
  const [width, height] = size;

  const svgElement = document.createElementNS(SVG, 'svg');

  svgElement.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
  svgElement.setAttribute('viewBox', `0 0 ${width} ${height}`);
  svgElement.setAttribute('width', width);
  svgElement.setAttribute('height', height);
  svgElement.setAttribute('data-vieww', width);
  svgElement.setAttribute('data-viewh', height);
  svgElement.setAttribute('data-searchable', searchable);

  return svgElement;
};

const drawForeignObject = element => {
  // const map = getMap(lmap);
  // const contentZoom = parseInt(map._zoom, 10);

  const contentZoom = 4;

  const { id, tile_size: size, zoom_content: zoomContent } = element._source;
  const tileContent = zoomContent[contentZoom];

  const [width, height] = size;

  const foreignObject = document.createElementNS(SVG, 'foreignObject');

  foreignObject.setAttribute('id', `layer-${id}`);
  foreignObject.setAttribute('x', 0);
  foreignObject.setAttribute('y', 0);
  foreignObject.setAttribute('width', width);
  foreignObject.setAttribute('height', height);
  foreignObject.innerHTML = tileContent;

  return foreignObject;
};

const updateTileContent = (id, content) => {
  const foreignObject = document.getElementById(`layer-${id}`);

  foreignObject.innerHTML = content;
};

const openElement = (element, isMobile) => {
  const {
    id,
    zoom_content: zoomContent,
    aux_content: content,
  } = element._source;

  if (isEmptyValue(content)) {
    return;
  }

  const contentZoom = 4;
  const tileContent = zoomContent[contentZoom];

  const svgElement = document.getElementById(`layer-${id}`).parentNode;
  const foreignObject = document.getElementById(`layer-${id}`);
  const originalTransform = svgElement.style.transform;
  let currentTransform = originalTransform;
  const height = promoHeight(content);

  svgElement.setAttribute('viewBox', `0 0 ${PROMO_WIDTH} ${height}`);
  if (hasEmbed(content)) {
    svgElement.classList.add('embed');
  }
  svgElement.classList.add('expanded');
  svgElement.style.zIndex = '400';
  foreignObject.setAttribute('x', 0);
  foreignObject.setAttribute('y', 0);
  svgElement.setAttribute('data-width', svgElement.style.width);
  svgElement.setAttribute('data-height', svgElement.style.height);
  svgElement.setAttribute('data-content', tileContent);
  svgElement.setAttribute('data-transform', originalTransform);
  foreignObject.setAttribute('width', PROMO_WIDTH);
  foreignObject.setAttribute('height', height);

  if (isMobile) {
    currentTransform = adjustedForRightOffsetForMobile(
      svgElement,
      currentTransform,
    );
  } else {
    currentTransform = adjustedForRightOffset(svgElement, currentTransform);
  }

  const { transform, movedUp } = adjustedForBottomOffset(
    svgElement,
    currentTransform,
    height,
  );

  currentTransform = transform;

  currentTransform = adjustedForTopOffset(
    svgElement,
    currentTransform,
    isMobile,
    movedUp,
  );

  svgElement.style.transform = currentTransform;

  svgElement.setAttribute('width', PROMO_WIDTH);
  svgElement.setAttribute('height', promoHeight(content));

  foreignObject.innerHTML = content;
  svgElement.style.width = `${PROMO_WIDTH}px`;
  svgElement.style.height = `${promoHeight(content)}px`;
  setTimeout(() => refreshFsLightbox(), 500);
};

const openElementIfExists = (elements, currentUserId, isMobile) => {
  const selectId = getSelectId();
  const element = findById(selectId)(elements);

  if (element) {
    openElement(element, isMobile);

    const { contact_id: contactId } = element._source;

    // universal analytics commented out. To be replaced with GA4 tags at a later time.
    
    // dimension1 = UserId, dimension2 = ContactId
    // ga('send', 'event', 'Looking Glass', 'TileClick', {
    //   dimension1: currentUserId,
    //   dimension2: contactId,
    // });
  }
};

const fadeElements = visibleElementIds => {
  const allSearchable = document.querySelectorAll(
    '[data-searchable="true"] foreignObject',
  );

  [].forEach.call(allSearchable, foreignObject => {
    const id = splitLayerId(foreignObject.id);

    if (notIncludedInArray(id, visibleElementIds)) {
      foreignObject.style.opacity = '0.2';
    } else {
      foreignObject.style.opacity = '1.0';
    }
  });
};

const collapseAllExpanded = (changeSize = true) => {
  const expandedElements = document.getElementsByClassName('expanded');

  [].forEach.call(expandedElements, svgElement => {
    const foreignObject = svgElement.children[0];

    const originalHeight = svgElement.dataset.viewh;
    const originalWidth = svgElement.dataset.vieww;

    if (changeSize) {
      svgElement.style.width = svgElement.dataset.width;
      svgElement.style.height = svgElement.dataset.height;
      svgElement.style.transform = svgElement.dataset.transform;
    }

    svgElement.setAttribute(
      'viewBox',
      `0 0 ${originalWidth} ${originalHeight}`,
    );
    foreignObject.setAttribute('x', 0);
    foreignObject.setAttribute('y', 0);
    foreignObject.setAttribute('width', originalWidth);
    foreignObject.setAttribute('height', originalHeight);
    svgElement.classList.remove('expanded');
    svgElement.classList.remove('embed');
    foreignObject.innerHTML = svgElement.dataset.content;
    svgElement.style.zIndex = '200';
  });
};

const removeAllTiles = lmap => {
  const map = getMap(lmap);

  map.eachLayer(thisLayer => {
    if (thisLayer._url.nodeName) {
      const { searchable } = thisLayer._url.dataset;
      if (equals(searchable, 'true')) {
        map.removeLayer(thisLayer);
      }
    }
  });
};

const updateZoomContent = (elements, lmap) => {
  const map = getMap(lmap);
  const contentZoom = parseInt(map._zoom, 10);

  // eslint-disable-next-line array-callback-return
  elements.map(element => {
    const { id, searchable, zoom_content: zoomContent } = element._source;
    const tileContent = zoomContent[contentZoom];

    if (!searchable) {
      return;
    }

    updateTileContent(id, tileContent);
  });
};

const addElementsToMap = (elements, lmap) => {
  const map = getMap(lmap);

  // eslint-disable-next-line array-callback-return
  elements.map(element => {
    const { xy, id } = element._source;

    if (elementExists(id)) {
      return;
    }

    const svgElement = drawSvg(element);
    const foreignObject = drawForeignObject(element);

    svgElement.appendChild(foreignObject);

    // xy[0] top left, xy[2] bottom right
    L.svgOverlay(svgElement, [
      unproject(xy[0], lmap),
      unproject(xy[2], lmap),
    ]).addTo(map);
  });
};

const shouldUpdateZoomContent = (zoom, z) => {
  if (notEquals(parseInt(zoom, 10), parseInt(z, 10))) {
    return true;
  }

  return false;
};

const isZooming = (zoom, z) => {
  if (notEquals(parseFloat(zoom, 10), parseFloat(z, 10))) {
    return true;
  }

  return false;
};

const openPopup = (element, lmap, isMobile) => {
  const map = getMap(lmap);

  const { leafletElement: el } = lmap.current;

  const { xy } = element._source;

  el.setView(unproject(xy[0], lmap), map._zoom);
  collapseAllExpanded();
  setTimeout(() => openElement(element, isMobile), 500);
};

const formatSearchForDisplay = (search, category) => {
  if (category) {
    return '';
  }

  return search;
};

export {
  addElementsToMap,
  clickedOutsideTiles,
  fadeElements,
  isZooming,
  openPopup,
  openElementIfExists,
  openElement,
  removeAllTiles,
  collapseAllExpanded,
  formatSearchForDisplay,
  shouldUpdateZoomContent,
  updateZoomContent,
};
