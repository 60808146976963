const INITIAL_C = 17; // convention
const INITIAL_Z = 4; // zoom

const ZOOM_DELTA = 0.25;
const MIN_ZOOM = 2;
const MAX_ZOOM = 6;
const MAX_XY = 32768;
const CENTER_XY = 16384;
const UNPROJECT_Z = 7;
const PROMO_WIDTH = 800;
const TILE_VERSION = 'v1';
const SVG = 'http://www.w3.org/2000/svg';

export {
  CENTER_XY,
  INITIAL_C,
  INITIAL_Z,
  MIN_ZOOM,
  MAX_ZOOM,
  MAX_XY,
  PROMO_WIDTH,
  SVG,
  TILE_VERSION,
  UNPROJECT_Z,
  ZOOM_DELTA,
};
