import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SetAsDefaultLink from './SetAsDefaultLink';
import DeactivateLink from './DeactivateLink';

class PaymentMethodTableRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showStateColumn: props.showStateColumn,
      showDetailsLink: props.showDetailsLink,
      isHovered: false,
    };

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  handleMouseEnter() {
    this.setState({
      isHovered: true,
    });
  }

  handleMouseLeave() {
    this.setState({
      isHovered: false,
    });
  }

  render() {
    const { isHovered, showDetailsLink, showStateColumn } = this.state;
    const {
      paymentMethod,
      handleSetDefault,
      handleDeactivate,
      contactId,
    } = this.props;
    const cardName = `${this.props.paymentMethod.card_type} x${this.props.paymentMethod.card_digits}`;
    const cardDefault = this.props.paymentMethod.default_method == true;
    const active = this.props.paymentMethod.state == 'active';

    return (
      <tr
        onMouseEnter={() => this.handleMouseEnter(true)}
        onMouseLeave={() => this.handleMouseLeave(false)}
      >
        <td>
          {cardDefault ? (
            <i className="fa fa-check"></i>
          ) : (
            active && isHovered && (
              <SetAsDefaultLink
                paymentMethod={paymentMethod}
                handleSetDefault={handleSetDefault}
                tooltipKey="1"
                message="Make default payment method"
              />
            )
          )}
        </td>
        <td>
          {showDetailsLink ? (
            <Link
              to={`/contacts/${contactId}/payment_methods/${paymentMethod.id}`}
            >
              {cardName}
            </Link>
          ) : (
            cardName
          )}
        </td>
        <td>{paymentMethod.cardholder_name}</td>
        <td>{moment(paymentMethod.card_expiry).format('MM/YYYY')}</td>
        {showStateColumn && <td>{paymentMethod.state}</td>}
        <td>
          {paymentMethod.state == 'active' && (
            <DeactivateLink
              paymentMethod={paymentMethod}
              handleDeactivate={handleDeactivate}
              showStateColumn={showStateColumn}
            />
          )}
        </td>
      </tr>
    );
  }
}

export default PaymentMethodTableRow;
