import React from 'react';
import PropTypes from 'prop-types';
import InfoTooltip from '../../helpers/InfoTooltip';
import { Link } from 'react-router-dom';

class SatelliteConventionAttendeeList extends React.Component {
  renderSatelliteConventionAttendees() {
    const { satelliteConventionAttendees } = this.props;
    const { onStatusChange } = this.props;
    const { admin } = this.props;
    const { satelliteConvention } = this.props;

    if (satelliteConventionAttendees === null) return null;

    return satelliteConventionAttendees.map(satelliteConventionAttendee => (
      <tr key={satelliteConventionAttendee.id}>
        <td>
          {admin ? (
            <a href={`/profile/${satelliteConventionAttendee.contact_id}`}>
              {satelliteConventionAttendee.contact.name}
            </a>
          ) : (
            satelliteConventionAttendee.contact.name
          )}
        </td>
        <td>
          {satelliteConventionAttendee.status === 'pending' ? (
            <span>
              {satelliteConvention.attendee_check_in_open ? (
                <Link
                  to="#"
                  onClick={event =>
                    onStatusChange(satelliteConventionAttendee, event)
                  }
                >
                  Pending
                </Link>
              ) : (
                'Pending'
              )}
              &nbsp;
              <span className="info">
                <InfoTooltip
                  tooltipKey="1"
                  message="Click to mark as Attended"
                />
              </span>
            </span>
          ) : (
            satelliteConventionAttendee.status.charAt(0).toUpperCase() +
            satelliteConventionAttendee.status.slice(1)
          )}
        </td>
        <td>{satelliteConventionAttendee.attendance_type}</td>
        <td>{satelliteConventionAttendee.purchase_date}</td>
      </tr>
    ));
  }

  render() {
    const { satelliteConvention } = this.props;
    return (
      <div>
        <div className="page-title">Satellite Convention Attendees</div>
        <div className="panel">
          <div className="ribbon-top">
            <ul className="left">
              {satelliteConvention.attendee_check_in_open ? (
                <li className="btn">
                  <Link
                    to={`/satellite_conventions/${satelliteConvention.id}/satellite_convention_attendees/new`}
                  >
                    <i className="fa fa-plus-circle fa-2x" />
                    <label>Register Walk-In</label>
                  </Link>
                </li>
              ) : null}
              <li className="btn">
                <a
                  href={`/satellite_conventions/${satelliteConvention.id}/satellite_convention_attendees/export`}
                >
                  <i className="fa fa-plus-circle fa-2x" />
                  <label>Export</label>
                </a>
              </li>
            </ul>
          </div>

          <table className="records" cellSpacing="0" cellPadding="0">
            <thead>
              <tr>
                <th>Name</th>
                <th>Attended</th>
                <th>Attendance Type (Pre-Reg/Walk-in)</th>
                <th>Purchase Date</th>
              </tr>
            </thead>
            <tbody>{this.renderSatelliteConventionAttendees()}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

SatelliteConventionAttendeeList.propTypes = {
  satelliteConvention: PropTypes.shape().isRequired,
  satelliteConventionAttendees: PropTypes.arrayOf(PropTypes.object),
  onStatusChange: PropTypes.func.isRequired,
  admin: PropTypes.bool.isRequired,
};

SatelliteConventionAttendeeList.defaultProps = {
  satelliteConventionAttendees: [],
};

export default SatelliteConventionAttendeeList;
