import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loading from '../eventFinder/EventFinder/Loading';
import TicketsTable from './TicketsTable';
import autoload from '../../helpers/autoload';

const Tickets = ({ eventId, freeEvent, eTicketed, eventPrice }) => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ticketsCount, setTicketsCount] = useState(0);
  const [pageCount, setPageCount] = useState(null);
  const [ticketsAvailable, setTicketsAvailable] = useState(0);
  const [ticketsSold, setTicketsSold] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const getTicketStatus = async () => {
    try {
      const url = `/api/v2/events/${eventId}/tickets/status`;
      const res = await axios.get(url);
      setTicketsAvailable(res.data.data.tickets_available);
      setTicketsSold(res.data.data.total_tickets_sold);
    } catch (error) {
      console.error("Error fetching ticket status:", error);
    }
  };

  const getTickets = async (page) => {
    try {
      const url = `/api/v2/events/${eventId}/tickets?page=${page}`;
      if (!loading) {
        setLoading(true);
        const res = await axios.get(url);
        const newPageCount = Math.ceil(res.data.meta.total_num_of_items / res.data.meta.page_size);
        setTickets((prevTickets) => [...prevTickets, ...res.data.data.tickets]);
        setTicketsCount(res.data.meta.total_num_of_items);
        setPageCount(newPageCount);
        setCurrentPage(page);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching tickets:", error);
    }
  };

  const fetchNextPage = () => {
    if (currentPage < pageCount) {
      getTickets(currentPage + 1);
    }
  };

  useEffect(() => {
    getTicketStatus();
    getTickets(1);

    const statusInterval = setInterval(() => {
      getTicketStatus();
    }, 20000);

    return () => clearInterval(statusInterval);
  }, []);

  return (
    <div id="tickets-table" className="row">
      {loading && tickets.length === 0 ? (
        <Loading />
      ) : (
        <div className="small-12 columns">
          <div className="panel">
            <div className="ribbon-top">
              <div className="left">
                <div className="panel_titlebar">Tickets</div>
              </div>
              <div className="right tickets-count">
                <div>
                  Sold: <strong>{ticketsSold}</strong>
                </div>
                <div>
                  Available: <strong>{ticketsAvailable}</strong>
                </div>
              </div>
            </div>
            <TicketsTable
              renderStats={eTicketed}
              tickets={tickets}
              fetchNextPage={fetchNextPage}
              hasMore={currentPage < pageCount}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default autoload(Tickets, 'tickets');
