import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  eventDisplayTime,
  categoryUri,
  gameSystemDisplay,
  ticketsCount,
  ticketIconDisplay,
} from '../../helpers/displayHelpers';

/* eslint-disable camelcase */
const ScheduleRow = ({ event, even }) => {
  const { event_id, title } = event;

  const resultRowClasses = classNames('ticket_in_cart', 'schedule-item', {
    even,
    odd: !even,
  });

  const currentTime = new Date();

  const hasSoldOutClass = {
    event_row_sold_out: !(
      event.tickets_available > 0 || event.max_players_unlimited
    ),
  };

  const fullLocation = () => {
    let location = ""
    if (event.location) { location += event.location; }
    if (event.room_name) { location += (" : " + event.room_name); }
    if (event.table_number) { location += (" : " + event.table_number);}
    return location;
  }

  const hasConcludedClass = {
    event_row_concluded: Date.parse(event.end_date) < currentTime,
  };

  const hasCanceledClass = {
    event_row_canceled: event.event_status === 'CN-Canceled',
  };

  const rowClasses = classNames(
    resultRowClasses,
    hasSoldOutClass,
    hasConcludedClass,
    hasCanceledClass,
  );

  return (
    <a href={`/events/${event_id}`}>
      <div className={rowClasses}>
      
        <div className="row show-for-small">
          <div className="event-title">
            <div>{title}</div>
          </div>
          <div className="subtitle">
            {gameSystemDisplay(event)}
          </div>
        </div>
        <div className="event-icon text-center">
          <img
            alt="icon"
            border={0}
            height={40}
            src={categoryUri(event)}
            width={40}
          />
        </div>
        <div className="event-detail hide-for-small">
          <div className="event-title">
            <div>{title}</div>
          </div>
          <div className="subtitle">{gameSystemDisplay(event)}</div>
        </div>
        <div className="event-time-location">
          <div className="tl-icon">
            <i className="fa fa-clock-o"></i>
          </div>
          <div className="tl-text">{eventDisplayTime(event)}</div>
          <hr style={{ margin: 0 }} />
          <div className="tl-icon">
            <i className="fa fa-map-marker"></i>
          </div>
          <div className="tl-text">{fullLocation()}</div>
        </div>
        <div className="event-tickets">
          {ticketsCount(event)}
          <div className="ticket-type-icon">{ticketIconDisplay(event)}</div>
        </div>
      

      </div>
    </a>
  );
};

export default ScheduleRow;

ScheduleRow.propTypes = {
  event: PropTypes.shape().isRequired,
  even: PropTypes.bool.isRequired,
};
