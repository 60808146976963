import React, { Component } from 'react'
import PropTypes from 'prop-types'


class SearchQuery extends Component {
  static displayName = 'SearchQuery';

  constructor(props) {
    super(props);

    this.state = {
      searchText: ''
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
  }

  handleInputChange(event) {
    this.props.onChange(event.target.value);
    this.setState({searchText: event.target.value})
  }

  handleOnSubmit(event) {
    event.preventDefault()

    this.props.onChange(this.refs.searchValue.value);
    this.setState({searchText: this.refs.searchValue.value})
  }

  render() {
    let searchTerm = this.props.defaultQuery ? this.props.defaultQuery.replace('%26', '&').replace('%23', '#') : this.props.defaultQuery

    return (
      <div className="search-area">
        <form onSubmit={this.handleOnSubmit} role="search" className="form_menu">
          <input
            type="text"
            name="search"
            id="search"
            ref="searchValue"
            placeholder="Search for keywords or by Event ID"
            className="event-finder-search"
            onChange={this.handleInputChange}
            defaultValue={searchTerm} />
        </form>
      </div>
    );
  }

}

export default SearchQuery
