import React from 'react';

const TASK_TO_CONTENT_KEY = {
  'training-overview': 'web1.1',
  'preparing-for-training': 'web2.1',
  'select-interface': 'web3.1',
  'using-scan-interface': 'task3.1',
  'redeeming-tickets': 'task4.1',
  'redeeming-guest-tickets': 'task5.1',
  'dealing-with-problems': 'task7.1',
  'redeeming-more-guest-tickets': 'task8.1',
  'sell-comp-ticket': 'task6.1',
  'scan-all-events': 'task9.1',
  'sandbox': 'sandbox',
}

const findNextTaskKey = (trainingProgress) => {
  // Find the first incomplete task
  const nextTask = trainingProgress.find(task => !task.completed && task.optional === false);
  return nextTask ? nextTask.key : null;
}


const TasksChecklist = ({ trainingProgress, contentKey, setContentKey, checklistOpen, setChecklistOpen, isSmallScreen }) => {
  const totalTasks = trainingProgress.filter(task => task.optional === false).length;
  const completedTasks = trainingProgress.filter(task => task.optional === false && task.completed).length;
  const nextTaskKey = findNextTaskKey(trainingProgress);

  const progress = Math.round((completedTasks / totalTasks)* 100);

  const closeChecklistIfOpen = () => {
    if (checklistOpen) {
      setChecklistOpen(false);
    }
  }

  const handleItemClick = (taskKey) => {
    setContentKey(TASK_TO_CONTENT_KEY[taskKey]);
    closeChecklistIfOpen()
  }


  const createItemLink = (task) => {
    const isNextTask = task.key === nextTaskKey;

    switch (task.key) {
      case 'select-interface':
        return <div key={task.key}></div>;

      case 'submit-cant-scan-report':
        return (
          <li key={task.key} className='list-item item-disabled'>
            <div className="list-left-side">
              <CheckmarkOrSpacer completed={task.completed} />
              <s><a className={'tab-link-disabled'}>Submit Can't Scan Report (Mobile App only)</a></s>
            </div>
            <div className="list-right-side" />
          </li>
        );

      default:
        const currentKey = TASK_TO_CONTENT_KEY[task.key]
        const isCurrentTask = contentKey.split('.')[0] === currentKey.split('.')[0]

        return (
          <ChecklistRow
            key={task.key}
            taskKey={task.key}
            isCurrentTask={isCurrentTask}
            handleClick={handleItemClick}
            title={task.title}
            completed={task.completed}
            isNextTask={isNextTask}
          />
        );
    }
  }

  const fullList = () => {
    return (
      <div className='panel checklist-panel' style={{backgroundColor: '#fff'}}>
        <div className='progress-container'>
          {!checklistOpen && 
            <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
              <h4>Training Progress</h4>
            </div>
          }

          <div className='progress-bar'>
            {(isSmallScreen || checklistOpen) &&
              <button onClick={() => setChecklistOpen(!checklistOpen)}>
                <i className='fa-2x fa-list'></i>
              </button>
            }
            <progress value={isNaN(progress) ? 0 : progress} max="100" />
            <span>{isNaN(progress) ? 0 : progress}%</span>
          </div>
          <ul className="task-list">
            {trainingProgress.map((task) => (
              createItemLink(task)
            ))}
            <li className={progress >= 100 ? 'list-item hoverable' : 'list-item item-disabled'}>
              <div className='list-left-side'>
                <CheckmarkOrSpacer completed={progress >= 100} />
                <a className={progress >= 100 ? 'tab-link' : 'tab-link-disabled'}>Training Complete!</a>
              </div>
            </li>
            {progress >= 100 &&
              <li key={'sandbox'} className={contentKey === 'sandbox' ? 'list-item hoverable current-task' : 'list-item hoverable'} onClick={() => { setContentKey('sandbox'); closeChecklistIfOpen() }}>
                <div className='list-left-side'>
                  <CheckmarkOrSpacer completed={false} />
                  <a className="tab-link">{'Explore the Sandbox . . .'}</a>
                </div>
              </li>
            }
          </ul>
        </div>
      </div>
    )
  };

  const smallScreenList = () => {
    return (
      <div className='panel checklist-panel'>
        <div className='progress-container'>
          <div className='progress-bar'>
            <button onClick={() => setChecklistOpen(!checklistOpen)}>
              <i className='fa-2x fa-list'></i>
            </button>
            <progress value={isNaN(progress) ? 0 : progress} max="100" />
            <span>{isNaN(progress) ? 0 : progress}%</span>
          </div>
        </div>
      </div>
    )
  }

  return (
    (isSmallScreen && !checklistOpen) ?
      smallScreenList()
      :
      fullList()
  );
};

const ChecklistRow = ({ isCurrentTask, isNextTask, title, taskKey, completed, handleClick }) => {
  const taskDisabled = !completed && (!isCurrentTask && !isNextTask);
  const onClick = () => {
    if (taskDisabled) {
      return
    }
    handleClick(taskKey);
  }

  return (
    <li
      className={taskDisabled ? 'list-item item-disabled' : 'list-item hoverable'}
      onClick={onClick}
    >
      {/* Left */}
      <div className='list-left-side'>
        <CheckmarkOrSpacer completed={completed} />
        {/* Center -title */}
        <a className={taskDisabled ? "tab-link-disabled" : "tab-link"}>{title}</a>
      </div>
      {/* Right - arrow or spacer*/}
      <div className='list-right-side'>
        {isCurrentTask ? (
          <svg className="arrow-icon" width="10" height="15" viewBox="0 0 10 15" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.65951 8.29663L3.07953 14.6696C2.62473 15.1101 1.88932 15.1101 1.43937 14.6696L0.345931 13.6106C-0.108861 13.1701 -0.108861 12.4578 0.345931 12.022L5.00514 7.5L0.341094 2.98266C-0.113699 2.54217 -0.113699 1.8299 0.341094 1.3941L1.43453 0.330366C1.88933 -0.110121 2.62474 -0.110121 3.07469 0.330366L9.65467 6.70337C10.1143 7.14386 10.1143 7.85614 9.65951 8.29663Z" />
          </svg>
        ) : (
          <div style={{ width: "10px", height: "15px" }} />
        )}
      </div>
    </li>
  )
}

const CheckmarkOrSpacer = ({ completed }) => {
  if (completed) {
    return (
      <div className='li-checkbox-wrapper'>
        <svg width="26" height="19" viewBox="0 0 26 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M25.1096 3.84375C25.1096 4.26042 24.9636 4.61442 24.6716 4.90575L11.2336 18.3438C10.9416 18.6357 10.5876 18.7817 10.1716 18.7817C9.75562 18.7817 9.40162 18.6357 9.10962 18.3438L1.32863 10.5627C1.03663 10.2708 0.890625 9.91675 0.890625 9.50075C0.890625 9.08475 1.03663 8.73075 1.32863 8.43875L3.45363 6.31375C3.74563 6.02175 4.09963 5.87575 4.51562 5.87575C4.93162 5.87575 5.28563 6.02175 5.57763 6.31375L10.1716 10.9228L20.4216 0.65675C20.7136 0.36475 21.0676 0.21875 21.4836 0.21875C21.8996 0.21875 22.2536 0.36475 22.5456 0.65675L24.6706 2.78175C24.9626 3.07308 25.1086 3.42708 25.1086 3.84375H25.1096Z" fill="#00833E" />
        </svg>
      </div>
    )
  }
  return (
    <div className='li-checkbox-wrapper'>
      <div style={{ width: "26px", height: "19px" }} />
    </div>
  )
}

export default TasksChecklist;
