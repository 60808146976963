import React, { useState } from 'react';
import classNames from 'classnames';

const SearchFilterCheckboxes = ({ selectedGroup, groupSelected, searchFilter, closeModal }) => {
  const totalCount = selectedGroup.filtered_count 
    ? selectedGroup.filtered_count.reduce((total, group) => total + group.doc_count, 0)
    : 0;

  const [groupSelectedState] = useState(groupSelected);
  const [searchFilterState, setSearchFilterState] = useState(searchFilter);


  const checkboxOnChange = (selected) => {
    let newGroup;
    if (isGroupChecked(String(selected))) {
      newGroup = searchFilterState[groupSelectedState].filter(item => item !== String(selected));
    } else {
      newGroup = [...searchFilterState[groupSelectedState], String(selected)];
    }
    setSearchFilterState(prevState => ({
      ...prevState,
      [groupSelectedState]: newGroup.sort(), // Sort the array
    }));
  }

  const handleModalClose = () => {
    closeModal(searchFilterState);
  }

  const handleSelectAllClick = () => {
    setSearchFilterState(prevState => ({
      ...prevState,
      [groupSelectedState]: [],
    }));
  }

  const isGroupChecked = (key) => {
    return searchFilterState[groupSelectedState].includes(String(key));
  }

  const printGroup = (key) => {
    return key; // Modify this logic as per your requirements
  }

  let modalTitle = groupSelectedState[0].toUpperCase() + groupSelectedState.slice(1);


  return (
    <div>
      {/* Render modal title */}
      <div className="event-finder-modal-title">
        <h2 style={{ color: '#000', fontSize: '22px', textAlign: 'center', marginTop: '15px' }}>{modalTitle}</h2>
      </div>

      {/* Render checkboxes */}
      <div className="modal-filters">
        <div
          className={classNames({
            'modal-filter-checkbox select-all': true,
            checked: searchFilter.length === 0,
          })}
          onClick={handleSelectAllClick}
        >
          Select All
          <div className="cat-quantity">{totalCount}</div>
        </div>

        {/* Map over selectedGroup and render checkboxes */}
        {selectedGroup && selectedGroup.filtered_count && selectedGroup.filtered_count.map((group, i) => (
          <div
            key={i}
            className={classNames({
              'modal-filter-checkbox': true,
              checked: isGroupChecked(group.key),
            })}
            onClick={() => checkboxOnChange(group.key)}
          >
            {printGroup(group.key)}
            <div className="cat-quantity">{group.doc_count}</div>
          </div>
        ))}
      </div>

      {/* Render close button */}
      <div className="event-filter-close">
        <button onClick={handleModalClose} className="button">OK</button>
      </div>
    </div>
  );
}

export default SearchFilterCheckboxes;
