import React from 'react';

const Modal = ({ title, content, buttonText, onClick, altButtonText, altOnClick }) => {

  return (
  <div className="scanning-modal">
    <div className="scanning-modal-header">
      <h4>{title}</h4>
    </div>
    <div className="scanning-modal-content">
      {content}
      <div className="banner-button-group">
      {buttonText && <div className='button' style={{border: '1px solid white'}} onClick={onClick}>{buttonText}</div>}
      {altButtonText && <div className='button' style={{border: '1px solid white'}} onClick={altOnClick}>{altButtonText}</div>}
      </div>
    </div>
  </div>
  );
};

export default Modal;
