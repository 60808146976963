import React, { Component } from 'react';
import { equals } from 'ramda';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TicketRow from './TicketRow';
import TicketStats from './TicketStats';

class TicketsTable extends Component {
  static displayName = 'TicketsTable';

  static getDerivedStateFromProps(nextProps, prevState) {
    let nextState = prevState;
    if (!equals(nextProps.tickets, prevState.visibleTickets)) {
      nextState.visibleTickets = nextProps.tickets;
    }
    return nextState;
  }

  constructor(props) {
    super(props);

    this.state = {
      filter: null
    }

    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  groupTickets(tickets) {
    const contacts = [];
    tickets.forEach((ticket) => {
      const exist = contacts.find(obj => obj.contact_id === ticket.contact_id);
      if (exist) {
        exist.tickets.push(ticket);
        exist.status = this.getStatus(exist.tickets);
      } else {

        contacts.push({
          contact_id: ticket.contact_id,
          name: ticket.contact_name,
          pronoun: ticket.contact_pronoun,
          tickets: [ticket],
          pending: ticket.status == 'pending',
          status: ticket.redeemed ? "redeemed" : "unredeemed",
          error: ticket.error,
          failed: ticket.failed
        });
      }
    });
    return contacts;
  }

  filterRedeemed(tickets) {
    return tickets.filter((ticket) => ticket.redeemed)
  }

  filterUnredeemed(tickets) {
    return tickets.filter((ticket) => !ticket.redeemed)
  }

  getStatus(tickets) {
    let redeemedCount = this.filterRedeemed(tickets).length;
    let unredeemedCount = this.filterUnredeemed(tickets).length;
    if (redeemedCount == 0) {
      return "unredeemed";
    }
    if (unredeemedCount == 0) {
      return "redeemed";
    }
    if (redeemedCount > 0 && unredeemedCount > 0) {
      return "guests";
    }
  }

  sortByStatusAndName(array) {
    let sortedArray = array.sort(function (a, b) {
      if (a.status !== b.status) {
        let order = { unredeemed: 1, guests: 2, failed: 3, redeemed: 4 };
        return (order[a.status] || 0) - (order[b.status] || 0);
      }
      return a.name > b.name ? 1 : -1
    });
    return sortedArray;
  }

  handleButtonClick(filter, event) {
    event.preventDefault();
    if (this.state.filter == filter) {
      this.setState({ filter: null })
    } else {
      this.setState({ filter })
    }
  }

  applyFilter(tickets) {
    const filterProperty = this.state.filter;
    if (filterProperty) {
      if (filterProperty == "redeemed") {
        return tickets.filter(ticket => ticket.status === filterProperty || ticket.status === "guests")
      }
      return tickets.filter(ticket => ticket.status === filterProperty);
    }
    return tickets;
  }

  render() {
    const renderedContacts = [];

    let contacts = this.groupTickets(this.props.tickets);
    let filteredContacts = this.applyFilter(contacts);

    this.sortByStatusAndName(filteredContacts).map((contact, i) => {
      renderedContacts.push(
        <TicketRow key={i}
          contact={contact}
          even={(i == 0 || i % 2 == 0)}
        />
      );
    });

    return (
      <div className="tickets">
        {this.props.renderStats &&
          <TicketStats
            tickets={contacts}
            handleButtonClick={this.handleButtonClick}
            currentFilter={this.state.filter}
          />
        }
        <table className="records">
          <tbody>
            <tr>
              <th>Ticket Holder</th>
              <th className="text-right">Tickets / Status</th>
            </tr>
            {renderedContacts}
          </tbody>
        </table>
      </div>
    );
  }
}

export default TicketsTable;
