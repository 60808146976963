import {
  __,
  any,
  compose,
  complement,
  filter,
  find,
  findIndex,
  lensPath,
  includes,
  insert,
  isEmpty,
  isNil,
  map,
  not,
  prop,
  propEq,
  remove,
  reject,
  set,
  where,
} from 'ramda';
import moment from 'moment';

const isMatchingId = (id, allIds) => includes(__, allIds)(id);
const findUserById = id => where({ creator: propEq('id', id) });
const mutedLens = lensPath(['creator', 'muted']);
const filterIds = map(prop('id'));
const filterOutDeletedMessages = filter(propEq('deleted_at', null));
const filteredOutMessage = message => reject(propEq('id', message.id));
const notEmpty = complement(isEmpty);
const setMuted = set(mutedLens);
const existsInMessages = id => compose(not, isNil, find(propEq('id', id)));
const updateMessage = (message, messages) => {
  const currentIndex = findIndex(propEq('id', message.id))(messages);
  let messagesToSave = remove(currentIndex, 1, messages);
  messagesToSave = insert(currentIndex, message, messagesToSave);

  return messagesToSave;
};

const getUpdatedMessages = (currentMessages, recentMessages) => {
  let messagesToSave = [...currentMessages];
  const allIds = filterIds(messagesToSave);
  recentMessages.reverse().forEach(recentMessage => {
    if (isMatchingId(recentMessage.id, allIds)) {
      messagesToSave = updateMessage(recentMessage, messagesToSave);
    }else if (recentMessage.deleted_at == null) {
      messagesToSave.unshift(recentMessage);
    }
  });

  return messagesToSave;
};

export {
  existsInMessages,
  findUserById,
  filterIds,
  getUpdatedMessages,
  filterOutDeletedMessages,
  filteredOutMessage,
  isMatchingId,
  notEmpty,
  setMuted,
  updateMessage,
};
