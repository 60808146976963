import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { modalStyles, filterToArray } from '../../../helpers/displayHelpers';
import { removeEmptyString } from '../../../helpers/searchHelpers';
import ScheduleGroupSelector from './ScheduleGroupSelector';
import ScheduleMetadataSelector from './ScheduleMetadataSelector';
import ScheduleFilterBadge from './ScheduleFilterBadge';
import { fetchMetadata } from '../helpers/api';
import Loading from '../../eventFinder/EventFinder/Loading';

const ScheduleFilter = ({
  removeFilter,
  addFilters,
  filters,
  contactId,
  c,
  enabled,
}) => {
  const [loading, setLoading] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    isModalOpen: false,
    metaData: {},
    ogMetaData: {},
    isGroupSectorOpen: true,
    isMetaDataOpen: false,
    loading: false,
    filterSelected: '',
  });

  const getMetadata = async filter => {
    setLoading(true);

    const emptyFilter = {};
    const res = await fetchMetadata({ contactId, filters, filter, c });
    const res2 = await fetchMetadata({ contactId, emptyFilter, filter, c });

    const combinedFilters = {
      ...filterOptions,
      ...{
        metaData: removeEmptyString(res.data),
        ogMetaData: removeEmptyString(res2.data),
        isGroupSectorOpen: false,
        isMetaDataOpen: true,
        filterSelected: filter,
      },
    };

    setLoading(false);
    setFilterOptions(combinedFilters);
  };

  const closeModal = () => {
    const combinedFilters = {
      ...filterOptions,
      ...{
        metaData: {},
        ogMetaData: {},
        isModalOpen: false,
        isGroupSectorOpen: true,
        isMetaDataOpen: false,
        filterSelected: '',
      },
    };

    setFilterOptions(combinedFilters);
  };

  const openModal = () => {
    setFilterOptions({ ...filterOptions, ...{ isModalOpen: true } });
  };

  const {
    isModalOpen,
    isGroupSectorOpen,
    isMetaDataOpen,
    metaData,
    ogMetaData,
    filterSelected,
  } = filterOptions;

  return (
    <div className="finder-filters">
      <div className="finder-filter-link">
        {filterToArray(filters).map(currentFilter => {
          const { name, filter } = currentFilter;

          return (
            <ScheduleFilterBadge
              key={`${name}-${filter}`}
              filter={filter}
              name={name}
              removeFilter={removeFilter}
            />
          );
        })}
      </div>
      <button
        type="button"
        onClick={openModal}
        className={`search-filter button ${!enabled ? 'disabled' : ''}`}
        title="Filter Search"
        disabled={!enabled}
      >
        <i className="fa fa-filter fa-2x"></i>
      </button>
      <Modal
        isOpen={isModalOpen}
        style={modalStyles}
        onRequestClose={closeModal}
        ariaHideApp={false}
        contentLabel="Filter Selector"
      >
        {!loading && isGroupSectorOpen && (
          <ScheduleGroupSelector
            closeModal={closeModal}
            getMetadata={getMetadata}
            filters={filters}
          />
        )}
        {loading && <Loading />}
        {!loading && isMetaDataOpen && (
          <ScheduleMetadataSelector
            filterSelected={filterSelected}
            filters={filters}
            addFilters={addFilters}
            metaData={metaData}
            ogMetaData={ogMetaData}
            closeModal={closeModal}
          />
        )}
      </Modal>
    </div>
  );
};

export default ScheduleFilter;

ScheduleFilter.propTypes = {
  addFilters: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
  contactId: PropTypes.number.isRequired,
  filters: PropTypes.shape().isRequired,
};
