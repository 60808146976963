import L from 'leaflet';

/* eslint-disable */
(function() {
  if (!L || !L.GridLayer || !L.GridLayer.prototype) return;

  let originalInitTile = L.GridLayer.prototype._initTile;

  L.GridLayer.include({
    _initTile: function(tile) {
      originalInitTile.call(this, tile);

      let tileSize = this.getTileSize();

      tile.style.width = tileSize.x + 1 + 'px';
      tile.style.height = tileSize.y + 1 + 'px';
    },
  });
})();
