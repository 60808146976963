import axios from 'axios';

document.addEventListener('DOMContentLoaded', () => {
  const facilitySelector = document.getElementById('space_plan_facility_id');
  const spaceSelector    = document.getElementById('space_plan_space_id');

  if (facilitySelector && spaceSelector) {
    facilitySelector.addEventListener('change', function(e) {
      const targetPath = `/space_plans/${e.target.value}/spaces_for_facility`;

      axios
        .get(targetPath)
        .then(function(response) {
          spaceSelector.innerHTML = response.data;
        });
    });
  };
});
