import React, { useState, useEffect } from 'react';
import SearchOptions from './ExhibitorSearch/SearchOptions';
import qs from 'qs';
import FinderResults from './ExhibitorSearch/FinderResults';
import autoload from '../../helpers/autoload';

const ExhibitorContainer = ({ convention_id }) => {
  const [searchFilter, setSearchFilter] = useState({ category: [] });
  const [sortOrder, setSortOrder] = useState({ category: [] });
  const [searchQuery, setSearchQuery] = useState('');
  const [isApiReady, setIsApiReady] = useState(false);

  // Parse query parameters from URL on initial render
  useEffect(() => {
    let category = []
    let order = null
    const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    setSearchQuery(queryParams.search || '');

    if (typeof queryParams.category === 'string') {
      category = [queryParams.category];
    } else if (queryParams.category) {
      category = queryParams.category;
    }

    if (queryParams.order) {
      order = queryParams.order;
    }

    setSortOrder(order);
    setSearchFilter({ category });

    setIsApiReady(true);
  }, []);

  const handleSearchQueryChange = search => {
    setSearchQuery(search.replace('&', '%26').replace('#', '%23'));
  };

  useEffect(() => {
    // Extract categories from the searchFilter state
    const categories = searchFilter.category.map(cat => `category=${encodeURIComponent(cat)}`).join('&');

    // Update URL parameters
    let queryString = '';
    if (searchQuery) {
      queryString += `search=${encodeURIComponent(searchQuery)}`;
    }
    if (categories) {
      queryString += (queryString ? '&' : '') + categories;
    }

    if (sortOrder) {
      queryString += (queryString ? '&' : '') + `order=${encodeURIComponent(sortOrder)}`;
    }

    const newURL = queryString === '' ? window.location.pathname : `?${queryString}`;
    window.history.replaceState({}, '', newURL);
  }, [searchQuery, searchFilter, sortOrder]);

  return (
    <div>
      <SearchOptions
        search={searchQuery}
        filters={searchFilter}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        onQueryChange={handleSearchQueryChange}
        setSearchFilter={setSearchFilter}
      />
      <FinderResults
        search={searchQuery}
        conventionId={convention_id}
        searchFilter={searchFilter}
        parentSortOrder={sortOrder}
        setParentSortOrder={setSortOrder}
        isApiReady={isApiReady}
      />
    </div>
  );
};

export default autoload(ExhibitorContainer, 'exhibitor-container');
