import React from 'react';
import EventRow from './EventRow';

const Modal = ({ event_choices, selectChoice, cancel }) => {

  return (
    <div className="redemption-modal">
      <div className="redemption-modal-header">
        <h4>Select an Event</h4>
      </div>
      <div className="redemption-modal-content">
        {event_choices.map((choice) =>
          <div className="result" key={choice.id} onClick={() => selectChoice(choice.id)}>
            <EventRow
              eventOption={choice}
              className="large-12 small-12"
            />
          </div>
        )}
      </div>
      <div className="flex flex-v-center padding-v">
        <button className='button modal-button' style={{marginBottom: "0"}} onClick={cancel}>Cancel</button>
      </div>
    </div>
  );
};

export default Modal;
