import { Controller } from 'stimulus';
import axios from 'axios';

export default class MessagesController extends Controller {
  static targets = ['participantsField', 'participantsInput', 'tokensContainer', 'messagePlaceholder', 'ckEditor'];

  onParticipantsChange(event) {
    let input = this.participantsInputTarget.value;
    if (event.key === 'Enter' && input) {
      axios
        .get(`/my_messages/add_participant?login=${input}`)
        .then(response => {
          if (response.data && response.data.id) {
            let id = response.data.id;
            this.participantsInputTarget.value = '';
            this.participantsInputTarget.style.color = 'black';

            let hiddenParticipant = document.createElement('input');
            hiddenParticipant.setAttribute('type', 'hidden');
            hiddenParticipant.setAttribute('name', 'participant_ids[]');
            hiddenParticipant.setAttribute('data-login', input);
            hiddenParticipant.setAttribute('value', id);

            this.participantsFieldTarget.appendChild(hiddenParticipant);

            let participantToken = document.createElement('li');
            participantToken.classList.add('Token')
            participantToken.setAttribute('data-login', input);
            
            let closeButton = document.createElement('a');
            closeButton.classList.add('Close')
            closeButton.innerHTML = 'x'
            closeButton.onclick = (event) => {
              event.preventDefault();
              let login = event.target.parentElement.getAttribute('data-login');
              
              let hidden = document.querySelector('.js-participants-list')
              hidden.childNodes.forEach(node => {
                if (node.getAttribute('data-login') == login){
                  node.parentElement.removeChild(node);
                }
              });

              event.target.parentElement.parentElement.removeChild(event.target.parentElement);
            };

            let span = document.createElement('span');
            span.innerHTML = input;
            participantToken.appendChild(span)
            participantToken.appendChild(closeButton)

            this.tokensContainerTarget.appendChild(participantToken);
          } else {
            this.participantsInputTarget.style.color = 'red';
          }
        }).catch(error => {
          console.log(error);
        });
    }
  }

  onDropdownClick() {
    let dropdown = document.getElementById('participant-dropdown');
    this.slideToggle(dropdown, 100);
  }

  showCKEditor(event) {
    this.messagePlaceholderTarget.style.display = 'none';
    this.slideToggle(this.ckEditorTarget);
  }

  hideCKEditor(event) {
    this.slideToggle(this.ckEditorTarget);
    this.messagePlaceholderTarget.style.display = 'block';
  }

  slideUp(target, duration) {
    target.style.transitionProperty = 'height, margin, padding';
    target.style.transitionDuration = duration + 'ms';
    target.style.boxSizing = 'border-box';
    target.style.height = target.offsetHeight + 'px';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    target.style.overflow = 'hidden';
    window.setTimeout(() => {
      target.style.display = 'none';
      target.style.removeProperty('height');
      target.style.removeProperty('padding-top');
      target.style.removeProperty('padding-bottom');
      target.style.removeProperty('margin-top');
      target.style.removeProperty('margin-bottom');
      target.style.removeProperty('overflow');
      target.style.removeProperty('transition-duration');
      target.style.removeProperty('transition-property');
    }, duration);
  }

  slideDown(target, duration) {
    target.style.removeProperty('display');
    let display = window.getComputedStyle(target).display;
    if (display === 'none') {
      display = 'block';
    }
    target.style.display = display;
    let height = target.offsetHeight;
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    target.style.overflow = 'hidden';
    target.style.boxSizing = 'border-box';
    target.style.transitionProperty = 'height, margin, padding';
    target.style.transitionDuration = duration + 'ms';
    target.style.height = height + 'px';
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    window.setTimeout(() => {
      target.style.removeProperty('height');
      target.style.removeProperty('overflow');
      target.style.removeProperty('transition-duration');
      target.style.removeProperty('transition-property');
    }, duration);
  }

  slideToggle(target, duration = 500) {
    if (window.getComputedStyle(target).display === 'none') {
      return this.slideDown(target, duration);
    } else {
      return this.slideUp(target, duration);
    }
  }
}
