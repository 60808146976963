import React from 'react'

export default function NavButtons({backButtonText='BACK', backOnClick=null, nextButtonText='NEXT', nextOnClick=null}) {
  return (
    <div className='next-button-row' style={{justifyContent: backOnClick ? 'space-between' : 'end'}}>
      {backOnClick && 
        <div className='back-button' onClick={backOnClick}>
          <p>{backButtonText}</p>
        </div>
      }
      {nextOnClick && 
        <div className='next-button' onClick={nextOnClick}>
          <p>{nextButtonText}</p>
        </div>
      }
    </div>
  )
}
