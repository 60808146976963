/* global window  */
import React, { useState, useRef, useCallback, memo } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { isEmpty } from 'ramda'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { Alert, noticeMsg, errorMsg } from '../../helpers/notifications'
import autoload from '../../helpers/autoload'

const AvatarList = ({ availableAvatars, cdnUrl, onAvatarSelect }) => {
  return (
    <Splide
      options={{
        perMove: 10,
        pagination: false,
        autoWidth: true,
        gap: 5
      }}>
      {availableAvatars.map(({ id }) => {
        return (
          <SplideSlide key={id}>
            <img
              onClick={() =>
                onAvatarSelect(id, `${cdnUrl}/avatars/${id}/large.png`)
              }
              src={`${cdnUrl}/avatars/${id}/normal.png`}
              alt="Image 1"
            />
          </SplideSlide>
        )
      })}
    </Splide>
  )
}
const MemoizedAvatarList = React.memo(AvatarList)

const AvatarUpload = ({
  avatarId,
  availableAvatars,
  avatarUrl,
  submitUrl,
  cdnUrl
}) => {
  const inputRef = useRef(null)
  const formRef = useRef(null)
  const [selectedAvatarId, setSelectedAvatarId] = useState(avatarId)
  const [showUpload, setShowUpload] = useState(false)
  const [largeAvatar, setLargeAvatar] = useState(avatarUrl)
  const [submitText, setSubmitText] = useState('Update')
  const [file, setFile] = useState([])

  const onSave = (event) => {
    event.preventDefault()

    setSubmitText('Updating...')

    let formData = new FormData()

    if (!isEmpty(file)) {
      formData.append('avatar[file]', file)
    }
    formData.append('new_avatar_id', selectedAvatarId)

    axios({
      method: 'post',
      url: `${submitUrl}.json`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        setSubmitText('Update')

        if (document.querySelector('.menu .avatar')) {
          document.querySelector('.menu .avatar').src = largeAvatar;
        }

        if (document.querySelector('.account-nav-dropdown .avatar')) {
          document.querySelector('.account-nav-dropdown .avatar').src = largeAvatar;
        }

        document.querySelector('.avatar_dropdown img').src = largeAvatar;
        document.getElementById('change_avatar_panel').style.display = 'none'
        const { data } = response.data

        if (data) {
          const targetNode = document.querySelector('[data-flash]')
          targetNode.dataset.flash = JSON.stringify(data)
        }
      })
      .catch(function (response) {
        setSubmitText('Update')
        console.log(response)
      })
  }

  const onSelect = (event) => {
    var file = inputRef.current.files[0]
    var reader = new FileReader()
    var url = reader.readAsDataURL(file)
    reader.onloadend = function (e) {
      setFile([reader.result])
      setLargeAvatar(reader.result)
      setShowUpload(false)
    }
  }

  const onAvatarSelect = useCallback(
    (id, largeSelectedImage) => {
      setSelectedAvatarId(id)
      setFile([])
      setShowUpload(false)
      setLargeAvatar(largeSelectedImage)
    },
    [setSelectedAvatarId, setLargeAvatar]
  )

  const setUploadShow = () => {
    setShowUpload(true)
  }

  const cancelAvatar = (event) => {
    event.preventDefault()
    setFile([])
    setLargeAvatar(avatarUrl)
    setShowUpload(false)
    setSelectedAvatarId(avatarId)
    
    document.getElementById('change_avatar_panel').style.display = 'none'
  }

  return (
    <div id="avatar-modal">
      <p style={{ margin: '7px 0' }}></p>
      <form
        onSubmit={onSave}
        className="new_avatar"
        ref={formRef}
        id="new_avatar"
        encType="multipart/form-data"
        acceptCharset="UTF-8"
        data-remote="true"
        method="post">
        <div className="attributes">
          {!showUpload && (
            <div className="attr logo">
              <div className="name">Avatar:</div>
              <div className="value">
                <div className="large-avatar">
                  <img
                    height={128}
                    width={128}
                    className="avatar"
                    data-largeimage="true"
                    src={largeAvatar}
                  />
                </div>
                <a className="change-logo" onClick={setUploadShow}>
                  Upload New
                </a>
              </div>
            </div>
          )}
          {showUpload && (
            <div className="new-logo-form">
              <div className="attr">
                <div className="name">Avatar:</div>
                <div className="value">
                  <input
                    accept="image/png,image/jpeg,image/gif"
                    type="file"
                    onChange={onSelect}
                    ref={inputRef}
                    name="avatar[file]"
                    id="avatar_file"
                  />
                  <input
                    type="hidden"
                    name="avatar[file]"
                    value={file}
                    multiple={true}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          <MemoizedAvatarList
            availableAvatars={availableAvatars}
            cdnUrl={cdnUrl}
            onAvatarSelect={onAvatarSelect}
          />
        </div>
        <div className="attributes">
          <div className="attr">
            <div className="name">&nbsp;</div>
            <div className="value">
              <a
                className="button"
                name="commit"
                onClick={cancelAvatar}
              >
                Cancel
              </a>
              <input
                type="submit"
                name="commit"
                value={submitText}
                className="button"
              />
            </div>
          </div>
        </div>
      </form>
      <p />
    </div>
  )
}

export default autoload(AvatarUpload, 'avatar-upload')

AvatarUpload.propTypes = {
  avatarId: PropTypes.number.isRequired,
  availableAvatars: PropTypes.arrayOf(PropTypes.object).isRequired,
  avatarUrl: PropTypes.string.isRequired,
  submitUrl: PropTypes.string.isRequired,
  cdnUrl: PropTypes.string.isRequired
}
