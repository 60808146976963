/* global ga  */
import React, { useState, useEffect, useRef } from 'react';
import L from 'leaflet';
import PropTypes from 'prop-types';
import { TileLayer, ZoomControl } from 'react-leaflet';
import { debounce } from 'debounce';
import MapExtended from './MapExtended';
import Coordinates from './Coordinates';
import Search from './Search';
import NoSupport from './NoSupport';
import { fetchLgElements } from './api/api';
import { MIN_ZOOM, MAX_ZOOM, ZOOM_DELTA, TILE_VERSION } from './constants';
import './helpers/PixelFix';
import { isValidBrowser } from './helpers/browser';
import 'fslightbox';
import autoload from '../../helpers/autoload';

import {
  openPopup,
  collapseAllExpanded,
  clickedOutsideTiles,
  fadeElements,
  isZooming,
  openElementIfExists,
  addElementsToMap,
} from './helpers/tiles';

import { getMapBounds, project } from './helpers/coordinates';

import {
  initialMapLocation,
  isCoordsOpen,
  updateUrlWithMapLocation,
} from './helpers/url';

import {
  isEmptyValue,
  filterDupeElements,
  getDataset,
  getCategoryAndIcon,
  getIds,
  notIncludedInArray,
} from './helpers/utils';

const validBrowser = isValidBrowser();

const LookingGlass = ({ currentUserId, cdnUrI, isMobile }) => {
  const location = initialMapLocation();
  const [elements, setElements] = useState([]);
  const [visibleElementIds, setVisibleElementIds] = useState([]);
  const [categories, setCategories] = useState({});
  const [searchedElements, setSearchedElements] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [mapLocation, setMapLocation] = useState(location);
  const [currentClick, setCurrentClick] = useState(null);
  const map = useRef(null);

  useEffect(() => {
    async function fetchData() {
      const { search, category, x, y, c, z } = mapLocation;
      const mapBounds = getMapBounds(map);

      // fetch all the LG Elements with the category aggregations
      const response = await fetchLgElements({
        c,
        x,
        y,
        z,
        ...mapBounds,
        aggregations: true,
      });

      const { data: newElements, aggregations } = response.data.data.elements;
      setCategories(getCategoryAndIcon(aggregations));
      const filteredElements = filterDupeElements([
        ...elements,
        ...newElements,
      ]);

      setElements(filteredElements);
      let filtedElementIds = getIds(filteredElements);

      if (search || category) {
        const searchResponse = await fetchLgElements({
          ...mapLocation,
          ...mapBounds,
          category,
          includeSearch: true,
        });

        const { elements: newSearchElements } = searchResponse.data.data;
        filtedElementIds = getIds(newSearchElements);

        setSearchedElements(filterDupeElements([...newSearchElements]));
      }

      setVisibleElementIds(filtedElementIds);

      setLoaded(true);
    }

    if (validBrowser) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (validBrowser) {
      fadeElements(visibleElementIds);
    }
  }, [visibleElementIds]);

  useEffect(() => {
    if (validBrowser) {
      addElementsToMap(elements, map);
    }
  }, [elements]);

  useEffect(() => {
    if (loaded) {
      openElementIfExists(elements, currentUserId, isMobile);
    }
  }, [loaded]);

  const onSelectElement = element => {
    // eslint-disable-next-line no-underscore-dangle
    const { id, contact_id: contactId } = element._source;

    const viewPortlocation = {
      ...mapLocation,
      select: id,
    };

    updateUrlWithMapLocation(viewPortlocation);
    setMapLocation(viewPortlocation);

    openPopup(element, map, isMobile);

    // universal analytics commented out. To be replaced with GA4 tags at a later time.

    // dimension1 = UserId, dimension2 = ContactId
    // ga('send', 'event', 'Looking Glass', 'SearchResultSelect', {
    //   dimension1: currentUserId,
    //   dimension2: contactId,
    // });
  };

  const onViewportChanged = async ({ center, zoom }) => {
    const { z } = mapLocation;

    if (isZooming(zoom, z)) {
      collapseAllExpanded();
    }

    // Uncomment when we want to add the zoom at different levels
    //
    // if (shouldUpdateZoomContent(zoom, z)) {
    //   updateZoomContent(elements, map);
    // }

    const xCenter = center[0];
    const yCenter = center[1];
    const { x, y } = project(
      [parseInt(xCenter, 10), parseInt(yCenter, 10)],
      map,
    );

    const viewPortlocation = {
      ...mapLocation,
      z: zoom,
      x,
      y,
    };

    updateUrlWithMapLocation(viewPortlocation);
    setMapLocation(viewPortlocation);
  };

  const onSelectCategory = async category => {
    const { search } = mapLocation;

    if (isEmptyValue(category) && isEmptyValue(search)) {
      setSearchedElements([]);
    }

    const mapBounds = getMapBounds(map);
    const viewPortlocation = {
      ...mapLocation,
      category,
      search,
    };

    updateUrlWithMapLocation(viewPortlocation);
    setMapLocation(viewPortlocation);

    const response = await fetchLgElements({
      ...mapLocation,
      ...mapBounds,
      category,
    });

    const { elements: newElements } = response.data.data;

    setElements(filterDupeElements([...newElements]));
    setVisibleElementIds(getIds(newElements));

    if (isEmptyValue(category) && isEmptyValue(search)) {
      return;
    }

    const searchResponse = await fetchLgElements({
      ...mapLocation,
      ...mapBounds,
      category,
      includeSearch: true,
    });

    const { elements: newSearchElements } = searchResponse.data.data;

    setSearchedElements(filterDupeElements([...newSearchElements]));

    // dimension1 = UserId, dimension6 = CategoryTerm
    ga('send', 'event', 'Looking Glass', 'CategorySelected', {
      dimension1: currentUserId,
      dimension6: category,
    });
  };

  const onSearchChange = async (search, clearCategory = false) => {
    const { category } = mapLocation;

    const mapBounds = getMapBounds(map);

    let searchLocation = {
      ...mapLocation,
      search,
    };

    if (clearCategory) {
      searchLocation = { ...searchLocation, category: undefined };
    }

    updateUrlWithMapLocation(searchLocation);

    if ((isEmptyValue(category) && isEmptyValue(search)) || clearCategory) {
      setSearchedElements([]);
    } else {
      const searchResponse = await fetchLgElements({
        ...mapLocation,
        ...mapBounds,
        search,
        category,
        includeSearch: true,
      });

      const { elements: newSearchElements } = searchResponse.data.data;

      setSearchedElements(filterDupeElements([...newSearchElements]));
    }

    setMapLocation(searchLocation);
  };

  const handleClick = e => {
    const { originalEvent: event } = e;
    const trackingLink = event.target.closest('a');

    if (clickedOutsideTiles(event)) {
      collapseAllExpanded();
      const viewPortlocation = {
        ...mapLocation,
        select: '',
      };
      updateUrlWithMapLocation(viewPortlocation);
      setMapLocation(viewPortlocation);
    } else {
      const element = event.target.closest('.element');
      const dataset = getDataset(element);

      if (element && dataset && dataset.id) {
        const viewPortlocation = {
          ...mapLocation,
          select: dataset.id,
        };

        if (notIncludedInArray(parseInt(dataset.id, 10), visibleElementIds)) {
          return;
        }

        collapseAllExpanded();
        updateUrlWithMapLocation(viewPortlocation);
        setMapLocation(viewPortlocation);
        openElementIfExists(elements, currentUserId, isMobile);
      }
    }
    setCurrentClick(e.latlng);

    // Link Click GA event tracking
    if (trackingLink && trackingLink.classList.contains('tracklink')) {
      const { contactid: contactId, linktype: linkType } = trackingLink.dataset;

      // dimension1 = UserId, dimension2 = ContactId, dimension4=LinkType
      ga('send', 'event', 'Looking Glass', 'LinkClick', {
        dimension1: currentUserId,
        dimension2: contactId,
        dimension4: linkType,
        transport: 'beacon',
      });
    }

    // Image Click GA event tracking
    if (trackingLink && trackingLink.classList.contains('trackimage')) {
      const { contactid: contactId, imageid: imageId } = trackingLink.dataset;

      // dimension1 = UserId, dimension2 = ContactId, dimension3=ImageId
      ga('send', 'event', 'Looking Glass', 'ImageClick', {
        dimension1: currentUserId,
        dimension2: contactId,
        dimension3: imageId,
      });
    }
  };

  if (!validBrowser) {
    return <NoSupport />;
  }

  return (
    <MapExtended
      minZoom={MIN_ZOOM}
      maxZoom={MAX_ZOOM}
      zoomDelta={ZOOM_DELTA}
      zoomSnap={ZOOM_DELTA}
      crs={L.CRS.Simple}
      ref={map}
      doubleClickZoom={false}
      onViewportChanged={onViewportChanged}
      onClick={handleClick}
      zoomControl={false}
    >
      {currentClick && isCoordsOpen() && (
        <Coordinates map={map} currentClick={currentClick} />
      )}
      <ZoomControl position="topright" />
      <Search
        onSearchChange={debounce(onSearchChange, 500)}
        mapLocation={mapLocation}
        elements={elements}
        categories={categories}
        loaded={loaded}
        searchedElements={searchedElements}
        onSelectCategory={onSelectCategory}
        onSelectElement={onSelectElement}
        currentUserId={currentUserId}
      />
      <TileLayer
        url={`${cdnUrI}/lg/tiles/${TILE_VERSION}/{z}/{x}/{y}.png`}
        continuousWorld
      />
    </MapExtended>
  );
};

export default autoload(LookingGlass, 'looking-glass');

LookingGlass.propTypes = {
  currentUserId: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  cdnUrI: PropTypes.string.isRequired,
};
